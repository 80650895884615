import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../translations';
import MapNavCountry from './MapNavCountry';
import { MapNavContainer, MapNavContent, ActionText } from './MapNavStyled';

export const MapNavigation = ({ country, updateCountry }) => {
  const [mapHeight, setMapHeight] = useState(0);
  useLayoutEffect(() => {
    const updateHeight = () => {
      const mapContainer = document.getElementById('styledmapcontainer');
      if (mapContainer) {
        setMapHeight(mapContainer.offsetHeight);
      }
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
  return (
    <MapNavContainer height={mapHeight}>
      {country ? (
        <MapNavCountry country={country} updateCountry={updateCountry} />
      ) : (
        <MapNavContent>
          <ActionText>{t('mapNav')('title')}</ActionText>
        </MapNavContent>
      )}
    </MapNavContainer>
  );
};

MapNavigation.propTypes = {
  country: PropTypes.string,
  updateCountry: PropTypes.func.isRequired
};

MapNavigation.defaultProps = {
  country: ''
};
