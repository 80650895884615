/** @jsx jsx */
import { useTheme } from '@emotion/react';
import React, {
  Fragment, useContext, useRef, createRef, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/react';
import {
  PlanetLineContainer,
  NavigationTitle,
  NavigationButton,
  PlanetLineContent,
  StyledTimelineGraphicContainer,
  StyledTimelineContainer,
  StyledTimelineHeader,
  StyledTimelineContent,
  StyledModalContentHeader,
  StyledModalTextContent,
  StyledModalImage,
  StyledNavButtonContainer,
} from './PlanetTimeLineTabs.styles';

import Tims4GoodContext from '../../../context/timsForGoodContext';
import TimelineGraphic from '../../../components/branding/TimelineGraphic';
import Column45 from '../../../components/layout/Column45';
import Column50 from '../../../components/layout/Column50';
import MainColumn from '../../../components/layout/MainColumn';
import StyledTimelineLI from '../../../components/layout/StyledTimelineLI';
import StyledTimelineUL from '../../../components/layout/StyledTimelineUL';
import Modal from '../../../components/ui/modal/Modal';
import StyledChevron from '../../../components/branding/Chevron';
import { t } from '../../../translations';
import { useScreen } from '../../../utils';
import StyledLink from '../../../components/structure/StyledLink';
import Tabs from './tabs';

const PlanetTimeLineTabs = ({ children }) => {
  const theme = useTheme();
  const content = t('planet')('years').map((key) => ({
    title: key, eventKey: key,
  }));
  const [tab, setTab] = useState(content?.length - 1);
  const isMobile = useScreen();
  const { sendMessage } = useContext(Tims4GoodContext);

  const handleTabPress = (tabIndex) => {
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Tab Clicked',
      mParticleEvtType: 8,
      evtMessage: {
        tabName: content[tabIndex].eventKey,
      },
    });

    setTab(tabIndex);
  };

  const handleNavigationClick = (index) => () => {
    handleTabPress(tab + index);
  };

  const svgPosition = (num) => (num % 2 === 0 && !isMobile ? { right: '12px' } : { left: '12px' });
  const isEven = (num) => num % 2 === 0;

  return (
    <>
      <Tabs content={content} activeTab={tab} onClick={handleTabPress} ariaLabel={t('planet')('ariaLabel')} />
      <PlanetLineContainer>
        {content.map(({ eventKey, title }, index) => (
          <PlanetLineContent key={eventKey} display={tab === index ? 'flex' : 'none'} aria-labelledby={eventKey}>
            <MainColumn fullWidthUntil="Tera" alignItems="unset">
              <Column45 fullWidthUntil="Tera" topPadding>
                {children}
              </Column45>
              <StyledNavButtonContainer>
                <NavigationButton
                  className="left_nav"
                  onClick={handleNavigationClick(-1)}
                  disabled={tab === 0}
                >
                  <StyledChevron
                    fillColor={theme.colors.white}
                    size="small"
                    point="left"
                  />
                </NavigationButton>
                <NavigationTitle
                  tabIndex={-1}
                >
                  {content[tab].title}
                </NavigationTitle>

                <NavigationButton
                  onClick={handleNavigationClick(1)}
                  disabled={tab === content.length - 1}
                  className="right_nav"
                >
                  <StyledChevron
                    fillColor={theme.colors.white}
                    size="small"
                    point="right"
                  />
                </NavigationButton>
              </StyledNavButtonContainer>
              <Column50
                layout="center"
                fullWidthUntil="Tera"
                isTimeline
                className="timeline_wrapper"
              >
                <StyledTimelineGraphicContainer>
                  <TimelineGraphic height="100%" count={t('planet')('timeline')[eventKey].length} />
                </StyledTimelineGraphicContainer>
                <StyledTimelineUL>
                  {t('planet')('timeline')[eventKey].map((instance, index) => {
                    const { copy } = instance;
                    let copyContent = (<p>{copy}</p>);

                    if (typeof copy !== 'string') {
                      copyContent = copy.map((c, index) => (<p key={index}>{c}</p>));
                    }

                    return (
                      <StyledTimelineLI key={index} count={index}>
                        <Modal
                          key={`timeEvent_${index}`}
                          eventKey={instance.eventKey}
                          contentStyles={{
                            color: `${theme.colors.white}`,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          buttonStyles={{
                            position: 'relative',
                            border: 'none',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            outline: 'none',
                            color: `${theme.colors.brown}`,
                            padding: `${theme.spacings.giga} ${theme.spacings.mega}`,
                            borderRadius: `${theme.borderRadius.kilo}`,
                            fontSize: theme.fontSize.exa,
                            lineHeight: theme.fontSize.peta,
                            letterSpacing: theme.letterSpacing.bit,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: theme.colors.white46,

                            '&:focus': theme.focus.brown,

                            svg: {
                              ...svgPosition(index),
                              position: 'absolute',
                            },
                          }}
                        >
                          <StyledTimelineContainer>
                            <StyledTimelineHeader>
                              {instance.date}
                            </StyledTimelineHeader>
                            {instance.feature && (
                            <StyledTimelineContent>
                              {instance.feature}
                            </StyledTimelineContent>
                            )}
                          </StyledTimelineContainer>
                          <StyledChevron
                            fillColor={theme.colors.brown}
                            size="tiny"
                            point={(isEven(index) && !isMobile) ? 'right' : 'left'}
                          />
                          <div id="modal_content_container">
                            <StyledModalImage src={instance.imgSrc} alt={instance.alt} />
                            <StyledModalContentHeader id="modal_header">
                              {instance.feature}
                            </StyledModalContentHeader>
                            <StyledModalTextContent id="modal_content">
                              {copyContent}
                              {instance.link && (
                              <StyledLink url={instance.link}>
                                {instance.learnMore}
                              </StyledLink>
                              )}
                            </StyledModalTextContent>
                          </div>
                        </Modal>
                      </StyledTimelineLI>
                    );
                  })}
                </StyledTimelineUL>
              </Column50>
            </MainColumn>
          </PlanetLineContent>
        ))}
      </PlanetLineContainer>
    </>
  );
};

PlanetTimeLineTabs.propTypes = {
  children: PropTypes.node,
};

export default PlanetTimeLineTabs;
