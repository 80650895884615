import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const MainColumn = ({
  children,
  alignItems,
  justifyContent,
  fullWidthUntil,
  maringTopVariant,
  reverse,
  className,
  zIndex,
}) => {
  const theme = useTheme();

  const marginValue =
    maringTopVariant === 'large' ? { marginTop: theme.spacings.yotta } : {};

  const Container = styled.div`
    width: 90%;
    display: flex;
    gap: ${theme.spacings.mega};
    align-items: ${alignItems || 'center'};
    justify-content: ${justifyContent || 'space-between'};
    height: 100%;
    z-index: ${zIndex}
    ${marginValue};
    ${reverse && 'flex-direction: row-reverse;'}
    ${theme.mq[`until${fullWidthUntil}`]} {
      flex-direction: column;
    }
    ${theme.mq.untilMega} {
      gap: 0;
    }
  `;
  return <Container className={className}>{children}</Container>;
};

MainColumn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  alignItems: PropTypes.string,
  fullWidthAt: PropTypes.string,
  justifyContent: PropTypes.string,
  maringTopVariant: PropTypes.string,
  zIndex: PropTypes.string,
};

MainColumn.defaultProps = {
  fullWidthUntil: 'Mega',
  maringTopVariant: 'none',
  zIndex: 'auto',
};

export default MainColumn;
