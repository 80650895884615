import { css } from '@emotion/react';

export const GeneralStyles = {
  spacings: {
    atom: '2px',
    bit: '4px',
    byte: '8px',
    exa: '12px',
    giga: '16px',
    kilo: '20px',
    mega: '28px',
    peta: '40px',
    tera: '48px',
    yotta: '60px',
    zetta: '87px'
  },
  focus: {
    brown: {
      outline: 0,
      boxShadow: '0 0 3px 2px #2C1513'
    },
    white: {
      outline: 0,
      boxShadow: '0 0 3px 2px #ffffff'
    },
    red: {
      outline: 0,
      boxShadow: '0 0 3px 2px #C8102E'
    }
  },
  gradient: {
    lightRedTodarkRed: 'linear-gradient(317deg,#c8102e, #be0825)',
    clearToBlack: 'linear-gradient(rgba(0,0,0,0), #000000)',
    halfClearToblack: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.8) 50%)'
  },
  colors: {
    beigeOverlayAlt: '#E6E4DD',
    beigeOverlayAlt50: 'rgba(230, 228,221, .5)',
    beigeOverlay30: 'rgba(212, 174, 135, .3)',
    beigeOverlay15: 'rgba(212, 174, 135, .15)',
    lightBeige: '#ECDCCB',
    beige: '#D4AE87',
    beige2: '#f9f3ed',
    beige3: '#f9f5ee',
    beige4: '#eee8dd',
    green: '#455c45',
    darkGreen: '#293a29',
    red1: '#C8102E',
    red2: '#A0091D',
    brown: '#2C1513',
    brown2: '#3F2021',
    brown45Transparent: 'rgba(44, 21, 19, .45)',
    white: '#FFFFFF',
    white46: 'rgba(255, 255, 255, .46)',
    black: '#000000',
    black30Overlay: 'rgba(0,0,0,.3)',
    black55Overlay: 'rgba(0,0,0,.55)',
    black90Overlay: 'rgba(0,0,0,.9)'
  },
  opacity: {
    atom: '2px',
    bit: '0.12',
    byte: '0.15',
    exa: '0.5',
    giga: '0.65',
    kilo: '0.87'
  },
  fontSize: {
    preAtom: '16px', // 16px
    atom: '18px', // 18px
    bit: '20px', // 20px
    byte: '22px', // 22px
    exa: '24px', // 24px
    giga: '26px', // 26px
    kilo: '28px', // 28px
    mega: 'calc(20px + 1.04vw)', // 40px at 1600px
    peta: 'calc(30px + 1.07vw)', // 60px
    tera: 'calc(50px + 2.28vw)', // 105px
    yotta: 'calc(25px + 3.68vw)', // 55px at 768px
    zetta: 'calc(35px + 5.17vw)' // 75px
  },
  fontWeight: {
    bold: '700',
    medium: '600',
    regular: '400'
  },
  letterSpacing: {
    atom: '-3.68px',
    bit: '-2.1px',
    byte: '-1.4px',
    exa: '-0.98px',
    giga: '-0.7px',
    kilo: '-0.63px',
    mega: '-0.36px',
    peta: '0.55px'
  },
  lineHeight: {
    byte: '1.15',
    mega: '1.1',
    peta: '1',
    zetta: '0.9'
  },
  borderRadius: {
    circle: '50%',
    atom: '3px',
    bit: '5px',
    byte: '10px',
    exa: '16px',
    giga: '30px',
    kilo: '500px'
  },
  borderWidth: { kilo: '1px', mega: '2px', giga: '3px' },
  breakpoints: {
    afterTera: '(min-width: 1280px)',
    byte: 669,
    giga: 960,
    gigaToTera: '(min-width: 960px) and (max-width: 1279px)',
    kilo: 480,
    kiloToMega: '(min-width: 480px) and (max-width: 812px)',
    mega: 813,
    megaToTera: '(min-width: 813px) and (max-width: 1279px)',
    tera: 1280,
    untilKilo: '(max-width: 479px)',
    untilMega: '(max-width: 812px)'
  },
  mq: {
    byte: '@media (min-width: 671px)',
    byteToMega: '@media (min-width: 671px) and (max-width: 812px)',
    afterTera: '@media (min-width: 1280px)',
    giga: '@media (min-width: 960px)',
    gigaToTera: '@media (min-width: 960px) and (max-width: 1279px)',
    kilo: '@media (min-width: 480px)',
    kiloToByte: '@media (min-width: 480px) and (max-width: 670px)',
    kiloToMega: '@media (min-width: 480px) and (max-width: 812px)',
    mega: '@media (min-width: 813px)',
    megaToGiga: '@media (min-width: 812px) and (max-width: 960px)',
    megaToTera: '@media (min-width: 812px) and (max-width: 1279px)',
    tera: '@media (min-width: 1280px)',
    untilKilo: '@media (max-width: 479px)',
    untilByte: '@media (max-width: 670px)',
    untilMega: '@media (max-width: 812px)',
    untilTera: '@media (max-width: 1279px)',
    untilInfinity: '@media (min-width: 1px)',
    mobileSmallHighDensity:
      '@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2)',
    mobileMediumHighDensity:
      '@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3)',
    mobileLargeHighDensity:
      '@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3)',
    tabletRegularDensity:
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)',
    tabletHighDensity:
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)'
  }
};

export const mobileSubheaderStyle = css`
  ${GeneralStyles.mq.untilMega} {
    font-weight: 400;
  }
`;
