import { GeneralStyles } from '../components/styles/generalStyles';

export const colorToDisplay = (displayColor) => {
  let renderedColor;

  switch (displayColor) {
    case 'brown':
      renderedColor = GeneralStyles.colors.brown;
      break;
    case 'green':
      renderedColor = GeneralStyles.colors.green;
      break;
    case 'black':
      renderedColor = GeneralStyles.colors.black;
      break;
    default:
      renderedColor = GeneralStyles.colors.white;
      break;
  }

  return renderedColor;
};
