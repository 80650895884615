import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MetricsContext } from './metrics.context';

export const MetricsProvider = ({
  breakPointOffSets,
  resizeTolerance,
  children
}) => {
  const resizeCallbacks = useRef([]);

  const getCurrentTopOffSet = () => {
    const currentOffset = breakPointOffSets
      .sort((offSet1, offSet2) => offSet1.minWidth - offSet2.minWidth)
      .reduce(
        (prev, offSetItem) =>
          window.matchMedia(`(min-width: ${offSetItem.minWidth}px)`).matches
            ? offSetItem.offSetTop
            : prev,
        0
      );
    return currentOffset;
  };

  const addResizeCallback = (callBack) => {
    resizeCallbacks.current.push(callBack);
  };

  const dispatchResizeCallbacks = () => {
    resizeCallbacks.current.forEach((callback) => {
      callback();
    });
  };

  useEffect(() => {
    dispatchResizeCallbacks();
    let timer;
    const handleResize = () => {
      clearTimeout(timer);
      timer = setTimeout(dispatchResizeCallbacks, resizeTolerance);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <MetricsContext.Provider
      value={{ getCurrentTopOffSet, breakPointOffSets, addResizeCallback }}
    >
      {children}
    </MetricsContext.Provider>
  );
};

MetricsProvider.propTypes = {
  breakPointOffSets: PropTypes.arrayOf(
    PropTypes.shape({
      minWidth: PropTypes.number.isRequired,
      offSetTop: PropTypes.number.isRequired
    })
  ).isRequired,
  resizeTolerance: PropTypes.number
};

MetricsProvider.defaultProps = {
  resizeTolerance: 400
};
