import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Tims4GoodContext from '../../context/timsForGoodContext';
import { GeneralStyles as theme } from '../styles/generalStyles';

const VideoResponsive = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  width: 100%;
  overflow: hidden;
  max-width: 1050px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:not(:first-of-type) {
    ${theme.mq.untilMega} {
      margin-top: 32px;
    }
  }

  &:not(:first-of-type) {
    ${theme.mq.untilKilo} {
      margin-top: 24px;
    }
  }
`;

const VideoContainer = styled.div``;

const YoutubeEmbed = ({ videoId, iframeTitle }) => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const playerId = `yt-player-${videoId}`;
  const playerRef = useRef(null);
  const videoPlayed = useRef(false);

  useEffect(() => {
    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.PLAYING) {
        if (!videoPlayed.current) {
          sendMessage({
            type: 'MPARTICLE_LOG',
            evtSource: '[TH] T4G Youtube Video Played',
            mParticleEvtType: 8,
            evtMessage: {
              videoId,
              videoTitle: iframeTitle
            }
          });
          videoPlayed.current = true;
        }
      }
    };

    const initializePlayer = () => {
      playerRef.current = new window.YT.Player(playerId, {
        videoId,
        host: 'https://www.youtube-nocookie.com',
        playerVars: {
          rel: 0,
          modestbranding: 1,
          playsinline: 1
        },
        events: {
          onStateChange: onPlayerStateChange
        }
      });
    };

    const loadYoutubePlayerApi = () => {
      if (!window.YT) {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        script.async = true;
        document.body.appendChild(script);
      }

      if (typeof window.onYouTubeIframeAPIReady === 'undefined') {
        window.onYouTubeIframeAPIReady = () => {
          initializePlayer();
        };
      } else {
        // extend onYouTubeIframeAPIReady() with each new player
        const existingFunction = window.onYouTubeIframeAPIReady;
        window.onYouTubeIframeAPIReady = () => {
          existingFunction();
          initializePlayer();
        };
      }
    };
    loadYoutubePlayerApi();

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId, playerId]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (
        playerRef.current.getPlayerState() === window.YT.PlayerState.PLAYING
      ) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
    }
  };

  return (
    <VideoResponsive
      tabIndex='0'
      className='embedded-video'
      onKeyDown={handleKeyDown}
    >
      <VideoContainer tabIndex='0' id={playerId} title={iframeTitle} />
    </VideoResponsive>
  );
};

YoutubeEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
  iframeTitle: PropTypes.string.isRequired
};
export default YoutubeEmbed;
