import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import browserSniffer from '../../utils/browserSniffer';

import Tims4GoodContext from '../../context/timsForGoodContext';

const VideoPlayer = styled.div`
  background: #000;
  position: relative;
  max-width: 100%;
  max-height: calc(100vh - ${(props) => props.offSet}px);
  ${(props) => (props.minWidth ? `width: ${props.minWidth}` : 'min-width: 100%;')};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const VideoImage = styled.img`
  display: block;
  width: unset;
  height: 100%;
`;

const Video = styled.video`
  ${(props) => (props.isBackground ? 'height: auto; ' : 'width: 100%; max-width: 929px;')};
  background-color: #000;
  margin-bottom: -5px;
`;

const VideoComponent = ({
  title,
  videoSrc,
  videoPoster,
  tracks,
  hasControls,
  shouldAutoPlay,
  shouldLoop,
  isMuted,
  isBackground,
  backgroundImage,
  hasDescriptiveText,
  offSet = 0,
  ...props
}) => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const theme = useTheme();

  const StyledModalContentHeader = styled.h2`
    font-size: ${theme.fontSize.kilo};
    line-height: ${theme.lineHeight.byte};
    letter-spacing: ${theme.letterSpacing.byte};
    margin: ${theme.spacings.peta} ${theme.spacings.tera} ${theme.spacings.peta};
    padding-right: ${theme.spacings.yotta};

    ${theme.mq.untilByte} {
      font-size: ${theme.fontSize.exa};
      margin: ${theme.spacings.mega} ${theme.spacings.mega}
        ${theme.spacings.giga};
      padding-right: ${theme.spacings.tera};
    }
  `;

  const StyledModalTextContent = styled.div`
    font-size: ${theme.fontSize.exa};
    line-height: ${theme.lineHeight.byte};
    margin: 0 ${theme.spacings.tera} ${theme.spacings.peta};

    ${theme.mq.untilByte} {
      font-size: ${theme.fontSize.preAtom};
      margin: 0 ${theme.spacings.mega} ${theme.spacings.mega};
    }
  `;

  const minWidth = isBackground ? '100%' : '929px';

  function onPlay() {
    if (!shouldAutoPlay) {
      sendMessage({
        type: 'MPARTICLE_LOG',
        evtSource: '[TH] T4G Video Played',
        mParticleEvtType: 8,
        evtMessage: { title },
      });
    }
  }

  return (
    <VideoPlayer minWidth={minWidth} offSet={offSet}>
      {browserSniffer() && isBackground ? (
        <VideoImage src={backgroundImage} alt="" />
      ) : (
        <Video
          autoPlay={shouldAutoPlay}
          loop={shouldLoop}
          muted={isMuted}
          poster={videoPoster}
          preload="auto"
          playsInline
          controls={hasControls}
          onPlay={onPlay}
          {...(!isBackground && { crossOrigin: 'anonymous' })}
          isBackground={isBackground}
        >
          <source src={videoSrc} type="video/mp4" />
          {tracks}
          Sorry, your browser doesn't support embedded videos, but don't worry,
          you can
          {' '}
          <a href={videoSrc}>download it</a>
          and watch it with your favorite video player!
        </Video>
      )}
    </VideoPlayer>
  );
};

VideoComponent.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  videoPoster: PropTypes.string,
  tracks: PropTypes.arrayOf(PropTypes.element),
  hasControls: PropTypes.bool.isRequired,
  shouldAutoPlay: PropTypes.bool,
  shouldLoop: PropTypes.bool,
  isMuted: PropTypes.bool,
  preload: PropTypes.string,
  isBackground: PropTypes.bool,
  hasDescriptiveText: PropTypes.bool,
  offSet: PropTypes.number,
  title: PropTypes.string,
};

VideoComponent.defaultProps = {
  isBackground: false,
  hasDescriptiveText: false,
  offSet: 0,
};

export default VideoComponent;
