import React from 'react';
import PropTypes from 'prop-types';
import {
  MapNavFarmContainer,
  FarmDetailTitle,
  FarmDetailText,
  FarmDetailLocation,
  FarmDetailImgContainer,
  FarmDetailImgRow,
  FarmDetailImgSmall,
  FarmDetailImgLarge,
} from './MapNavStyled';

const MapNavFarm = ({ farm }) => (
  <MapNavFarmContainer>
    <FarmDetailTitle>{farm.title}</FarmDetailTitle>
    <FarmDetailLocation>{farm.location}</FarmDetailLocation>
    <FarmDetailText>{farm.text}</FarmDetailText>
    {farm.quote && <FarmDetailText>{farm.quote}</FarmDetailText>}
    <FarmDetailImgContainer>
      {!farm.img2 && farm.img1 && (
        <FarmDetailImgLarge
          src={farm.img1.imgURL}
          aria-label={farm.img1.alt}
          role="img"
        />
      )}
      {farm.img2 && (
        <FarmDetailImgRow>
          <FarmDetailImgSmall
            src={farm.img1.imgURL}
            aria-label={farm.img1.alt}
            role="img"
          />
          <FarmDetailImgSmall
            src={farm.img2.imgURL}
            aria-label={farm.img2.alt}
            role="img"
          />
        </FarmDetailImgRow>
      )}
      {farm.img3 && !farm.img4 && (
        <FarmDetailImgLarge
          src={farm.img3.imgURL}
          aria-label={farm.img3.alt}
          role="img"
        />
      )}
      {farm.img4 && (
        <FarmDetailImgRow>
          <FarmDetailImgSmall
            src={farm.img3.imgURL}
            aria-label={farm.img3.alt}
            role="img"
          />
          <FarmDetailImgSmall
            src={farm.img4.imgURL}
            aria-label={farm.img4.alt}
            role="img"
          />
        </FarmDetailImgRow>
      )}
    </FarmDetailImgContainer>
  </MapNavFarmContainer>
);

MapNavFarm.propTypes = {
  farm: PropTypes.shape({
    title: PropTypes.string,
    location: PropTypes.string,
    text: PropTypes.string,
    quote: PropTypes.string,
    img1: PropTypes.shape({ imgURL: PropTypes.string, alt: PropTypes.string }),
    img2: PropTypes.shape({ imgURL: PropTypes.string, alt: PropTypes.string }),
    img3: PropTypes.shape({ imgURL: PropTypes.string, alt: PropTypes.string }),
    img4: PropTypes.shape({ imgURL: PropTypes.string, alt: PropTypes.string }),
  }).isRequired,
};

export default MapNavFarm;
