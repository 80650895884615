import React from 'react';

const Tims4GoodContext = React.createContext({
  lang: 'en',
  sendMessage: () => {},
});

export const MParticleEventTypes = {
  Navigation: 1,
  Location: 2,
  Search: 3,
  Transaction: 4,
  UserContent: 5,
  UserPreference: 6,
  Social: 7,
  Other: 8,
};

export default Tims4GoodContext;
