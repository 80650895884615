import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { GeneralStyles as theme } from '../../components/styles/generalStyles';

export const CfcHeader = styled.h1`
  max-width: 1050px;
  font-size: 32px;
  font-weight: 700;
  line-height: 37.5px;
  letter-spacing: -2px;
  text-align: center;
  color: ${theme.colors.green};
  margin: unset;

  ${theme.mq.untilMega} {
    font-size: 34px;
    line-height: 36px;
  }

  ${theme.mq.untilKilo} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const CfcTitle = styled.h2`
  font-size: 60px;
  line-height: 63.5px;
  font-weight: 700;
  letter-spacing: -2px;
  color: ${theme.colors.green};
  margin: unset;
  a {
    color: ${theme.colors.green};
  }
  a:hover {
    cursor: pointer;
  }

  ${theme.mq.untilKilo} {
    font-size: 48px;
    line-height: 48px;
  }
`;

export const CfcText = styled.text`
  font-size: 32px;
  font-weight: 700;
  line-height: 37.5px;
  letter-spacing: -2px;
  color: ${theme.colors.green};

  ${theme.mq.untilMega} {
    margin-top: 44px;
    font-size: 34px;
    text-align: center;
    line-height: 30px;
    letter-spacing: normal;
  }

  ${theme.mq.untilKilo} {
    margin-top: 36px;
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    letter-spacing: -2px;
  }
`;

export const CfcTextBlack = styled.text`
  font-size: 32px;
  font-weight: 700;
  line-height: 38.5px;
  letter-spacing: -2px;
  color: ${theme.colors.black};

  ${theme.mq.untilMega} {
    margin-top: 32px;
    font-size: 36px;
    line-height: 38.5px;
  }

  ${theme.mq.untilKilo} {
    margin-top: 32px;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: normal;
  }
`;

export const EnveritasSubHeader = styled.text`
  font-size: 36px;
  line-height: 38.5px;
  font-weight: 500;
  margin-top: 12px;
  letter-spacing: -2px;

  ${theme.mq.untilMega} {
    margin-top: 32px;
  }

  ${theme.mq.untilKilo} {
    margin-top: 32px;
    font-size: 24px;
    line-height: 24px;
  }
`;

export const EnveritasText = styled.text`
  font-size: 28px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 24px;

  ${theme.mq.untilMega} {
    margin-top: 19px;
    margin-bottom: 36px;
  }

  ${theme.mq.untilKilo} {
    margin-top: 24px;
    margin-bottom: 41px;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const EnveritasImg = styled.img`
  width: 100%;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 59px;

  ${theme.mq.untilMega} {
    margin-top: 73px;
  }

  ${theme.mq.untilKilo} {
    margin-top: 30px;
  }

  div {
    width: 100%;
    background-color: unset;
    display: flex;
    justify-content: center;
  }

  video {
    width: 100%;
    max-width: unset;
  }
`;

export const FarmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FarmerCenteredText = styled.text`
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -2px;

  ${theme.mq.untilMega} {
    margin-top: 32px;
    font-size: 36px;
    line-height: 48px;
  }

  ${theme.mq.untilKilo} {
    margin-top: 32px;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: normal;
  }
`;

export const FarmerImageContainer = styled.div`
  padding-left: 44px;
  padding-right: 44px;
  ${({ isFirst }) =>
    !isFirst &&
    css`
      ${theme.mq.untilMega} {
        margin-top: 48px;
      }

      ${theme.mq.untilKilo} {
        margin-top: 48px;
      }
    `}
`;

export const FarmerImg = styled.img`
  width: 100%;
  border-radius: 50%;
`;

export const FarmerText = styled.text`
  font-size: 24px;
  font-weight: 700;
  margin-top: 16px;
  color: ${theme.colors.green};
  text-align: center;
  ${theme.mq.untilMega} {
    font-size: 36px;
    line-height: 36px;
  }

  ${theme.mq.untilKilo} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const LearnMoreText = styled.h3`
  margin: unset;
  margin-top: 72px;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -2px;
  line-height: 48px;
  color: ${theme.colors.green};

  ${theme.mq.untilMega} {
    margin-top: 72px;
    font-size: 36px;
  }

  ${theme.mq.untilKilo} {
    margin-top: 64px;
    font-size: 36px;
  }
`;

export const MonitoringPointContainer = styled.div`
  ${theme.mq.untilMega} {
    margin-top: 32px;
  }
`;

export const MonitoringIcon = styled.img`
  width: 76px;
  height: 76px;
`;

export const MonitoringPoint = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const MonitoringPointText = styled.text`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-left: 16px;

  ${theme.mq.untilKilo} {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const ResponsiveSectionWrapper = styled.div`
  ${({ backgroundColor, paddingTop, paddingBottom, applyFlexStyles }) => css`
    background-color: ${backgroundColor};

    padding-top: ${paddingTop[0]}px;
    padding-bottom: ${paddingBottom[0]}px;

    ${theme.mq.untilMega} {
      padding-top: ${paddingTop[1]}px;
      padding-bottom: ${paddingBottom[1]}px;
    }

    ${theme.mq.untilKilo} {
      padding-top: ${paddingTop[2]}px;
      padding-bottom: ${paddingBottom[2]}px;
    }

    ${applyFlexStyles &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      align-items: center;
      overflow: hidden;
    `}
  `}
`;

export const ResponsiveSectionMargin = styled.div`
  margin-top: 60px;
  margin-bottom: 93px;

  ${theme.mq.untilMega} {
    margin-top: 66px;
    margin-bottom: 73px;
  }

  ${theme.mq.untilKilo} {
    margin-top: 48px;
    margin-bottom: 30px;
  }
`;

export const ResponsiveColumnWrapper = styled.div`
  ${({ marginTop, video }) => css`
    display: flex;
    justify-content: center;
    margin-top: ${marginTop[0]}px;
    width: 100%;

    ${theme.mq.untilMega} {
      margin-top: ${marginTop[1]}px;
    }

    ${theme.mq.untilKilo} {
      margin-top: ${marginTop[2]}px;
    }
    ${video &&
    css`
      div {
        width: 90%;
        background-color: unset;
        display: flex;
        justify-content: center;
      }

      video {
        width: 100%;
        max-width: unset;

        ${theme.mq.untilMega} {
           {
            margin-top: 32px;
          }
        }

        ${theme.mq.untilKilo} {
           {
            margin-top: 24px;
          }
        }
      }
    `}
  `}
`;
