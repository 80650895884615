import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const CardArrow = ({
  className,
}) => {
  const StyledArrow = styled.svg`
    display: block;
    margin: 5% auto;
  `;

  return (
    <StyledArrow
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="30"
      viewBox="0 0 51 38"
      className={className}
    >
      <polygon fill="#FFF" fillRule="evenodd" points="356.698 5155 354.547 5157.106 369.919 5172.496 325 5172.496 325 5175.504 369.919 5175.504 354.547 5190.91 356.698 5193 375.668 5174.002" transform="translate(-325 -5155)" />
    </StyledArrow>
  );
};

CardArrow.propTypes = {
  className: PropTypes.string,
};

export default CardArrow;
