import React from 'react';
import PropTypes from 'prop-types';

const TimelineGraphic = ({ height, count }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height={height}
  >
    <defs>
      <filter id="icon_line-a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-b">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-c">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-d">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-e">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-f">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-g">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-h">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-i">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-j">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-k">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="icon_line-l">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.223529 0 0 0 0 0.003922 0 0 0 0 0.007843 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(.318 1.37)">
      <line x1="19" x2="19" y1="0" y2="100%" stroke="#885A4F" strokeLinecap="square" strokeWidth="4" />
      {[...Array(count)].map((item, i) => (
        <svg key={i} x="0" y={`${100 * (3 * i + 1) / (3 * (count + 1))}%`}>
          <g fill="#390102">
            <rect width="11.922" height="1.049" x="13" y="0" />
          </g>
        </svg>
      ))}
      {[...Array(count)].map((item, i) => (
        <svg key={i} x="0" y={`${100 * (3 * i + 2) / (3 * (count + 1))}%`}>
          <g fill="#390102">
            <rect width="11.922" height="1.049" x="13" y="0" />
          </g>
        </svg>
      ))}
      <svg x="0" y={`${100 * (3 * count + 1) / (3 * (count + 1))}%`}>
        <g fill="#390102">
          <rect width="11.922" height="1.049" x="13" y="0" />
        </g>
      </svg>
      <svg x="0" y={`${100 * (3 * count + 2) / (3 * (count + 1))}%`}>
        <g fill="#390102">
          <rect width="11.922" height="1.049" x="13" y="0" />
        </g>
      </svg>

      {[...Array(count)].map((item, i) => (
        <svg key={i} x="0" y={`calc(${100 * (i + 1) / (count + 1)}% - 18.5px)`}>
          <g>
            <circle cx="18.5" cy="18.5" r="18.5" fill="#2C1513" opacity=".155" />
            <circle cx="19" cy="18" r="10" fill="#2C1513" />
          </g>
        </svg>
      ))}
    </g>
  </svg>
);

TimelineGraphic.propTypes = {
  height: PropTypes.string,
  count: PropTypes.number,
};

TimelineGraphic.defaultProps = {
  height: 700,
  count: 5,
};

export default TimelineGraphic;
