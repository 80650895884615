import React, { useContext } from 'react';
import Tims4GoodContext from './context/timsForGoodContext';

const strings = {
  en: {
    common: {
      forGood: 'for good',
      ourCommitmentTo: 'our commitment to',
      learnMore: 'Learn more',
      playVideo: 'Play video',
    },
    header: {
      smallChanges: 'Small changes',
      bigImpact: 'big impact.',
      paragraph:
        'At Tim Hortons, with over 4,000 locations across Canada, we are proud to be a part of every community and recognize that given our scale, every small thing we do or change we make has the potential to make a big impact.',
      wereLaunching:
        'We’ve launched Tims for Good, a platform focused on building a more sustainable path forward through three lead commitments.',
      visit: 'Visit the',
      tfgUrlName: 'Tim Hortons Newsroom',
      tfgUrl: 'https://www.news.timhortons.ca/en/tims-for-good',
      latestStories: 'for all the latest stories about Tims for Good',
      headerImgSrc: 'https://cdn.rbi.digital/t4g/rbi_header_video_lo-res.jpg',
      transcript: [
        'We all want to be good to our planet.',
        '(We see 3 Canadians that are doing their own good for our planet. Alexa from Maple Ridge, British Columbia is taking a video on her llama farm while enjoying a Tims coffee. Aliya from Surrey, British Columbia is picking up trash on a beach. Noxi from Montréal, Quebec is showing her home-grown mushrooms.)',
        'For Andréann, it’s turning recycled material into a work of art.',
        '(We see Andréann from Montréal, Quebec creating beautiful jewelry out of recycled materials.)',
        'For us, it’s turning our packaging into potential.',
        '(We see a Tims Restaurant Owner, Fadi Jajjika, in front of a Tims restaurant in Vaughn, Ontario. Fadi is standing proudly and has a Tims reusable cup in hand.)',
        'We’ve eliminated over one billion single-use plastics across Canada.',
        '(We continue to see a Canadians doing their own initiatives to support the planet. A young boy, Jaimz, and his mother Amanda from Toronto, Ontario tend to microgreens that they grow in their home. Kaylea from King City, Ontario sips a Tims coffee from a reusable cup on her sustainable farm, while she feeds her compost to her goats and chickens. Text appears on screen ‘over 1 billion single-use plastics’ while we hear this line being spoken.)',
        'And we’re continuing our journey to do more good by making our Guest packaging reusable, recyclable, or compostable.',
        '(We see Tims packaging that correspond with initiatives to make items reusable, recyclable or compostable. Lamarr from Lipton, Saskatchewan holds up a reusable Tims tumbler. Noxi from Montréal, Quebec holds a Tims Quencher beverage in a recyclable cold beverage cup with a strawless lid. Ting from Misissauga, Ontario places a compostable paper straw into a Tims Iced Capp.)',
        'Because when we make small changes in neighbourhoods near and far, we can make a big impact far and wide.',
        '(We see a montage of beautiful Canadian landscapes including a lighthouse from the East Coast and mountain range from the West Coast, and of Tims restaurants across Canada. Text appears on screen as we hear ‘Small changes near and far’ and ‘big impact far and wide.’)',
        'Tims for Good. Visit timhortons.ca to learn more.',
        '(We return to our Restaurant Owner, Fadi, who makes a cheers gesture with a Tims reusable cup to the camera while standing in front of a Tims restaurant. The commercial ends on the Tims for Good logo and directs users to learn more about our initiatives at timhortons.ca/tims-for-good)',
      ],
    },
    commitments: {
      foodBev: 'our commitment to food and beverage quality',
      planet: 'our commitment to the planet',
      people: 'our commitment to people and communities',
      peopleAlt: 'Coffee beans in a metal scooper',
      planetAlt:
        'A coffee cup held in a hand over a background of beautiful green mountains',
      foodBevAlt:
        'A freshly-cracked egg being cooked on a stovetop in a round egg cooker.',
    },
    foodBev: {
      foodBevQuality: 'food and beverage quality',
      header:
        'Committed to using quality ingredients with no artificial colours, flavours or preservatives.',
      subheader:
        'At Tims, ‘Always Fresh’ isn’t just a saying, it’s a way of life. From our commitment to using simple ingredients to brewing 100% Premium Arabica Coffee every 20 minutes, we’re serving our guests high quality, great tasting food and beverages, each and every day.',
      tabs: [
        {
          title: 'Ingredients You Can Feel Good About',
          text: [
            'Our bagels, English muffins and biscuits are baked without artificial flavours or preservatives, and we are committed to having all permanent menu items free of artificial colours, flavours or preservatives.\n\n',
            'For more information on Tim Hortons sustainability commitments, visit ',
            <a
              href="https://rbi.com/English/corporate-profile/default.aspx"
              key="link-corporate-profile"
            >
              rbi.com
            </a>,
            '.',
          ],
          imgURL: 'https://cdn.rbi.digital/t4g/Tabbed-menu_Ingredients2.png',
          eventKey: 'Ingredients',
          isActive: true,
          alt: 'A Tim Hortons classic bacon breakfast sandwich including cheese, a fresh-cracked egg and bacon on a toasted English muffin',
        },
        {
          title: 'Nutrition & Transparency',
          text: [
            'We aim to provide guests with a variety of menu options and the information they need to make educated choices that fit their individual lifestyles. We’re aiming to continuously improve the nutrient profile of our food while preserving that same great taste that our guests love.  \n\nFor more information on Tims’ nutrition, please visit ',
            <a
              href="https://www.timhortons.ca/nutrition-and-allergens"
              key="link-nutrition"
            >
              our nutrition and allergens page.
            </a>,
          ],
          imgURL: 'https://cdn.rbi.digital/t4g/Tabbed-menu_Nutrition2.png',
          eventKey: 'Nutrition & Transparency',
          isActive: false,
          alt: 'A Tim Hortons Boston Cream donut that is ripped in halves, showing the venetian cream donut filling',
        },
        {
          title: 'Canadian Sourcing',
          text: 'Our team is always looking to source key ingredients domestically, wherever possible. In Canada, that means milk, cream, butter, cheese, eggs, and potatoes for our hash browns and wedges. We think it’s important that we source Canadian where we can.',
          imgURL: 'https://cdn.rbi.digital/t4g/Tabbed-menu_Local_Sourcing2.png',
          eventKey: 'Canadian Sourcing',
          isActive: false,
          alt: 'A Tim Hortons Roast Beef Craveable Sandwich made of a sandwich bun, cheese, roast beef, and more',
        },
        {
          title: 'Food Waste',
          text: 'We’re developing plans, setting targets, and forging partnerships to manage our food waste sustainability.',
          imgURL: 'https://cdn.rbi.digital/t4g/Tabbed-menu_Food_Waste2.png',
          eventKey: 'Food Waste',
          isActive: false,
          alt: 'A Tim Hortons Vanilla Dip Donut with colourful sprinkles and a bite taken out of it',
        },
      ],
    },
    planet: {
      ourCommitment: 'our commitment to the',
      planet: 'planet',
      subtitle:
        'We’re on a long-term journey to make the food and beverage packaging served to guests at Tim Hortons<sup>®</sup> restaurants in Canada reusable, recyclable or compostable. ',
      text: [
        'We recognize that our environmental footprint spans beyond the land our Tims restaurants sit on. We have already made progress by changing over 3 billion units of packaging to make them reusable, recyclable, or compostable, and eliminating more than 1 billion single-use plastics in 2021.',
        'We’ll keep you updated on our successes and challenges as we strive towards our goal. Click on the timeline to explore our progress and upcoming milestones. ',
      ],
      ariaLabel: 'our commitment to the planet',
      years: ['2019', '2020', '2021', '2022'],
      timeline: {
        2019: [
          {
            date: 'May 2019',
            eventKey: 'May 2019',
            feature: '100% Recyclable Hot Beverage Lids',
            copy: 'A hot beverage lid made from polypropylene, a material that is 100% recyclable and accepted in 95% of curbside recycling programs across Canada. Launched in participating restaurants.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_May2019_Recyclable-Hot-Beverage-Lids.jpg',
            alt: 'A Tim Hortons hot beverage lid that is 100% recyclable, placed on a wooden table',
          },
          {
            date: 'July 2019',
            eventKey: 'July 2019',
            feature: 'Strawless Cold Beverage Lids',
            copy: 'Iced cold beverages in our Canadian and United States restaurants served with a strawless lid in order to eliminate the need for straws. This is estimated to remove 137 million plastic straws out of circulation annually.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Jul2019_Strawless-Cold-Beverage-Lids.jpg',
            alt: 'A new Tim Hortons cold beverage lid that features a strawless design, shown on top of a black iced coffee',
          },
          {
            date: 'Nov 2019',
            eventKey: 'Nov 2019',
            feature: '100% Recycled Fibre Takeaway Bags',
            copy: 'Takeaway bags made of 100% recycled fibre paper in Canada and the United States.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Nov2019_Recycled-Fiber-Takeaway-Bags.jpg',
            alt: 'Two Tim Hortons take out paper bags made of 100% recycled fibre propped on a wooden table',
          },
          {
            date: 'Dec 2019',
            eventKey: 'Dec 2019',
            feature: 'Wooden Stir Sticks',
            copy: 'Compostable stir sticks made available across Canada and the United States, eliminating 75 million plastic stir sticks in 2021.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Dec2019_Wooden-Stir-Sticks.jpg',
            alt: 'A handful of wooden stir sticks sitting on a wooden table',
          },
        ],
        2020: [
          {
            date: 'June 2020',
            eventKey: 'June 2020',
            feature: 'Everyday Reusable Cups',
            copy: [
              'As part of our Bring-Your-Own-Cup collection, we launched everyday reusable cups that are now available at participating restaurants in Canada.',
              'Some cups have indicated fill lines and we also have a specially developed portioning tool making it easy for Team Members to prepare drinks to the correct proportions. This allows a guest to bring any cup into our restaurants while still maintaining the same consistent experience they would come to expect with a single use cup.',
            ],
            imgSrc: 'https://cdn.rbi.digital/t4g/Reusable_Cups.jpeg',
            alt: 'A collection of 8 different reusable Tim Hortons drinkware cups lined up on a table',
          },
          {
            date: 'Oct 2020',
            eventKey: 'Oct 2020',
            feature: '30% Recycled Material Cup (Pilot)',
            copy: 'Expansion of new cup trial in Calgary and Toronto which focuses on developing a new Tims hot beverage cup made with 30% recycled material.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Oct2020_Recycled-Material-Cup.jpg',
            alt: 'A Tim Hortons hot beverage lid that is made of 30% recycled material',
          },
          {
            date: 'Nov 2020',
            eventKey: 'Nov 2020',
            feature: 'Compostable Cup Liner (Pilot)',
            copy: [
              'Pilot project at select Vancouver restaurants with the aim of introducing a paper cup that is easier to recycle and possible to compost.',
              'We continue to work with key industry and government stakeholders to ensure the cup meets municipality regulations to introduce this into more markets in Canada.',
            ],
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Nov2020_Compostable-Cup-Liner.jpg',
            alt: 'A hand is holding a red Tim Hortons hot beverage cup that is made with a compostable cup liner',
          },
          {
            date: 'Nov 2020',
            eventKey: 'Nov 2020',
            feature: 'No More Double Cupping',
            copy: 'No longer double cupping at Canadian restaurants, so grab a recyclable sleeve instead! By completely eliminating double cupping, we can save up to 200 million cups per year*',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Nov2020_No-More-Double-Cupping.jpg',
            alt: 'A Tim Hortons hot beverage cup and a cup sleeve on a wooden table',
          },
        ],
        2021: [
          {
            date: 'Jan 2021',
            eventKey: 'Jan 2021',
            feature: '100% Recycled Fibre Napkins',
            copy: 'New napkins made of 100% recycled fibre, including 90% post-consumer content, and 25% less material than before. This change for restaurants in Canada and the United States saved 1,210 tonnes of paper in 2021.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Jan2021_Recycled-Fiber-Napkins.jpg',
            alt: 'A stack of brown napkins made of 100% recycled fibres',
          },
          {
            date: 'Jan 2021',
            eventKey: 'Jan 2021',
            feature: 'Recyclable Sandwich Packaging',
            copy: 'New paper-based wrappers have been rolled out for sandwiches and bagels that are fully recyclable, while also cutting the use of paper by 17% annually. This reduced more than 379 tonnes of plastic in 2021.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Jan2021_Recyclable-Sandwich-Packaging.jpg',
            alt: 'Two paper-based recyclable sandwich wrappers for Tim Hortons sandwich and bagel products laid out on a table',
          },
          {
            date: 'Apr 2021',
            eventKey: 'Apr 2021',
            feature: 'Removing Plastic Straws',
            copy: [
              'Plastic straws were phased out of Canadian restaurants, removing more than 319 million single use plastics in 2021.',
              'Excludes bendable straws that are still available at restaurants upon guest request. These are made available for accessibility purposes.',
            ],
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Apr2021_Removing-Plastic-Straws.jpg',
            alt: 'White paper straws lined up on a wooden table',
          },
          {
            date: 'Oct. 2021',
            eventKey: 'Oct 2021',
            feature: 'AI Recycling Pilot',
            copy: 'New partnership with Vancouver-based Intuitive AI to pilot artificial intelligence-assisted technology at select restaurants across Canada with the goals of providing recycling and composting education for guests and driving increased recycling and diversion rates.',
            imgSrc: 'https://cdn.rbi.digital/t4g/AI_Recycling.jpg',
            alt: 'A woman standing in front of an AI generated screen that indicates the appropriate disposal method for the plastic Tim Hortons bottle that she is holding',
          },
          {
            date: 'Nov. 2021',
            eventKey: 'Nov 2021',
            feature: 'Loop Partnership Pilot Launch',
            copy: 'New partnership with Loop to pilot a new reusable and returnable cup system for beverages on the go.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Oct2020_Loop-Partnership.jpg',
            alt: 'A Tim Hortons cup and donut box that are returnable and reusable sat on a table outdoors',
          },
        ],
        2022: [
          {
            date: 'Feb. 2022',
            eventKey: 'Feb 2022',
            feature: 'White Lid Launch',
            copy: 'We introduced new white hot beverage lids at select restaurants across Canada. Based on industry consultations about the future of recycling in Canada, we believe that white recyclable materials have better potential to be reused and repurposed into more new products, helping us close the loop on recycling more of our packaging.',
            imgSrc: 'https://cdn.rbi.digital/t4g/New_White_Lids.jpeg',
            alt: 'A Tim Hortons red hot beverage cup with a white lid on a wooden table',
          },
          {
            date: 'Apr. 2022',
            eventKey: 'Apr 2022',
            feature: 'Recyclable and Compostable Cup Pilot',
            copy: 'Partnership with WestRock to launch a test of an innovative new hot beverage cup design in April 2022 at select Vancouver restaurants. The test will feature cups that are made with up to 20 per cent post-consumer recycled content and are compostable and recyclable. Subject to local municipality’s recyclable and compostable requirements and available processing facilities.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Recyclable_and_Compostable_Cup.jpeg',
            alt: 'A Tim Hortons red hot beverage cup made of recyclable and compostable materials',
          },
          {
            date: 'Q4 2022',
            eventKey: 'Q4 2022',
            feature: 'Fibre Hot Beverage Lid Pilot',
            copy: 'Trials of a plastic-free, recyclable and compostable lid at select restaurants in Canada are planned for Q4 2022.',
            imgSrc: 'https://cdn.rbi.digital/t4g/Fibre_Lid.jpg',
            alt: 'A Tim Hortons red hot beverage cup with a cream-coloured lid made of plastic-free, recyclable and compostable fibre',
          },
          {
            date: '2022',
            eventKey: '2022',
            feature: 'Borrow-A-Cup Program Launch',
            copy: 'We launched a 10-restaurant Borrow-a-Cup reusable cup pilot in partnership with Return-It in Vancouver, British Columbia. At these restaurants, Guests can borrow a Tims reusable cup for a $4.00 deposit that they will get back upon returning it. Returns are accepted at these 10 restaurants, as well as in public space return bins and other Sharewares locations around Vancouver.',
            imgSrc: 'https://cdn.rbi.digital/t4g/Borrow_A_Cup.jpg',
            alt: 'A reusable Tim hortons cup and a cup return bin',
          },
          {
            date: 'Nov. 2022',
            eventKey: 'Nov. 2022',
            feature: '25% PCR (Post Consumer Recycled) Lids',
            copy: 'We tested a first-of-its-kind in Canada hot drink lid with 25% post-consumer recycled polypropylene with the aim of helping to drive a circular economy. The lids are recyclable and made partly of recycled material.',
            imgSrc: 'https://cdn.rbi.digital/t4g/PRC_Lids.jpg',
            alt: 'A Tim Hortons hot beverage cup lid made of 25% post-consumer recycled material.',
          },
          {
            date: '2022-2023',
            eventKey: '2022-2023',
            feature: 'Flat Sheet Wrap Packaging',
            copy: 'In 2022, we began transitioning from our red Wrap Box packaging to a new Flat Sheet packaging for all Breakfast and Lunch wraps. The new Flat Sheets use 75% less material than Wrap Boxes, resulting in savings of more than 1,400 tonnes of material annually.',
            imgSrc: 'https://cdn.rbi.digital/t4g/Wrap_Sheet_Evolution.png',
            alt: 'A sandwich wrap box next to a sandwich wrap sheet with an arrow between them to symbolize a transition',
          },
        ],
      },
    },
    projects: {
      videoDesc:
        'Check out how some of these exciting projects and more live within our restaurants!',
    },
    people: {
      pplComm: 'people and communities',
      header: 'Continuously giving back to our communities',
      text: 'When we first opened our doors in 1964, the intention was to create a place where anyone could go at any time and feel at home. It was a small gesture at the time, but today, we consider it our duty to be a good neighbour and contribute to local Canadian communities throughout the year. ',
      cards: [
        {
          title: 'Tim Hortons Foundation Camps',
          eventKey: 'Tim Hortons Foundation Camps',
          desc: 'Tim Hortons Foundation Camps is a non-profit organization that, through a multi-year camp-based program, helps youth develop skills like leadership, resilience, and responsibility. Each year, all restaurants host Camp Day, when 100% of proceeds from coffee and net proceeds of bracelet sales are donated back to Tims Camps.',
          url: 'https://timscamps.com/',
          imgSrc: 'https://cdn.rbi.digital/t4g/People_Tims_Camps.jpg',
          alt: 'A group of youth campers at Tim Hortons Foundation Camps sit together joyfully on a beautiful day outside at camp.',
        },
        {
          title: 'Smile Cookie',
          eventKey: 'Smile Cookie',
          desc: 'Every year, for one week, 100% of proceeds of our chocolate chunk cookies, hand decorated with smiles, are donated to local charities chosen by Tim Hortons restaurants. Last year, more than $15 million was donated to local charities, hospitals and community groups across Canada.',
          url: 'https://www.timhortons.ca/smile-cookie',
          imgSrc: 'https://cdn.rbi.digital/t4g/People_Smile_Cookie.jpg',
          alt: 'A young boy smiles while holding a Tim Hortons Smile Cookie up to face the camera',
        },
        {
          title: 'Timbits Hockey & Soccer',
          eventKey: 'Timbits Hockey & Soccer',
          desc: 'Tims restaurant owners annually support over 300,000 children between 4 to 8 years old by removing financial barriers and providing them access to entry-level organized sports with an emphasis on learning a new game, making new friends, and having fun.',
          url: 'https://www.timhortons.ca/timbits-sports',
          imgSrc:
            'https://cdn.rbi.digital/t4g/People_Timbits Hockey & Soccer.jpg',
          alt: 'Two teams of young Timbits Soccer players are in the middle of an exciting soccer game as a young girl in a blue jersey kicks the ball',
        },
        {
          title: 'Coffee Trucks & Donations',
          eventKey: 'Coffee Trucks & Donations',
          desc: 'At Tims, we know the power of teamwork. Our restaurant owners and donation vehicles are proud to drop off free coffee and baked goods to thank and recognize those who serve our communities. Since 2020, we’ve proudly donated over 2 million cups of coffee across Canada.',
          url: 'https://www.timhortons.ca/tims-for-good-donations',
          imgSrc:
            'https://cdn.rbi.digital/t4g/People_Tims_for_Good_Donation-Platform.jpg',
          alt: 'A Tim Hortons coffee truck is parked in front a lake and beautiful sunset',
        },
        {
          title: 'Special Olympics Donut',
          eventKey: 'Special Olympics Donut',
          desc: 'Every year our Special Olympics Donut campaign has a goal of raising funds to help expand the quality, opportunity and accessibility of Special Olympics\' daily community sport programs for Canadians with an intellectual disability. 100% of the proceeds from each donut are donated to Special Olympics Canada.',
          url: 'https://www.timhortons.ca/specialolympics',
          imgSrc: 'https://cdn.rbi.digital/t4g/Special_Olympics_Donut_EN.jpg',
          alt: 'A young female Special Olympics Athlete smiles at the camera while holding a Tim Hortons Special Olympics Donut: a chocolate donut topped with sprinkles and whipped cream',
        },
        {
          title: 'Orange Donut',
          eventKey: 'Orange Donut',
          desc: 'Every year on National Day for Truth and Reconciliation in Canada, when Guests buy an Orange Sprinkle Donut, 100% of proceeds go to support Indigenous organizations such as the Orange Shirt Society and Indian Residential School Survivors Society.',
          url: 'https://www.timhortons.ca/orange-sprinkle-donut',
          imgSrc: 'https://cdn.rbi.digital/t4g/Orange_Donut.png',
          alt: 'A Tim Hortons restaurant owner stands in front of his restaurant in Kamloops, BC holding a 6-pack of Tim Hortons’ Orange Sprinkle Donuts',
        },
      ],
    },
    community: {
      preheader:
        'Our commitment to communities extends to the regions where we source our coffee.',
      standaloneVideoId: 'XAMAS1qZ54Q',
      standaloneVideoAlt: 'Coffee for Communities',
      proudlySupporting:
        'There is a coffee farming community behind every cup of Tims coffee that we serve. Together with our local partners, we aim to continuously strengthen the businesses and futures of coffee farmers.',
      enveritasHeader: 'Enveritas',
      enveritasSubHeader:
        'We partner with Enveritas to audit 100% of our coffee bean purchases each year under a set of social, economic and environmental indicators.',
      enveritas:
        'Through our coffee for communities program, we use Enveritas’ unique model, an innovative and accurate way to measure farm level sustainability for all of the coffee that we purchase. Together we engage every supplier and use the accuracy of the data to identify challenges facing farmers within our supply chain. This allows us to design programs that focus efforts on our commitment to enhancing farmer livelihoods in all of our coffee farming communities, while contributing to the overall sustainability and quality of coffee production.',
      enveritasAlt: 'Two male coffee farmers surrounded by lush coffee plants on their coffee farm smiling at each other while having a conversation',
      farmerCenteredHeader: 'Using a farmer centered approach',
      farmerCenteredSubHeader:
        'With our partners, we work with coffee farmers to provide them the technical and business skills to unlock their potential. This is achieved through:',
      farmers: [
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/farmer_1.png',
          caption: 'Empowering women to be leaders',
          alt: 'A young female coffee farmer in a pink top poses proudly for a photo in front of her coffee farm',
        },
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/farmer_2.png',
          caption: 'Improving farming practices',
          alt: 'A woman farmer is in the process of harvesting coffee plants on a coffee farm and placing the products into a basket',
        },
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/farmer_3.png',
          caption: 'Training young farmers',
          alt: 'Two male coffee farmers inspect a coffee plant and discuss the product.',
        },
      ],
      learnMoreHeader: 'Learn more by watching our videos below',
      learnMoreVideos: [
        {
          embedId: '022wbr3Jdmg',
          title: 'Empowering Women To Be Leaders',
        },
        {
          embedId: 'gJ-u9yKageA',
          title: 'Improving Farming Practices',
        },
        {
          embedId: 'abtzBNM19Xw',
          title: 'Training Young Farmers',
        },
      ],
      monitoringHeader: 'Monitoring and evaluation',
      monitoringPoints: [
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/leaf_icon_green.png',
          imgAlt: 'leaf icon',
          caption:
            'A methodical approach is used to measure progress in this program over time',
        },
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/project_icon_green.png',
          imgAlt: 'project icon',
          caption:
            '14 projects in 13 sourcing regions within our producing countries',
        },
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/farmer_icon_green.png',
          imgAlt: 'farmer icon',
          caption: 'Direct support of 18,000 farmers through 2023',
        },
      ],
      susHeader:
        'Sustainability is a journey. We aim to continuously strengthen coffee farming communities through improved practices and prosperity.',
      cfcTitle: 'Tims Coffee for Communities program',
      cfcText:
        'Helping empower coffee farmers to improve their livelihoods through training in partnership with local coffee exporters, not-for-profits and producer organizations.',
      cfcLogoUrl:
        'https://cdn.rbi.digital/t4g/logo_Coffee-for-Communities_en.png',
      cfcLogoAlt: 'Tim Hortons Coffee for Communities Program',
      cfcVideo: {
        buttonTitle: 'Show transcript',
        transcript: [
          'Tims for good ',
          'At Tim Hortons, we believe in the businesses and future of coffee farmers. That’s why, we’re helping empower them through training with our partners to improve their livelihoods.',
          'Tim Hortons partners with local coffee exporters, not-for-profit and producer organizations.',
          'Yelstin (Spanish): They’ve made the time and commitment to come and teach me new skills to innovate my lands.',
          'This project has supported Yeltsin by providing him training using the coffee and climate approach which teaches farmers how to assess and react to different climate related impacts on their coffee farms. It has also provided him seeds to cultivate crops and coffee varieties that are more tolerant to the effects of climate change.',
          'This is the Tims Coffee for Communities program',
          'This is the Tims Coffee for Communities program. And there’s a farmer behind every Tims cup we serve.',
          'Learn more at Tim Hortons dot ca',
          'Learn more at Timhortons.ca/tims-for-good',
          'Tims for good (logo) Small changes, big impact.',
          '© Tim Hortons, 2023.',
        ],
      },
      tabs: [
        {
          title: 'The Program',
          text: [
            'Since 2005, Tims has worked with smallholder coffee farming families who are most in need of our support. \n\nOur partnership uses a hands-on, collaborative, and long-term approach to help farmers develop the knowledge and skills to become active participants in the coffee value chain, creating a positive change in coffee growing communities.',
          ],
          imgURL: 'https://cdn.rbi.digital/t4g/img_people_the-program.png',
          eventKey: 'Community Program',
          isActive: true,
        },
        {
          title: 'Enveritas Partnership',
          text: 'We are proud to partner with Enveritas, who verifies 100% of our purchases each year under a set of Social, Economic and Environmental standards that measure farm-level sustainability. We engage every supplier and use technology and data to understand farmer realities in our supply chain. Our partnership allows us to ‘deep dive’ and support continuous improvement in each of these three areas, while focusing on achieving big sustainability goals with our partners.',
          imgURL:
            'https://cdn.rbi.digital/t4g/img_people_enveritas-partnership.png',
          eventKey: 'Community Enveritas Partnership',
          isActive: false,
        },
        {
          title: 'Our Objective',
          text: 'Our work addresses six components: promoting gender inclusion, promoting a viable economic future for young adults, improving coffee quality, strengthening climate-smart production, building and strengthening farmer organizations, and building partnerships through collaborative initiatives.',
          imgURL: 'https://cdn.rbi.digital/t4g/img_people_objectives.png',
          eventKey: 'Community Our Objectives',
          isActive: false,
        },
        {
          title: 'Implementation',
          text: 'We work to empower through education because often the best innovation comes from farmers themselves. We are encouraging early adoption of new farm technologies, ecological solutions and the use of tech innovation to improve farm practices. We also support coffee farmers to use climate-smart agricultural practices, which are critical to the sustainability of their business and livelihoods.\n\nProjects have been implemented in Guatemala, Honduras, Colombia, Brazil, and Tanzania, and have helped nearly 30,000 coffee farmers and their families to date.',
          imgURL: 'https://cdn.rbi.digital/t4g/img_people_implementation.png',
          eventKey: 'Community Implementation',
          isActive: false,
        },
      ],
    },
    quotes: {
      quote1:
        'I’m proud to build upon my family’s coffee legacy and share my family’s story as a facilitator to involve young adults in the coffee value chain.',
      quote1Source: '- Kevin Leveron (Coffee Farmer, Honduras)',
      quote1Credit: 'Photo by Hanns R. Neumann Stiftung',
      quote2:
        'I’ve just set up my first demonstration plot with new varieties, cover crops and crop distancing as part of the climate training I received.',
      quote2Source: '- Jessie Karen España Galdámez (Coffee Farmer, Honduras)',
      quote2Credit: 'Photo by Hanns R. Neumann Stiftung',
    },
    tv: {
      title: 'Check out our Tims for Good TV commercial!',
      buttonTitle: 'Show transcript',
      transcript: [
        '(Exterior Tim Hortons restaurant is shown at dawn)',
        'Orville Bernard, Tims restaurant owner: Across Canada, we’ve launched Tims for good.',
        '(Tims restaurant owner Orville Bernard appears)',
        '(Tims restaurant owner Cathy Hopkins appears)',
        'Cathy Hopkins, Tims restaurant owner: Tims for good is an initiative to bring positive changes to our food and beverages, planet, people and communities.',
        '(Tims guest Christian B. appears holding hands, with a Tims cold beverage cup in hand)',
        '(Guest appears drinking from a Tims cup)',
        '(Deer appears outside of Tims restaurant)',
        '(Locke family appears)',
        '(Screen shows a freshly cracked egg)',
        '(Screen divides to show freshly cracked egg in raw and cooked state)',
        'Orville Bernard, Tims restaurant owner: We’re in a journey to elevate the quality for food and beverages.',
        '(Guest picks up a freshly cracked egg sandwich)',
        '(Screen divides into six to show the preparation of freshly cracked egg bagel sandwich)',
        'Cathy Hopkins, Tims restaurant owner: We’re committed to removing a billion single-use plastics by the end of the year.',
        '(Paper straws appear)',
        '(Screen is divided into three with paper straws, guest holding a reusable cup and wooden stir sticks)',
        '(Screen is divided into six to show guest holding a reusable cup, wooden stir sticks, Locke family with a cold beverage, guest stirring their beverage with a wooden stir stick, a reusable cup and cold beverage lids)',
        'Orville Bernard, Tims restaurant owner: And our coffee is 100% ethically sourced',
        '(Screen shows coffee being poured into a reusable cup) ',
        '(Screen is divided into three with zoomed in footage of coffee on the sides and coffee being poured into a reusable cup in the middle) ',
        '(Screen is divided into nine, showing our coffee plants, beans, bags, pouring into a reusable cup, coffee footage zoomed in, and coffee farmer) ',
        'Cathy Hopkins, Tims restaurant owner: Together, we can make small changes that will make a big impact ',
        '(Screen is divided into three, showing a freshly cracked egg, paper straws and coffee being poured to a reusable cup) ',
        '(All of the initiatives come together in a large mosaic, showing freshly cracked egg bagel sandwiches, reusable cups, paper straws, coffee plants, our guests picking up coffee, the Locke family, cold beverage lids, wooden stir sticks, guest enjoying coffee at the station, coffee plants, coffee beans, guest at drive through, Tims croissants and different angles of coffee poured to a reusable cup. The scene features the Locke family, the Caron Family, Kibu K. and Christian B.) ',
        '(Mosaic fades out to Tims for good logo, with brown craft napkin background show the red maple leaf and the timhortons.ca/tims-for-good link in white) ',
        'Orville Bernard, Tims restaurant owner: Tims for good',
      ],
    },
    mapNav: {
      title:
        'Click on a country to learn more about the communities behind them.',
      guatemala: 'Guatemala',
      colombia: 'Colombia',
      brazil: 'Brazil',
      indonesia: 'Indonesia',
      backButton: 'Back to initial map page',
      guatemalaButton: 'Back to Guatemala farmers’ list',
      colombiaButton: 'Back to Colombia farmers’ list',
      brazilButton: 'Back to Brazil farmers’ list',
      indonesiaButton: 'Back to Indonesia farmers’ list',
    },
  },
  fr: {
    common: {
      forGood: 'Du bien au quotidien.',
      ourCommitmentTo: 'Notre engagement envers',
      playVideo: 'Play video',
      learnMore: 'En savoir plus',
    },
    header: {
      smallChanges: 'Petits changements',
      bigImpact: 'grand impact.',
      paragraph:
        'Tim Hortons est fier de faire partie d’un grand nombre de communautés avec ses quelque 4000 restaurants répartis dans tout le Canada, et compte tenu de notre envergure, nous reconnaissons que même les petits changements peuvent avoir un grand impact.',
      wereLaunching:
        'Nous avons lancé le programme « Tim. Du bien au quotidien. », une plateforme qui prône l’adoption de solutions plus durables et repose sur nos engagements dans trois grands domaines.',
      visit: 'Visitez',
      tfgUrlName: 'la page Actualités de Tim Hortons pour connaître',
      tfgUrl: 'https://www.news.timhortons.ca/fr/tim-du-bien-au-quotidien',
      latestStories:
        'toutes les dernières nouvelles concernant Tim. Du bien au quotidien.',
      headerImgSrc: 'https://cdn.rbi.digital/t4g/rbi_header_video_lo-res.jpg',
      transcript: [
        'On veut tous faire du bien à la planète.',
        '(On voit 3 Canadiens qui chacun contribuent au bien de la planète: D’abord, Aliya de Surrey en Colombie-Britannique, qui ramasse des déchets sur une plage. Puis, Alexa de Maple Ridge en Colombie-Britannique, qui tourne une vidéo d’elle-même sur sa ferme de lamas, en dégustant un café Tim. Finalement, Noxi de Montréal, qui nous montre les champignons de son potager.)',
        'Pour Andréann, c’est en recyclant de vieux bijoux en boucles d’oreille.',
        '(On voit Andréann, de Montréal, qui crée des boucles d’oreille à partir de bijoux recyclés.)',
        'Pour nous, c’est en donnant leur plein potentiel à nos emballages. ',
        '(On voit un bagel, ainsi qu’un café Tim dans un gobelet réutilisable, suivi d’Amanda de Toronto en Ontario, qui s’occupe de ses plantes qu’elle cultive à la maison.)',
        'On a éliminé plus d’un milliard de produits en plastique à usage unique partout au pays.',
        '(On voit Kaylea, de King City en Ontario, qui boit du café Tim de son gobelet réutilisable sur sa ferme durable, alors qu’elle nourrit du composte à ses chèvres et ses poulets. En même temps d’être dicté, le texte « On a éliminé plus d’un milliard de produits en plastique à usage unique » apparaît à l’écran.)',
        'Et on veut continuer à faire plus de bien, en rendant nos emballages réutilisables, recyclables ou biodégradables.',
        '(On voit ensuite de différents emballages Tim, qui représentent les initiatives pour rendre ces items réutilisables, recyclables ou biodégradables. Lamarr, de Lipton en Saskatchewan qui boit de sa tasse Tim réutilisable. Puis, Noxi de Montréal, qui tient son RaffraîchiTim dans son gobelet recyclable avec couvercle sans paille. Finalement, Ting de Mississauga en Ontario, qui utilise sa paille compostable en papier pour boire son Cappuccino glacé du Tim.)',
        'Parce qu’avec des petits changements à l’échelle locale et nationale, on peut avoir un impact important à l’échelle planétaire.',
        '(On voit un magnifique montage de paysages Canadiens, incluant un phare situé sur la côte est, des montagnes situées sur la côte ouest, ainsi que plusieurs restaurants Tim à travers le Canada. Alors qu’ils sont dictés, les textes « Petits changements » et « Grand impact » apparaissent à l’écran.)',
        'Tim. Du bien au quotidien. Visitez notre site Web pour en apprendre plus.',
        '(On voit un propriétaire de restaurant Tim, Fadi, qui lève son café à la caméra, devant un restaurant Tim. La vidéo se termine avec le logo « Tim. Du bien au quotidien », et incite les téléspectateurs à en apprendre plus sur nos initiatives à timhortons.ca/timdubienauquotidien)',
      ],
    },
    commitments: {
      foodBev:
        'Notre engagement envers la qualité des aliments et des boissons',
      planet: 'Notre engagement envers la planète',
      people: 'Notre engagement envers les gens et les communautés',
      peopleAlt: 'Des grains de café dans une pelle en métal',
      planetAlt:
        'Un gobelet de café tenu à la main sur fond de belles montagnes vertes',
      foodBevAlt:
        'Un œuf fraîchement cassé qui cuit dans un cuiseur à œufs rond',
    },
    foodBev: {
      foodBevQuality: 'la qualité des aliments et des boissons',
      header:
        'Nous nous engageons à utiliser des ingrédients de qualité qui ne contiennent aucun colorant, arôme ou agent de conservation de sources artificielles.',
      subheader:
        'Chez Tim, « Toujours frais » n’est pas qu’un dicton, c’est un mode de vie. Que ce soit en utilisant des ingrédients simples ou en offrant du café 100% arabica de qualité supérieure que nous infusons toutes les 20 minutes, nous servons à nos invités des boissons et des aliments délicieux et de grande qualité chaque jour.',
      tabs: [
        {
          title: 'Des ingrédients que vous approuvez',
          text: ['Nos bagels, nos muffins anglais et nos biscuits sont préparés sans arômes artificiels ni agents de conservation, et nous nous engageons à ce que tous les produits permanents du menu ne contiennent aucun agent de conservation, colorant, ni arôme artificiel.\n\n',
            'Pour obtenir de plus amples renseignements sur l’engagement de Tim Hortons en matière de durabilité, veuillez consulter le ',
            <a
              href="https://www.rbi.com/French/accueil/default.aspx#"
              key="link-corporate-profile"
            >
              rbi.com
            </a>,
            '.',
          ],
          imgURL: 'https://cdn.rbi.digital/t4g/Tabbed-menu_Ingredients2.png',
          eventKey: 'Ingredients',
          isActive: true,
          alt: 'Un TimatinMD bacon classique de Tim Hortons comprenant du fromage, un œuf fraîchement cassé et du bacon sur un muffin anglais grillé',
        },
        {
          title: 'Nutrition et transparence',
          text: [
            'Nous aspirons à offrir à nos invités toute une gamme de produits et à leur fournir toutes les informations dont ils ont besoin pour faire des choix de repas éclairés qui correspondent à leur mode de vie. Nous visons à améliorer continuellement le profil nutritionnel de nos aliments tout en préservant le goût délicieux que nos invités adorent. \n\nPour en savoir plus sur les informations nutritionnelles des produits Tim Hortons, ',
            <a
              href="https://www.timhortons.ca/information-nutritionnelle-et-allergenes"
              key="link-nutrition"
            >
              consultez notre page sur sur l&apos;information nutritionnelle et
              allergène.
            </a>,
          ],
          imgURL: 'https://cdn.rbi.digital/t4g/Tabbed-menu_Nutrition2.png',
          eventKey: 'Nutrition & Transparency',
          isActive: false,
          alt: 'Un beigne crème Boston de Tim Hortons déchiré en deux, montrant la garniture à la crème vénitienne',
        },
        {
          title: 'Des produits canadiens',
          text: 'Notre équipe cherche toujours à s’approvisionner en ingrédients clés directement au pays, dans la mesure du possible. C’est le cas au Canada pour le lait, la crème, le beurre, le fromage, les œufs et les pommes de terre servant aux galettes et aux quartiers de pommes de terre. Nous croyons qu’il est important de nous approvisionner au Canada lorsque nous le pouvons.',
          imgURL: 'https://cdn.rbi.digital/t4g/Tabbed-menu_Local_Sourcing2.png',
          eventKey: 'Canadian Sourcing',
          isActive: false,
          alt: 'Un sandwich au bœuf rôti de la plateforme Les Irrésistibles de Tim Hortons composé d’un pain à sandwich, de fromage, de bœuf rôti et d’autres ingrédients',
        },
        {
          title: 'Gaspillage alimentaire',
          text: 'Nous élaborons des plans, fixons des cibles et créons des partenariats pour gérer notre gaspillage alimentaire de manière durable.',
          imgURL: 'https://cdn.rbi.digital/t4g/Tabbed-menu_Food_Waste2.png',
          eventKey: 'Food Waste',
          isActive: false,
          alt: 'Un beigne glacé à la vanille de Tim Hortons saupoudré de vermicelles colorés; une bouchée a été prise.',
        },
      ],
    },
    planet: {
      ourCommitment: 'Notre engagement envers la',
      planet: 'planète',
      subtitle:
        'Nous avons entrepris une démarche à long terme visant à ce que les emballages dans lesquels nous servons les aliments et les boissons soient réutilisables, recyclables ou compostables, et ce, dans tous les restaurants Tim Hortons<sup>®</sup> au Canada.',
      text: [
        'Nous reconnaissons que notre empreinte environnementale s’étend au-delà du territoire sur lequel se trouvent les restaurants Tim Hortons. Nous avons déjà fait des progrès en apportant des améliorations à plus de trois milliards d’emballages pour les rendre réutilisables, recyclables ou compostables, et en éliminant plus d’un milliard d’articles de plastique à usage unique en 2021.',
        '​Nous vous tiendrons au courant de nos réussites et de ce qu’il nous reste à accomplir en vue d’atteindre notre objectif. Cliquez sur les éléments de la ligne du temps pour découvrir nos progrès et les étapes à venir.',
      ],
      ariaLabel: 'Notre engagement envers la planète',
      years: ['2019', '2020', '2021', '2022'],
      timeline: {
        2019: [
          {
            date: 'mai 2019',
            eventKey: 'May 2019',
            feature:
              'Couvercles 100% recyclables pour gobelets à boissons chaudes',
            copy: 'Nouveau couvercle à boissons chaudes fait de polypropylène, un matériau 100% recyclable et accepté par 95% des programmes de recyclage domestique au Canada. Lancé aux restaurants participants.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_May2019_Recyclable-Hot-Beverage-Lids.jpg',
            alt: 'Un couvercle à boisson chaude Tim Hortons 100 % recyclable, posé sur une table en bois',
          },
          {
            date: 'juil. 2019',
            eventKey: 'July 2019',
            feature: 'Couvercles sans paille pour gobelets à boissons froides',
            copy: 'Les boissons froides offertes dans nos restaurants canadiens et américains sont servies avec un couvercle sans paille afin de ne plus avoir besoin d’utiliser de paille. Cela permet d’éviter la mise en circulation plus de 137 millions de pailles de plastique chaque année.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Jul2019_Strawless-Cold-Beverage-Lids.jpg',
            alt: 'Un nouveau couvercle à boisson froide Tim Hortons, au design sans paille, posé sur un TimGlacé noir',
          },
          {
            date: 'nov. 2019',
            eventKey: 'Nov 2019',
            feature:
              'Sacs pour les commandes à emporter composés à 100% de fibres recyclées',
            copy: 'Nous avons lancé au Canada et aux États-Unis des sacs pour les commandes à emporter faits de fibres de papier recyclées à 100%.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Nov2019_Recycled-Fiber-Takeaway-Bags.jpg',
            alt: 'Deux sacs à commande pour emporter Tim Hortons faits de papier recyclé à 100 % posés sur une table en bois',
          },
          {
            date: 'déc. 2019',
            eventKey: 'Dec 2019',
            feature: 'Bâtonnets en bois',
            copy: 'Lancement au Canada et aux États-Unis de bâtonnets compostables qui ont permis d’éliminer 75 millions de bâtonnets de plastique en 2021.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Dec2019_Wooden-Stir-Sticks.jpg',
            alt: 'Une poignée de bâtonnets en bois posés sur une table en bois',
          },
        ],
        2020: [
          {
            date: 'juin 2020',
            eventKey: 'June 2020',
            feature: 'Gobelets réutilisables tout-aller',
            copy: [
              'Dans le cadre de notre programme de gobelets réutilisables, nous avons lancé les gobelets réutilisables tout-aller, qui sont maintenant offerts aux restaurants participants au Canada.',
              'Certains gobelets ont des lignes de remplissage, et nous disposons également d’un outil de mesure spécialement conçu pour que les membres d’équipe puissent préparer facilement les boissons avec les bonnes quantités. De cette façon, les invités peuvent apporter n’importe quel gobelet en restaurant et déguster la même excellente boisson que celle qu’ils reçoivent lorsqu’ils utilisent des gobelets à usage unique.',
            ],
            imgSrc: 'https://cdn.rbi.digital/t4g/Reusable_Cups.jpeg',
            alt: 'Une collection de 8 gobelets réutilisables Tim Hortons différents, alignés sur une table',
          },
          {
            date: 'oct. 2020',
            eventKey: 'Oct 2020',
            feature: 'Gobelet à 30% de matières recyclées (pilote)',
            copy: 'Nous avons élargi les essais, à Calgary et à Toronto, pour la mise au point d’un gobelet pour boisson chaude Tim Hortons fait de matières recyclées à 30%.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Oct2020_Recycled-Material-Cup.jpg',
            alt: 'Un couvercle à boisson chaude Tim Hortons fait de matériaux recyclés à 30 %',
          },
          {
            date: 'nov. 2020',
            eventKey: 'Nov 2020',
            feature: 'Revêtement de gobelet compostable (pilote)',
            copy: [
              'Tim Hortons a lancé un projet pilote dans certains restaurants de Vancouver dans le but d’introduire un gobelet de carton plus facile à recycler et compostable.',
              'Nous continuons de travailler avec les principaux intervenants de l’industrie et des gouvernements pour nous assurer que le gobelet soit conforme aux réglementations municipales afin de pouvoir l’offrir à plus large échelle au Canada.',
            ],
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Nov2020_Compostable-Cup-Liner.jpg',
            alt: 'Une main tient un gobelet à boisson chaude rouge Tim Hortons, fabriqué avec un revêtement compostable',
          },
          {
            date: 'nov. 2020',
            eventKey: 'Nov 2020',
            feature: 'Fini, le doublage des gobelets!',
            copy: 'Aux restaurants du Canada, nous ne doublons plus les gobelets, mais offrons un manchon réutilisable! Nous pouvons ainsi éviter le gaspillage de 200 millions de gobelets par année*.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Nov2020_No-More-Double-Cupping.jpg',
            alt: 'Un couvercle à boisson chaude et un manchon Tim Hortons sur une table en bois',
          },
        ],
        2021: [
          {
            date: 'janv. 2021',
            eventKey: 'Jan 2021',
            feature: 'Serviettes à 100% de fibres recyclées',
            copy: 'Nouvelles serviettes de papier faites à 100% de fibres recyclées, dont 90% de matières recyclées après consommation, et 25% moins de matières utilisées comparativement aux anciennes serviettes. Ce changement apporté dans les restaurants du Canada et des États-Unis a permis d’économiser 1 210 tonnes de papier en 2021.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Jan2021_Recycled-Fiber-Napkins.jpg',
            alt: 'Une pile de serviettes de papier brunes faites de fibres recyclées à 100 %.',
          },
          {
            date: 'janv. 2021',
            eventKey: 'Jan 2021',
            feature: 'Sacs à sandwich recyclables',
            copy: 'Tim Hortons a également lancé, pour les sandwichs et les bagels, de nouveaux sacs en papier entièrement recyclables, ce qui a permis de réduire de 17% l’utilisation annuelle de papier. Ces nouveaux emballages ont permis d’économiser plus de 379 tonnes de plastique en 2021.​',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Jan2021_Recyclable-Sandwich-Packaging.jpg',
            alt: 'Deux emballages de sandwichs recyclables à base de papier pour les sandwichs et les bagels Tim Hortons posés sur une table',
          },
          {
            date: 'avril 2021',
            eventKey: 'Apr 2021',
            feature: 'Fini, les pailles de plastique!',
            copy: [
              'Nos restaurants du Canada ont graduellement cessé d’utiliser des pailles de plastique, ce qui nous a permis d’éviter l’utilisation de plus de 319 millions de pailles de plastique en 2021.',
              'Cette mesure exclut les pailles adaptables, qui sont toujours offertes dans les restaurants à la demande des invités. Elles sont offertes à des fins d’accessibilité.',
            ],
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Apr2021_Removing-Plastic-Straws.jpg',
            alt: 'Des pailles en papier blanc alignées sur une table en bois',
          },
          {
            date: 'oct. 2021',
            eventKey: 'Oct 2021',
            feature: 'Recyclage assisté par IA (pilote)',
            copy: 'En partenariat avec Intuitive AI, une entreprise basée à Vancouver, Tim Hortons a mis à l’essai une technologie assistée par intelligence artificielle (IA) dans certains restaurants du Canada, afin de renseigner ses invités sur le recyclage et le compostage, et d’optimiser les taux de recyclage et de réacheminement.',
            imgSrc: 'https://cdn.rbi.digital/t4g/AI_Recycling.jpg',
            alt: 'Une femme devant un écran généré par une IA qui lui indique la méthode d’élimination appropriée pour la bouteille en plastique de Tim Hortons qu’elle tient',
          },
          {
            date: 'nov. 2021',
            eventKey: 'Nov 2021',
            feature: 'Projet pilote de partenariat avec Loop',
            copy: 'Nouveau partenariat avec la plateforme Loop afin de tester un nouveau système de gobelets réutilisables et consignés pour les boissons à emporter.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Timeline_Oct2020_Loop-Partnership.jpg',
            alt: 'Un gobelet Tim Hortons et une boîte de beignes consignés et réutilisables posés sur une table à l’extérieur',
          },
        ],
        2022: [
          {
            date: 'fév. 2022',
            eventKey: 'Fev 2022',
            feature: 'Lancement du couvercle blanc',
            copy: 'Nous avons lancé un nouveau couvercle blanc pour gobelet à boissons chaudes dans certains restaurants au Canada. Après avoir consulté l’industrie quant au futur du recyclage au Canada, nous avons conclu que les matériaux recyclables blancs ont plus de chances d’être réutilisés et transformés en de nouveaux produits. Encore davantage de nos emballages pourront donc être recyclés grâce à cette initiative.',
            imgSrc: 'https://cdn.rbi.digital/t4g/New_White_Lids.jpeg',
            alt: 'Un gobelet à boisson chaude Tim Hortons rouge avec un couvercle blanc sur une table en bois',
          },
          {
            date: 'avril 2022',
            eventKey: 'Apr 2022',
            feature: 'Gobelet recyclable et compostable (pilote)',
            copy: 'En partenariat avec WestRock, Tim Hortons se prépare à tester un nouveau gobelet à boissons chaudes novateur en avril 2022 dans certains restaurants de Vancouver. En plus d’être compostable et recyclable, ce nouveau gobelet contient jusqu’à 20% de matières recyclées post-consommation. Sous réserve des exigences municipales locales en matière de produits recyclables et compostables, là où les installations existent.',
            imgSrc:
              'https://cdn.rbi.digital/t4g/Recyclable_and_Compostable_Cup.jpeg',
            alt: 'Un gobelet à boisson chaude Tim Hortons rouge fait de matériaux recyclables et compostables',
          },
          {
            date: 'T4 de 2022',
            eventKey: 'T4 de 2022',
            feature: 'Couvercle pour boissons chaudes sans plastique',
            copy: 'Des tests sont prévus dans certains restaurants du Canada au T4 de 2022 pour des couvercles recyclables et compostables sans plastique.',
            imgSrc: 'https://cdn.rbi.digital/t4g/Fibre_Lid.jpg',
            alt: 'Un gobelet à boisson chaude Tim Hortons rouge avec un couvercle beige en fibre sans plastique, recyclable et compostable',
          },
          {
            date: '2022',
            eventKey: '2022',
            feature: 'Lancement du programme d’emprunt de gobelet',
            copy: 'En partenariat avec Return-It, nous avons lancé un projet pilote d’emprunt de gobelets réutilisables dans 10 restaurants de Vancouver, en Colombie-Britannique. Les invités peuvent emprunter un gobelet réutilisable Tim Hortons moyennant un dépôt de 4 $, qu’ils récupèrent en le rapportant au restaurant. Les retours sont acceptés à ces 10 restaurants, et aux bacs de retour des espaces publics et à d’autres lieux de collecte de Sharewares à Vancouver.',
            imgSrc: 'https://cdn.rbi.digital/t4g/Borrow_A_Cup.jpg',
            alt: 'Un gobelet Tim Hortons réutilisable et un bac de retour des gobelets',
          },
          {
            date: 'nov. 2022',
            eventKey: 'Nov. 2022',
            feature: 'Couvercles 25% de polypropylène recyclé postconsommation',
            copy: 'Afin de contribuer à l’économie circulaire, nous avons testé un couvercle pour boissons chaudes contenant 25% de polypropylène recyclé postconsommation, le premier du genre au pays. Ce couvercle est recyclable et fabriqué en partie avec des matériaux recyclés.',
            imgSrc: 'https://cdn.rbi.digital/t4g/PRC_Lids.jpg',
            alt: 'Un couvercle à boisson chaude Tim Hortons fait à 25 % de matériaux recyclés post-consommation',
          },
          {
            date: '2022-2023',
            eventKey: '2022-2023',
            feature: 'Emballage des wraps dans le papier alimentaire',
            copy: 'En 2022, nous avons commencé la transition de la boîte à wrap rouge au nouveau papier alimentaire pour l’emballage de tous nos wraps. Le papier alimentaire diminue l’utilisation de matériel de 75% en comparaison aux boîtes, ce qui représente une économie annuelle de plus de 1 400 tonnes de matériel.',
            imgSrc: 'https://cdn.rbi.digital/t4g/Wrap_Sheet_Evolution.png',
            alt: 'Une boîte à wrap pour l’emballage des sandwichs à côté d’un papier alimentaire avec une flèche entre les deux pour symboliser une transition',
          },
        ],
      },
    },
    projects: {
      videoDesc:
        'Découvrez comment nous réalisons certains de ces projets passionnants et bien d’autres encore dans nos restaurants!',
    },
    people: {
      pplComm: 'les gens et les communautés',
      header: 'Redonner continuellement à nos communautés.',
      text: 'À l’ouverture du premier restaurant en 1964, l’objectif était de créer un endroit où les gens pourraient aller à n’importe quel moment et se sentir chez eux. Ce modeste rêve a pris beaucoup d’envergure depuis, et aujourd’hui, nous nous faisons un devoir d’être de bons voisins et de contribuer aux communautés locales canadiennes tout au long de l’année.',
      cards: [
        {
          title: 'Les Camps de la Fondation Tim Hortons ',
          eventKey: 'Tim Hortons Foundation Camps',
          desc: 'Les Camps de la Fondation Tim Hortons sont un organisme à but non lucratif qui, grâce à un programme de camps s’échelonnant sur plusieurs années, aident des jeunes à acquérir des compétences, telles que la résilience, le leadership et le sens des responsabilités. Lors du Jour des camps ayant lieu chaque année, les recettes de la vente de café et le produit net de la vente de bracelets sont versés en totalité aux Camps Tim Hortons.',
          url: 'https://campstimhortons.com/',
          imgSrc: 'https://cdn.rbi.digital/t4g/People_Tims_Camps.jpg',
          alt: 'Un groupe de jeunes campeurs des Camps de la Fondation Tim Hortons joyeusement assis ensemble lors d’une belle journée à l’extérieur du camp',
        },
        {
          title: 'Le Biscuit Sourire',
          eventKey: 'Smile Cookie',
          desc: 'Chaque année, pendant une semaine, 100% des recettes de la vente de nos biscuits aux brisures de chocolat, sur lesquels nous dessinons un sourire, sont versées à des organismes caritatifs locaux choisis par les restaurants Tim Hortons. L’année dernière, nous avons recueilli près plus de 15 million de dollars pour les organismes caritatifs, hôpitaux et groupes communautaires locaux dans tout le Canada.',
          url: 'https://www.timhortons.ca/biscuit-sourire',
          imgSrc: 'https://cdn.rbi.digital/t4g/People_Smile_Cookie.jpg',
          alt: 'Un jeune garçon sourit en tenant un biscuit sourire de Tim Hortons face à l’appareil photo',
        },
        {
          title: 'Hockey et Soccer Timbits',
          eventKey: 'Timbits Hockey & Soccer',
          desc: 'Les propriétaires de restaurant Tim Hortons offrent annuellement leur appui à plus de 300 000 jeunes de 4 à 8 ans en éliminant les obstacles financiers et en leur donnant accès à des programmes de sport organisé de niveau débutant qui mettent l’accent sur le plaisir de pratiquer un nouveau sport et de se faire de nouveaux amis.',
          url: 'https://www.timhortons.ca/sports-timbits',
          imgSrc:
            'https://cdn.rbi.digital/t4g/People_Timbits Hockey & Soccer.jpg',
          alt: 'Une jeune fille au chandail bleu frappe le ballon lors d’un match de soccer passionnant entre deux équipes de jeunes joueurs de Soccer Timbits',
        },
        {
          title: 'Camionnettes de café et dons',
          eventKey: 'Coffee Trucks & Donations',
          desc: 'Chez Tim Hortons, nous connaissons la force du travail d’équipe. Nos propriétaires de restaurant et nos équipes de camionnettes de dons sont heureux de livrer gratuitement du café et des pâtisseries aux personnes qui servent nos communautés en guise de remerciement et de reconnaissance. Depuis 2020, nous avons fièrement fait don de plus de 2 million de cafés à travers le Canada.',
          url: 'https://www.timhortons.ca/dons-tim-du-bien-au-quotidien',
          imgSrc:
            'https://cdn.rbi.digital/t4g/People_Tims_for_Good_Donation-Platform.jpg',
          alt: 'Une camionnette de café Tim Hortons est stationnée devant un lac et un magnifique coucher de soleil',
        },
        {
          title: 'Beigne Olympiques spéciaux',
          eventKey: 'Special Olympics Donut',
          desc: 'Chaque année, notre campagne du  Beigne Olympiques spéciaux vise à recueillir des fonds pour accroître la qualité, la portée et l’accessibilité des programmes sportifs communautaires quotidiens d’Olympiques spéciaux pour les Canadiens présentant une déficience intellectuelle. La totalité des profits va à Olympiques spéciaux Canada',
          url: 'https://www.timhortons.ca/olympiquesspeciaux',
          imgSrc: 'https://cdn.rbi.digital/t4g/Special_Olympics_Donut_FR.jpg',
          alt: 'Un jeune athlète des Olympiques spéciaux sourit à la caméra tout en tenant un beigne Olympiques spéciaux de Tim Hortons : un beigne au chocolat garni de vermicelles et de crème fouettée',
        },
        {
          title: 'Beigne aux vermicelles orange',
          eventKey: 'Orange Donut',
          desc: 'Chaque année, lorsque nos invités achètent un beigne aux vermicelles orange à l’occasion de la Journée nationale de la vérité et de la réconciliation, la totalité des profits est versée à la Société du chandail orange, à la Société des survivants des pensionnats indiens et à la Fondation Nouveaux Sentiers (Québec seulement).',
          url: 'https://www.timhortons.ca/le-beigne-aux-vermicelles-orange',
          imgSrc: 'https://cdn.rbi.digital/t4g/Orange_Donut.png',
          alt: 'Un propriétaire de restaurant Tim Hortons se tient devant son établissement de Kamloops, en Colombie Britannique, avec une boîte de six beignes aux vermicelles orange de Tim Hortons',
        },
      ],
    },
    community: {
      preheader:
        'Notre engagement envers les communautés s’étend aux régions où nous nous approvisionnons en grains de café.',
      standaloneVideoId: 'oLGG991c9Rg',
      standaloneVideoAlt: 'Partenariat de café Tim',
      proudlySupporting:
        'Chaque café Tim que nous servons provient d’une communauté de producteurs de café. En collaboration avec nos partenaires locaux, nous visons à contribuer aux entreprises et à l’avenir de ces producteurs.',
      enveritasHeader: 'Enveritas',
      enveritasSubHeader:
        'Nous sommes associés à Enveritas, qui vérifie 100 % de nos achats de grains de café chaque année selon divers indicateurs sociaux, économiques et environnementaux.',
      enveritas:
        'Notre partenariat de café Tim Hortons est basé sur le modèle unique d’Enveritas, qui comprend des moyens innovateurs et précis servant à mesurer la durabilité des pratiques agricoles des grains de café que nous nous procurons. Nous mobilisons chaque fournisseur et utilisons des données précises pour comprendre les réalités des agriculteurs dans notre chaîne d’approvisionnement. Cela nous permet de concevoir des programmes axés sur notre engagement à améliorer les moyens de subsistance des agriculteurs dans toutes nos communautés productrices de café, tout en contribuant à la durabilité et à la qualité globale de la production de café.',
      enveritasAlt: 'Deux cultivateurs de café, entourés de plants de café luxuriants sur leur ferme, se sourient tout en discutant',
      farmerCenteredHeader: 'Une approche axée sur les agriculteurs',
      farmerCenteredSubHeader:
        'En collaboration avec nos partenaires, nous travaillons avec les producteurs de café afin de leur fournir les compétences techniques et commerciales nécessaires pour développer leur plein potentiel. Pour ce faire :',
      farmers: [
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/farmer_1.png',
          caption: 'Nous outillons les femmes pour qu’elles soient des leaders',
          alt: 'Une jeune productrice de café, vêtue d’un haut rose, pose fièrement pour une photo devant sa ferme de café',
        },
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/farmer_2.png',
          caption: 'Nous améliorons les pratiques agricoles',
          alt: 'Une agricultrice récolte les grains des plants de café et place les produits dans un panier',
        },
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/farmer_3.png',
          caption: 'Nous formons les agriculteurs de la relève',
          alt: 'Deux cultivateurs de café inspectent un plant de café et discutent du produit',
        },
      ],
      learnMoreHeader: 'Découvrez-en plus en visionnant les vidéos ci-dessous.',
      learnMoreVideos: [
        {
          embedId: 'AlQ5C_lsN1w',
          title: 'Outiller les femmes pour en faire des leaders',
        },
        {
          embedId: 'EUb-5-3GY5o',
          title: 'Améliorer les pratiques agricoles',
        },
        {
          embedId: 'jwK17Q2rgRs',
          title: 'Former les agriculteurs de la relève',
        },
      ],
      monitoringHeader: 'Contrôle et évaluation',
      monitoringPoints: [
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/leaf_icon_green.png',
          imgAlt: 'leaf icon',
          caption:
            'Une approche méthodique est utilisée pour mesurer l’évolution du programme.',
        },
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/project_icon_green.png',
          imgAlt: 'project icon',
          caption: '14 projets dans 13 régions de nos pays producteurs de café.',
        },
        {
          imgSrc: 'https://cdn.rbi.digital/t4g/farmer_icon_green.png',
          imgAlt: 'farmer icon',
          caption: 'Soutien direct à plus de 18 000 agriculteurs en 2023.',
        },
      ],
      susHeader:
        'La durabilité est un engagement à long terme. Nous visons à renforcer les communautés productrices de café en adoptant des pratiques qui leur sont plus profitables et favorisent leur prospérité.',
      cfcTitle: 'Le programme de partenariat de café Tim',
      cfcText:
        'L’objectif est de soutenir les producteurs de café afin d’améliorer leur qualité de vie grâce à des formations en partenariat avec des exportateurs de café locaux ainsi que des organisations à but non lucratif et de producteurs.',
      cfcLogoUrl:
        'https://cdn.rbi.digital/t4g/logo_Coffee-for-Communities_fr.png',
      cfcLogoAlt: 'Programme un café pour nos Communautés de Tim Hortons',
      cfcVideo: {
        buttonTitle: 'Afficher la transcription',
        transcript: [
          'Tim. Du bien au quotidien',
          'Chez Tim Hortons, on croit en l’avenir de nos producteurs de café et en leurs entreprises.C’est pourquoi on les aide à s’outiller grâce à des formations avec nos partenaires pour améliorer leur qualité de vie.',
          'Tim Hortons travaille en partenariat avec des exportateurs de café locaux, des organismes à but non lucratif et des regroupements de producteurs.',
          'Yelstin (en espagnol):  Ils ont pris le temps et se sont engagés /à venir m’enseigner de nouvelles compétences /pour que je puisse innover dans mes pratiques agricoles.',
          'Ce projet a soutenu Yeltsin en lui offrant une formation utilisant l’approche du café et du climat qui apprend aux agriculteurs à évaluer et à réagir aux différents impacts du climat sur leurs exploitations de café. Il lui a également fourni des semences pour cultiver des plantes et des variétés de café plus tolérantes aux effets du changement climatique.',
          'Le programme de partenariat de café Tim',
          'Et, ça, c’est le programme de partenariat de café Tim Hortons. Parce qu’il y a des producteurs agricoles derrière chaque tasse de café Tim.',
          'Pour en savoir plus, visitez timhortons.ca',
          'Pour en savoir plus, visitez / timhortons.ca/timdubienauquotidien',
          'Tim. Du bien au quotidien Petits changements, grand impact.',
          '© Tim Hortons, 2023.',
        ],
      },
      tabs: [
        {
          title: 'Le programme',
          text: 'Depuis 2005, Tim Hortons a collaboré avec des familles de petits producteurs de café qui avaient le plus besoin de son soutien. Notre partenariat repose sur une approche pratique et collaborative à long terme qui permet aux producteurs d’acquérir les connaissances et les compétences afin de participer activement à la chaîne d’approvisionnement du café et d’avoir un effet positif sur les communautés productrices de café.',
          imgURL: 'https://cdn.rbi.digital/t4g/img_people_the-program.png',
          eventKey: 'Community Program',
          isActive: true,
        },
        {
          title: 'Partenariat avec Enveritas',
          text: 'Nous sommes fiers de nous associer à Enveritas, qui vérifie chaque année 100% de nos achats selon un ensemble de normes sociales, économiques et environnementales. Enveritas a développé un modèle qui permet de mesurer la durabilité au niveau de la plantation. Nous faisons participer tous nos fournisseurs et utilisons la technologie et les données pour comprendre les réalités des producteurs au sein de notre chaîne d’approvisionnement. Notre partenariat nous permet d’approfondir et de soutenir l’amélioration continue dans chacun des trois grands domaines, tout en nous concentrant sur la réalisation de grands objectifs de développement durable avec nos partenaires.',
          imgURL:
            'https://cdn.rbi.digital/t4g/img_people_enveritas-partnership.png',
          eventKey: 'Community Enveritas Partnership',
          isActive: false,
        },
        {
          title: 'Nos objectifs',
          text: 'Notre travail vise six objectifs : promouvoir l’inclusion des genres, promouvoir un avenir économique plus viable pour les jeunes adultes, améliorer la qualité du café, développer un mode de production mieux adapté aux climats, créer et renforcer des organisations de producteurs, et établir des partenariats au moyen d’initiatives de collaboration.',
          imgURL: 'https://cdn.rbi.digital/t4g/img_people_objectives.png',
          eventKey: 'Community Our Objectives',
          isActive: false,
        },
        {
          title: 'La mise en œuvre',
          text: 'Nous voulons favoriser l’autonomie par le biais de l’information, car les solutions les plus innovantes viennent souvent des producteurs. Nous encourageons l’adoption de nouvelles technologies agricoles et de solutions écologiques, de même que l’innovation technologique pour améliorer les pratiques agricoles. Nous aidons également les producteurs de café à adopter des pratiques agricoles intelligentes sur le plan climatique, qui sont essentielles pour assurer la durabilité des entreprises et des moyens de subsistance. \n\nJusqu’à présent, nous avons mis en œuvre des projets au Guatemala, au Honduras, en Colombie, au Brésil et en Tanzanie, et nous avons aidé près de 30 000 producteurs de café et leurs familles.',
          imgURL: 'https://cdn.rbi.digital/t4g/img_people_implementation.png',
          eventKey: 'Community Implementation',
          isActive: false,
        },
      ],
    },
    quotes: {
      quote1:
        'Je suis fier de m’appuyer sur l’héritage de ma famille en matière de café et de partager l’histoire de ma famille pour encourager les jeunes à s’impliquer dans la chaîne d’approvisionnement du café.',
      quote1Source: '- Kevin Leveron (Producteur de café, Honduras)',
      quote1Credit: 'Photo par Hanns R. Neumann Stiftung',
      quote2:
        'Dans le cadre de la formation sur le climat que j’ai reçue, j’ai également organisé ma première démonstration portant sur de nouvelles variétés, les cultures de couverture et l’éloignement des cultures.',
      quote2Source:
        '- Jessie Karen España Galdámez (Productrice de café, Honduras)',
      quote2Credit: 'Photo par Hanns R. Neumann Stiftung',
    },
    tv: {
      title:
        'Jetez un coup d’œil à notre publicité télévisée « Tim. Du bien au quotidien. »!',
      buttonTitle: 'Afficher la transcription',
      transcript: [
        '(On voit l’extérieur d’un restaurant Tim Hortons)',
        '(On voit des chevreuils à l’extérieur d’un restaurant Tim Hortons)',
        'Isabelle : Partout au pays, on lance Tim. Du bien au quotidien.',
        '(On voit la propriétaire de restaurant Isabelle Fortin à l’écran)',
        '(On voit le propriétaire de restaurant Nicolas Fortin à l’écran)',
        'Nicolas : Pour améliorer nos aliments, faire du bien à notre planète...',
        '(On voit ce dernier remettre la commande à une invitée)',
        '(On voit un phare)',
        '(On voit la famille Locke en train de marcher, main dans la main)',
        'Isabelle : … ainsi qu’à notre communauté.',
        '(Image d’un œuf qu’on casse)',
        'Nicolas : On s’engage à rendre la qualité de nos aliments et de nos boissons encore meilleure.',
        '(L’écran se divise pour montrer un œuf fraîchement cassé à l’état cru et cuit)',
        '(Un invité prend un Timatin préparé avec un œuf fraîchement cassé)',
        '(L’écran se divise en six pour montrer la préparation d’un Timatin préparé avec un œuf fraîchement cassé sur un bagel)',
        '(On voit des pailles de carton)',
        'Isabelle : On s’engage à faire disparaître un milliard de produits de plastique à usage unique, partout au pays, d’ici la fin de l’année.',
        '(L’écran se divise en trois et on voit des pailles de carton, un gobelet réutilisable et des bâtonnets en bois)',
        '(L’écran se divise en six et on voit un invité mélangeant sa boisson, un invité savourant une boisson froide, des pailles de carton, des bâtonnets en bois, deux jeunes à l’arrière d’une voiture, ainsi qu’une boisson froide avec une paille de carton et un Timatin préparé avec un œuf fraîchement cassé)',
        '(On voit du café être versé dans un gobelet réutilisable)',
        '(L’écran se divise en trois, avec des images de café en gros plan sur les côtés et du café qui est versé dans un gobelet réutilisable au centre)',
        'Nicolas : Et on sert du café qui vient à 100% de sources éthiques.',
        '(L’écran se divise en neuf, montrant des plants, des grains et des sacs de café, du café versé dans un gobelet réutilisable, des images de café en gros plan et un producteur de café)',
        '(L’écran se divise en trois, montrant un œuf fraîchement cassé, des pailles de carton et du café versé dans un gobelet réutilisable)',
        'Isabelle : Ensemble, on peut avoir un impact important sur notre planète.',
        '(L’écran se divise en neuf, montrant des œufs, des bâtonnets en bois, des grains et des plantations de café, des couvercles pour les gobelets à boisson froide et une image de café en gros plan)',
        'Nicolas : Tim. Du bien au quotidien.',
        '(L’écran se transforme en mosaïque montrant toutes les images aperçues dans la vidéo)',
        '(La mosaïque s’estompe et on voit le logo « Tim. Du bien au quotidien. », avec l’adresse timhortons.ca/timdubienauquotidien sur une serviette de table en papier recyclé en arrière-fond)',
      ],
    },
    mapNav: {
      title:
        'Cliquez sur un pays pour en apprendre davantage sur sa communauté.',
      guatemala: 'Guatemala',
      colombia: 'Colombie',
      brazil: 'Brésil',
      indonesia: 'Indonésie',
      backButton: 'Retour à la page de carte initiale',
      guatemalaButton: 'Retour à la liste des agriculteurs du Guatemala',
      colombiaButton: 'Retour à la liste des agriculteurs du Colombie',
      brazilButton: 'Retour à la liste des agriculteurs du Brésil',
      indonesiaButton: 'Retour à la liste des agriculteurs du Indonésie',
    },
  },
};

const t = (section) => (key) => {
  const { lang } = useContext(Tims4GoodContext);
  const textObj = strings[lang][section][key];
  return textObj;
};

export { t };
