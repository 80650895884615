import styled from '@emotion/styled';
import { GeneralStyles as theme } from '../../components/styles/generalStyles';

export const MapContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${theme.colors.brown2};
  ${theme.mq.untilMega} {
    flex-direction: column;
  }
`;

export const StyledPill = styled.button`
  color: ${({ focused }) =>
    focused ? theme.colors.white : theme.colors.brown2};
  background-color: ${({ focused }) =>
    focused ? theme.colors.brown2 : theme.colors.white};
  font-weight: 800;
  font-size: ${theme.fontSize.atom};
  border: none;
  border-radius: 20px;
  padding: 5px 14px;
  margin-right: 10px;
  ${theme.mq.untilByte} {
    font-size: 15px;
    margin-top: ${theme.spacings.byte};
  }
`;

export const StyledChevronWrapper = styled.div`
  display: none;
  background-color: ${theme.colors.white};
  color: ${theme.colors.brown2};
  border-radius: ${theme.borderRadius.circle};
  width: 38px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 38px;
  margin-top: ${theme.spacings.byte};
  &:focus {
    border-color: ${theme.colors.brown2};
  }
  ${theme.mq.megaToTera} {
    display: flex;
    margin-top: 0;
  }
  ${theme.mq.untilByte} {
    display: flex;
  }
`;

export const PillsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: ${theme.spacings.kilo};
  bottom: ${theme.spacings.kilo};
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;

  ${theme.mq.untilKilo} {
    flex-wrap: nowrap;
  }
`;

export const StyledMapContainer = styled.div`
  position: relative;
  background-image: url('${process.env.PUBLIC_URL}/images/map_background.png');
  background-size: cover;
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 100vh;
  ${theme.mq.untilMega} {
    height: 800px;
  }
  ${theme.mq.untilKilo} {
    height: 487px;
  }
`;

export const MapColumn = styled.div`
  flex: ${({ ratio }) => `0 0 ${ratio}%`};
`;
