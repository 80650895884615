import React from 'react';
import parse from 'html-react-parser';
import PlanetTimeLineTabs from './planet-timeline-tabs/PlanetTimeLineTabs';
import TimSection from '../../components/layout/TimsSection';
import { t } from '../../translations';
import Leaf from '../../components/branding/Leaf';
import StyledH2 from '../../components/structure/StyledH2';
import StyledH3 from '../../components/structure/StyledH3';
import { StyledTextContainer } from './PlanetTimeline.styles';
import StyledP from '../../components/structure/StyledP';
import { mobileSubheaderStyle } from '../../components/styles/generalStyles';

export const PlanetTimeline = () => {
  const bodyCopy = t('planet')('text');
  return (
    <TimSection
      id="planeTimelineScene"
      backgroundImage="https://cdn.rbi.digital/t4g/timeline_background.jpg"
    >
      <PlanetTimeLineTabs>
        <StyledTextContainer>
          <Leaf variant="red" />
          <StyledH2 textColor="brown" highlightcolor="red">
            {t('planet')('ourCommitment')}
            {' '}
            <span>{t('planet')('planet')}</span>
          </StyledH2>
          <StyledH3 textColor="brown">
            {parse(t('planet')('subtitle'))}
          </StyledH3>
          { !!bodyCopy && bodyCopy.map((t, index) => (
            <StyledP key={`planet_${index}`} textColor="brown" style={mobileSubheaderStyle} fontWeight="regular">
              {t}
            </StyledP>
          ))}
        </StyledTextContainer>
      </PlanetTimeLineTabs>
    </TimSection>
  );
};
