import React, {
  useState, useEffect, useRef, createRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  PlanetLineButtonContainer,
  TabStyleUL,
  TabStyledList,
  Tab,
} from './PlanetTimeLineTabs.styles';

const Tabs = ({
  content, onClick, activeTab, ariaLabel,
}) => {
  const tabsRef = useRef(content.map(() => createRef()));

  const [focusTab, setFocusTab] = useState(0);
  const [isActive, setActive] = useState(false);

  const handleKeyDown = (index) => (e) => {
    setActive(true);
    if (e.keyCode == '37' && index !== 0) {
      setFocusTab(index - 1);
    } else if (e.keyCode == '39' && index !== content.length - 1) {
      setFocusTab(index + 1);
    } else if (e.keyCode == '13') {
      onClick(index);
    }
  };

  const onTabClick = (index) => () => {
    setActive(true);
    setFocusTab(index);
    onClick(index);
  };

  useEffect(() => {
    if (isActive) {
      tabsRef.current[focusTab].current.focus();
    }
  }, [focusTab, isActive]);

  return (
    <PlanetLineButtonContainer>
      <TabStyleUL role="tablist" aria-label={ariaLabel}>
        {content.map((tabItem, index) => (
          <TabStyledList key={index}>
            <Tab
              ref={tabsRef.current[index]}
              className={activeTab === index ? 'active' : ''}
              tabIndex={focusTab === index ? 0 : -1}
              onClick={onTabClick(index)}
              onKeyDown={handleKeyDown(index)}
              aria-selected={activeTab === index}
              aria-controls={tabItem.eventKey}
              role="tab"
            >
              {tabItem.title}
            </Tab>
          </TabStyledList>
        ))}
      </TabStyleUL>
    </PlanetLineButtonContainer>
  );
};

Tabs.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      eventKey: PropTypes.string.isRequired,
    }),
  ),
  activeTab: PropTypes.number,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export default Tabs;
