import { useTheme } from '@emotion/react';
import React from 'react';
import Cards from '../../components/layout/Cards';
import MainColumn from '../../components/layout/MainColumn';
import TimSection from '../../components/layout/TimsSection';
import { t } from '../../translations';
import { TitleContainer } from '../commitment-to-food/CommitmentToFood.styles';

export const CommunityTiles = () => {
  const theme = useTheme();

  return (
    <TimSection>
      <TitleContainer color={theme.colors.darkGreen}>
        <MainColumn>
          <Cards content={t('people')('cards')} />
        </MainColumn>
      </TitleContainer>
    </TimSection>
  );
};
