import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { colorToDisplay } from '../../utils';

const StyledH3 = ({
  largeRightPadding, textColor, variant, children, className,
}) => {
  const theme = useTheme();
  const rightPadding = largeRightPadding ? '200px' : 'unset';

  const displayStyles = variant === 'small'
    ? {
      fontSize: theme.fontSize.mega,
      lineHeight: theme.lineHeight.peta,
      letterSpacing: theme.letterSpacing.bit,
    }
    : {
      fontSize: theme.fontSize.peta,
      lineHeight: theme.lineHeight.peta,
      letterSpacing: theme.letterSpacing.bit,
    };

  const tColor = colorToDisplay(textColor);

  const StyledH3 = styled.h3`
    color: ${tColor};
    padding-right: ${rightPadding};
    margin: 1.5rem 0;
    &:first-child {
      margin-top: 0;
    }
    ${theme.mq.untilMega} {
      text-align: center;
      font-size: ${theme.fontSize.yotta};
      line-height: ${theme.lineHeight.peta};
    }
    ${{ ...displayStyles }}
  `;

  return <StyledH3 className={className}>{children}</StyledH3>;
};

StyledH3.propTypes = {
  textColor: PropTypes.string,
  variant: PropTypes.string,
  largeRightPadding: PropTypes.bool,
  className: PropTypes.string,
};

StyledH3.defaultProps = {
  textColor: 'white',
};

export default StyledH3;
