import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { colorToDisplay } from '../../utils/colorToDisplay';
import StyledChevron from '../branding/Chevron';

const StyledButton = ({
  variant,
  fixedWidth,
  textColor,
  children,
  onSelected,
  hasChevron,
  bgColor,
  className,
}) => {
  const theme = useTheme();
  const standardTextColor = colorToDisplay(textColor);
  const fontSize = variant === 'large' ? theme.fontSize.tera : theme.fontSize.peta;
  const lineHeight = variant === 'large' ? theme.lineHeight.zetta : theme.fontSize.peta;
  const letterSpacing = variant === 'large' ? theme.letterSpacing.atom : theme.letterSpacing.bit;

  const btnWidth = fixedWidth ? '275px' : '90%';

  const StyledButton = styled.button`
    border: none;
    cursor: pointer;
    color: ${standardTextColor};
    padding: ${theme.spacings.giga} 0;
    font-size: ${fontSize};
    line-height: ${lineHeight};
    letter-spacing: ${letterSpacing};
    width: ${btnWidth};
    background-color: ${bgColor};
    font-weight: ${theme.fontWeight.bold};

    &:focus {
      ${theme.focus.red};
    }

    ${theme.mq.untilMega} {
      font-size: ${theme.fontSize.peta};
      line-height: ${theme.lineHeight.byte};
    }
  `;
  return (
    <StyledButton className={className} onClick={onSelected}>
      {children}
      {hasChevron && (
        <StyledChevron fillColor={standardTextColor} size={variant} />
      )}
    </StyledButton>
  );
};

StyledButton.propTypes = {
  variant: PropTypes.string,
  fixedWidth: PropTypes.bool,
  titleSize: PropTypes.string,
  date: PropTypes.string.isRequired,
  feature: PropTypes.string,
  textColor: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  hasChevron: PropTypes.bool,
  bgColor: PropTypes.string,
  className: PropTypes.string,
};

StyledButton.defaultProps = {
  variant: 'large',
  fixedWidth: false,
  titleSize: 'large',
  textColor: 'red',
  date: 'some Date',
  // feature: 'exciting new feature',
  onSelected: () => {
    console.log('basic function for onSelect');
  },
  hasChevron: false,
  bgColor: 'transparent',
};

export default StyledButton;
