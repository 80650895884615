import React from 'react';
import { t } from '../../translations';
import Tabs from '../../components/ui/tabs/Tabs';
import { Container } from './FoodHighlights.styles';

export const FoodHighlights = () => (
  <Container>
    <Tabs content={t('foodBev')('tabs')} />
  </Container>
);
