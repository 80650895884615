import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ScrollReveal } from '../scroll-reveal/ScrollReveal';

const ImageTile = ({
  imgSource,
  children,
  alt,
  justifyContent,
  indicatesInteractive,
}) => {
  const theme = useTheme();

  const ImageTile = styled(ScrollReveal)`
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    .background {
      transition: transform 3000ms ease-out;
    }

    &:hover .background {
      transform: scale(1.1);
    }
  `;

  const Image = styled.img`
    width: 100%;
    height: auto;
    display: block;
  `;

  const GradientContainer = styled.div`
    flex-grow: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    ${justifyContent
    ? `justify-content: ${justifyContent};
      background: linear-gradient(to bottom,
      ${theme.colors.brown45Transparent} 55%,
      ${theme.colors.black90Overlay}
    );`
    : `background: linear-gradient(to top,
      ${theme.colors.brown45Transparent} 55%,
      ${theme.colors.black90Overlay}
    );`}

    .wrapper {
      display: flex;
      flex-grow: 1;
      height: 100%;
      flex-direction: column;
      align-items: center;
    }

    span {
      color: ${theme.colors.white};
      font-size: ${theme.fontSize.peta};
      line-height: 1em;
      letter-spacing: ${theme.letterSpacing.bit};
      margin: 3.5vw 5.5vw 4vw 4vw;
    }

    ${theme.mq.untilMega} {
      text-align: center;
      justify-content: center;

      span {
        font-size: 7vw;
        margin: 10vw;
      }
    }
  `;

  return (
    <ImageTile indicateInteractive={indicatesInteractive}>
      <Image className="background" src={imgSource} alt={alt} />
      <GradientContainer>
        <div className="wrapper reveal">{children}</div>
      </GradientContainer>
    </ImageTile>
  );
};

ImageTile.propTypes = {
  children: PropTypes.object.isRequired,
  imgSource: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ImageTile.defaultProps = {
  alt: '',
};

export default ImageTile;
