import React, { useState, useContext } from 'react';

import { GeneralStyles } from '../../components/styles/generalStyles';
import StyledChevron from '../../components/branding/Chevron';
import { Map } from './Map';
import { MapNavigation } from '../../components/map-navigation/MapNavigation';
import { useWindowSize } from '../../utils/windowSize';
import { t } from '../../translations';
import Tims4GoodContext from '../../context/timsForGoodContext';
import {
  MapContainer,
  StyledPill,
  StyledChevronWrapper,
  PillsWrapper,
  StyledMapContainer,
  MapColumn
} from './InteractiveCoffeeMap.styles';

const theme = { ...GeneralStyles };

export const InteractiveCoffeeMap = () => {
  const [countryClicked, setCountryClicked] = useState();
  const [chevronClicked, setChevronClicked] = useState('left');
  const { sendMessage } = useContext(Tims4GoodContext);
  const { width } = useWindowSize();

  const updateCountry = (newCountry) => {
    setCountryClicked(newCountry);
  };

  const pillEvent = (country) => ({
    type: 'MPARTICLE_LOG',
    evtSource: '[TH] T4G Map Country Clicked',
    mParticleEvtType: 8,
    evtMessage: { country }
  });

  const handleButton = (evt) => {
    if (evt.target.id === 'brazil') {
      setCountryClicked('brazil');
      setChevronClicked('left');
      sendMessage(pillEvent('Brazil'));
    }
    if (evt.target.id === 'colombia') {
      setCountryClicked('colombia');
      setChevronClicked('left');
      sendMessage(pillEvent('Colombia'));
    }
    if (evt.target.id === 'guatemala') {
      setCountryClicked('guatemala');
      setChevronClicked('left');
      sendMessage(pillEvent('Guatemala'));
    }
    if (evt.target.id === 'indonesia') {
      setCountryClicked('indonesia');
      setChevronClicked('right');
      sendMessage(pillEvent('Indonesia'));
    }
  };

  const handleChevronClick = (position) => {
    setChevronClicked(position);
  };

  const isVisible = (position) =>
    chevronClicked === position ? 'invisible-button' : 'visible-button';

  const betweenKiloAndMega =
    width >= theme.breakpoints.kilo && width <= theme.breakpoints.mega;
  const greaterThanGiga = width >= theme.breakpoints.giga;
  const showPill = betweenKiloAndMega || greaterThanGiga;
  const showLeftPills = showPill || chevronClicked === 'left';
  const showRightPills = showPill || chevronClicked === 'right';
  const showLeftChevron = !showPill && chevronClicked === 'right';
  const showRightChevron = !showPill && chevronClicked === 'left';

  return (
    <MapContainer>
      <MapColumn className='map side' ratio='53.7'>
        <StyledMapContainer id='styledmapcontainer'>
          <Map countryClicked={countryClicked} />
          <PillsWrapper>
            <li>
              <StyledChevronWrapper
                onClick={() => handleChevronClick('left')}
                id='more-countries-left'
                style={{
                  display: showLeftChevron ? 'flex' : 'none'
                }}
                className={isVisible('left')}
              >
                <StyledChevron
                  fillColor={theme.colors.brown2}
                  size='tiny'
                  point='left'
                />
              </StyledChevronWrapper>
            </li>
            <li>
              <StyledPill
                onClick={handleButton}
                id='brazil'
                style={{
                  display: showLeftPills ? 'block' : 'none'
                }}
                focused={countryClicked === 'brazil'}
                className={isVisible('right')}
              >
                {t('mapNav')('brazil')}
              </StyledPill>
            </li>
            <li>
              <StyledPill
                onClick={handleButton}
                id='colombia'
                style={{
                  display: showLeftPills ? 'block' : 'none'
                }}
                focused={countryClicked === 'colombia'}
                className={isVisible('right')}
              >
                {t('mapNav')('colombia')}
              </StyledPill>
            </li>
            <li>
              <StyledPill
                onClick={handleButton}
                id='guatemala'
                style={{
                  display: showLeftPills ? 'block' : 'none'
                }}
                focused={countryClicked === 'guatemala'}
                className={isVisible('right')}
              >
                {t('mapNav')('guatemala')}
              </StyledPill>
            </li>
            <li>
              <StyledPill
                onClick={handleButton}
                id='indonesia'
                style={{
                  display: showRightPills ? 'block' : 'none'
                }}
                focused={countryClicked === 'indonesia'}
                className={isVisible('left')}
              >
                {t('mapNav')('indonesia')}
              </StyledPill>
            </li>
            <li>
              <StyledChevronWrapper
                onClick={() => handleChevronClick('right')}
                style={{
                  marginRight: theme.spacings.kilo,
                  display: showRightChevron ? 'flex' : 'none'
                }}
                id='more-countries-right'
                className={isVisible('right')}
              >
                <StyledChevron fillColor={theme.colors.brown2} size='tiny' />
              </StyledChevronWrapper>
            </li>
          </PillsWrapper>
        </StyledMapContainer>
      </MapColumn>
      <MapColumn ratio='46.3'>
        <MapNavigation country={countryClicked} updateCountry={updateCountry} />
      </MapColumn>
    </MapContainer>
  );
};
