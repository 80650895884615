import React from 'react';
import PropTypes from 'prop-types';

export const Guatemala = ({
  onMouseOver,
  onMouseOut,
  onClick,
  isHoveredOrFocused,
}) => (
  <g
    id="Guatemala V2"
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onClick={onClick}
  >
    <path
      id="path80"
      d="M271.841 235.782L270.276 234.72H268.897H268.605L268.461 234.466L267.942 233.546L267.115 233.602L266.964 233.612L266.833 233.537L266.325 233.247L264.903 233.181L264.419 233.158L264.426 232.674L264.712 213.326L249.319 213.366L243.96 213.55L243.634 213.824L245.434 214.749L245.488 214.777L245.533 214.816L246.527 215.678L248.222 217.042L248.288 217.095L248.332 217.167L249.071 218.35L250.202 218.727L250.234 218.738L250.264 218.753L253.768 220.474L253.833 220.506L253.886 220.555L254.92 221.505L255.004 221.582L255.046 221.687L255.509 222.843L256.082 224.109L259.014 225.109L259.393 225.238L259.35 225.636L259.216 226.87L259.213 226.898L259.207 226.926L258.866 228.445L259.204 229.765L259.347 230.325L258.772 230.386L252.533 231.054L252.531 231.054L240.45 232.298L234.302 244.429L234.136 244.95L234.935 246.072L235.763 247.012L235.763 247.012L235.77 247.02L236.39 247.753L236.786 248.221L236.248 248.515L235.974 248.664L235.412 249.106L235.291 249.432L235.478 250.184L235.529 250.388L235.419 250.567L235.042 251.18L235.042 251.622L235.4 252.541L236.241 253.086L236.305 253.127L236.354 253.186L237.731 254.846L243.129 256.92L246.309 256.994L248.809 256.695L248.924 256.681L249.033 256.719L252.757 258.015L253.239 257.252L253.307 256.301L253.32 256.126L253.439 255.997L255.435 253.84L255.538 253.728L255.686 253.693L258.282 253.078L259.756 252.651V251.878V251.626L259.958 251.476L262.772 249.396L263.946 246.845L263.459 245.177L263.378 244.9L263.576 244.692L265.263 242.921L265.263 242.921L265.268 242.916L268.035 240.085L268.191 239.925L268.415 239.935L269.876 240L272.59 238.399L273.222 236.716L273.275 236.576L273.397 236.487L274.659 235.572L274.831 235.151L274.376 234.777L272.974 234.608L272.584 235.558L272.35 236.128L271.841 235.782Z"
      fill={isHoveredOrFocused ? '#391D1E' : '#744215'}
      stroke="#C6A261"
    />
  </g>
);

Guatemala.propTypes = {
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onClick: PropTypes.func,
  isHoveredOrFocused: PropTypes.bool,
};

Guatemala.defaultProps = {
  onMouseOver: () => {},
  onMouseOut: () => {},
  onClick: () => {},
  isHoveredOrFocused: false,
};
