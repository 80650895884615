import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Tims4GoodContext from '../context/timsForGoodContext';
import { useMetrics } from '../context/metrics';

const ScrollTo = ({ elementId, children }) => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const { getCurrentTopOffSet } = useMetrics();

  const handlePressed = (e) => {
    e.preventDefault();
    const top = document.getElementById(elementId).offsetTop;
    window.scroll({ top: top - getCurrentTopOffSet() });
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Anchor Link Clicked',
      mParticleEvtType: 1,
      evtMessage: { url: elementId },
    });
  };

  return (
    <a onClick={handlePressed} href={`#${elementId}`}>
      {children}
    </a>
  );
};

ScrollTo.propTypes = {
  elementId: PropTypes.string.isRequired,
};

export default ScrollTo;
