/** @jsx jsx */
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/react';
import { Tween, PlayState } from 'react-gsap';
import { gsap } from 'gsap';
import {
  TabContainer,
  TabButtonContainer,
  StyleUL,
  StyledList,
  NormalBtn,
  TabContent,
  TabText,
  TabContentImg,
  Frame,
} from './Tabs.styles';

import Tims4GoodContext from '../../../context/timsForGoodContext';
import MainColumn from '../../layout/MainColumn';
import { ScrollReveal } from '../../scroll-reveal/ScrollReveal';
import browserSniffer from '../../../utils/browserSniffer';
import { useMetrics } from '../../../context/metrics';

const Tabs = ({ content }) => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const { onWindowResize } = useMetrics();
  const tabRefs = content.map(() => ({ ref: useRef() }));
  const frameRef = useRef();
  const getMinHeight = () => tabRefs[selectedTab].ref.current?.clientHeight;
  const setContentHeight = () => gsap.to(frameRef.current, { height: getMinHeight() });

  const handleTabPress = (tabIndex) => () => {
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Tab Clicked',
      mParticleEvtType: 8,
      evtMessage: {
        tabName: content[tabIndex].eventKey,
      },
    });

    setSelectedTab(tabIndex);
  };
  const isDesktopBrowser = useMemo(
    () =>
      // TODO: replace this with window size detection
      !browserSniffer(),
    [],
  );

  const onLinkClick = (e, url) => {
    e.preventDefault();
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Link Clicked',
      mParticleEvtType: 1,
      evtMessage: { url },
    });
    window.open(url, '_blank');
  };

  onWindowResize(setContentHeight);

  useEffect(() => {
    setTimeout(() => {
      setContentHeight();
    }, 2000);
  }, []);

  useEffect(() => {
    setContentHeight();
  }, [selectedTab]);

  return (
    <>
      <TabButtonContainer>
        <MainColumn>
          <StyleUL>
            {content.map((tabItem, index) => (
              <StyledList key={tabItem.title}>
                <NormalBtn
                  className={index === selectedTab ? 'active' : ''}
                  onClick={handleTabPress(index)}
                >
                  {tabItem.title}
                </NormalBtn>
              </StyledList>
            ))}
          </StyleUL>
        </MainColumn>
      </TabButtonContainer>
      <ScrollReveal
        triggerHook="onEnter"
        offsetTrigger={75}
        enabled={isDesktopBrowser}
      >
        <TabContainer>
          <Frame ref={frameRef}>
            {content.map((tab, index) => (
              <Tween
                key={tab.title}
                duration={selectedTab === index ? 1 : 0.2}
                playState={
                  selectedTab === index ? PlayState.play : PlayState.reverse
                }
                from={{ opacity: 0, x: isDesktopBrowser ? -30 : 0 }}
                to={{ opacity: 1, x: 0, zIndex: 1 }}
              >
                <TabContent ref={tabRefs[index].ref}>
                  <TabText className="reveal">
                    {Array.isArray(tab.text)
                      ? tab.text.map((item) => {
                        if (React.isValidElement(item) && item.type === 'a') {
                          return React.cloneElement(item, {
                            key: item,
                            onClick: (e) => onLinkClick(e, item.props.href),
                          });
                        }
                        return item;
                      })
                      : tab.text}
                  </TabText>
                  <TabContentImg
                    src={tab.imgURL}
                    alt={tab.alt}
                  />
                </TabContent>
              </Tween>
            ))}
          </Frame>
        </TabContainer>
      </ScrollReveal>
    </>
  );
};

Tabs.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        ).isRequired,
      ]),
      imgURL: PropTypes.string,
    }),
  ),
};

export default Tabs;
