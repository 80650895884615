import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const Column45 = ({ children, fullWidthUntil, topPadding }) => {
  const theme = useTheme();

  const Column45 = styled.div`
    width: 45%;
    height: 100%;
    ${theme.mq[`until${fullWidthUntil}`]} {
      ${topPadding && `padding-top: ${theme.spacings.zetta};`}
      width: 100%;
      text-align: center;
    }
    ${theme.mq.untilByte} {
      padding-top: 0;
    }
  `;
  return <Column45>{children}</Column45>;
};

Column45.propTypes = {
  fullWidthAt: PropTypes.string,
  topPadding: PropTypes.bool,
};

Column45.defaultProps = {
  fullWidthUntil: 'Mega',
  topPadding: false,
};

export default Column45;
