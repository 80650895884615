/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { jsx, useTheme } from '@emotion/react';

import { colorToDisplay } from '../../utils/colorToDisplay';

const StyledP = ({
  textColor,
  textAlign,
  children,
  padding,
  fontWeight,
  variant,
  style,
  className,
}) => {
  const theme = useTheme();

  let displayStyles;
  switch (variant) {
    case 'tiny':
      displayStyles = {
        fontSize: '1.4vw',
        lineHeight: theme.lineHeight.mega,
      };
      break;
    case 'small':
      displayStyles = {
        fontSize: theme.fontSize.bit,
        lineHeight: theme.lineHeight.byte,
      };
      break;
    default:
      displayStyles = {
        fontSize: theme.fontSize.kilo,
        lineHeight: theme.lineHeight.byte,
        margin: '5px',
      };
      break;
  }

  const tColor = colorToDisplay(textColor);
  const StyledP = styled.p`
    color: ${tColor};
    ${fontWeight && `font-weight: ${theme.fontWeight[fontWeight]};`}
    letter-spacing: ${theme.letterSpacing.exa};
    text-align: ${textAlign};
    z-index: 2;
    ${padding && `padding: 0 ${theme.spacings.kilo};`}
    ${{ ...displayStyles }}

    ${{ ...style }}

    ${theme.mq.untilTera} {
      ${variant === 'tiny' && 'font-size: 2.9vw;'}
    }

    ${theme.mq.untilMega} {
      text-align: center;
      ${variant === 'tiny' && 'font-size: 4.4vw;'}
      line-height: ${theme.lineHeight.petta};
    }

    ${theme.mq.untilByte} {
      ${variant === 'tiny' && 'font-size: 5.4vw;'}
    }
  `;

  return <StyledP className={className} css={style}>{children}</StyledP>;
};

StyledP.propTypes = {
  fontWeight: PropTypes.string,
  textColor: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};

StyledP.defaultProps = {
  fontWeight: 'bold',
  textColor: 'white',
};

export default StyledP;
