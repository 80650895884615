import { useContext } from 'react';
import Tims4GoodContext from './context/timsForGoodContext';

const farms = {
  en: {
    brazil: {
      farms: [
        {
          title: 'Donato de Carloe Filho',
          location: 'Municipality of Pedregulho',
          text: 'Donato and his family run a small coffee farm that previously did not have as much support or organization when compared to a larger producer. Since taking part in Tims Coffee for Communities trainings and workshops, Donato and family have felt like a much bigger and well equipped coffee farming enterprise. They’ve learned how to better organize their documents and files, and now know more modern ways to properly care for their coffee plants.',
          quote:
            '“We’ve learned just how important it is to farm coffee the right way, and the conditions of our farm have risen tremendously.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Donato-Brazil-1.jpg',
            alt: 'Three coffee farmers sit around a table inside beside a large window speaking with one another',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Donato-Brazil-2.jpg',
            alt: 'Two coffee farmers at a table inside sit listening to the instruction from a teacher wearing protective gear',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Donato-Brazil-3.jpg',
            alt: ' wide shot of a very green coffee plantation at day with clouds in the sky',
          },
        },
        {
          title: 'João Carlos Vissoso',
          location: 'Sitio Fern',
          text: 'After inheriting a dairy farm from his father, in 1992 Juan decided to turn the property into a coffee farm instead. With the guidance of Tims Coffee for Communities program, Juan and his faithful employees learned important safety techniques for farming coffee and have greatly improved how they conduct their business.',
          quote:
            '“I am very interested in continuing the project, I see improvements on my farm on a daily basis.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/JoaoCarlo-Brazil-1.jpg',
            alt: '3 coffee farmers sitting, facing an instructor under an open faced building',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/JoaoCarlo-Brazil-2.jpg',
            alt: 'A more close up shot of 3 coffee farmers sitting, facing an instructor under an open faced building',
          },
        },
        {
          title: 'Manoel Oliveria Lima',
          location: 'Ana Tereza Farm',
          text: 'Since 1969, Manoel has been producing coffee in Ribeirão Corrente. Only very recently has he become a participant in the trainings and workshops provided by the Tims Coffee for Communities program. With their guidance, he’s provided certified training for all his employees and has learnt the necessary skills for integrated pest and disease management.',
          quote:
            '“They are always willing to help us, I am very happy and satisfied with this partnership.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/ManoelOliveria-Brazil-1.jpg',
            alt: 'Two coffee farmers stand at a set of white tables putting on protective gear',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/ManoelOliveria-Brazil-2.jpg',
            alt: 'An instructor is standing beside a large white paper pad on an easel as he teaches',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/ManoelOliveria-Brazil-3.jpg',
            alt: 'A shot of farmers sitting in a classroom as an instructor teaches at the front of the class',
          },
          img4: {
            imgURL: 'https://cdn.rbi.digital/t4g/ManoelOliveria-Brazil-4.jpg',
            alt: 'A white shot of a plantation at day with a dirt road running inbetween it',
          },
        },
      ],
    },
    colombia: {
      farms: [
        {
          title: 'Alba Arango Ramos',
          location: 'Garzon-Huila',
          text: 'Alba’s dream has always been to be a coffee farmer like her father was. In 2018, her and her husband finally were able to bring her dream to life by starting their very own Coffee farm in the village of Los Medios. With the help of their neighbours they were able to learn more about production and key agricultural practices, but they still needed training to take it to the next level. In 2021 Alba participated in Tims Coffee for Communities program and received a new solar dryer that has improved the quality of her coffee and increased her income.',
          quote:
            '“It’s helped us not only improve our farm, but our home as well.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/AlbaArango-Col-1.jpg',
            alt: 'A woman coffee farmer stands beside a wall of bags of coffee beans',
          },
        },
        {
          title: 'Juan Carlos Gualaco Gomez',
          location: 'Acevedo-Huila',
          text: 'Juan Carlos is a young coffee farmer who has dedicated his life to the cultivation of coffee. Any chance he gets, he always signs up to be a participant in the trainings and events held thanks to Tims Coffee for Communities program. He’s learnt many technical skills from proper washing techniques that reduce his water consumption to new ways of drying the beans that increase his output efficiency. He has participated in many talks and sessions that have taught him the three pillars of sustainable coffee: Being social, helping the environment, and economics for good.',
          quote:
            '“I am very grateful to the project for allowing me to improve the activities I carry out daily in my farm.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/JaunCarlos-Col-1.jpg',
            alt: 'A farmer in rain boots poses for the camera holding a portable scale',
          },
        },
        {
          title: 'Ana Gloria Rodriguez',
          location: 'Pitalito-Huila',
          text: 'Despite knowing very little about coffee, Ana took a bet on herself and decided to start a coffee farm and named it Villa Valentina. In 2014 she was diagnosed with breast cancer, but through this adversity she never wavered from her love of coffee farming, and continued to run her farm through her treatments. She is thankful for the training provided by the Tims Coffee for Communities program. They’ve helped her learn essential safety skills for running her farm, and given her support when she needed it most.',
          quote:
            '“I’m so grateful for all you’ve done. I hope to continue to learn more for years to come.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/AnaGloria-Col-1.jpg',
            alt: 'A mother and son pose for a camera amongst their coffee plants',
          },
        },
        {
          title: 'Jennifer Alexandra Osorio',
          location: 'Valle Del Cauca',
          text: 'Jennifer’s been a farmer and farm owner for the last 3 years. With the help of Tims Coffee for Communities program, Jennifer is now able to identify nutritional deficiencies in the soil that she grows her coffee trees in and has devised a new coffee nutrition plan that could increase her productivity by 25%. ',
          quote:
            '"I’ve learnt to identify deficiencies in my soil and can now carry out more constant production at the farm. I’ve learnt a lot during each workshop”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Jennifer-Col-1.jpg',
            alt: 'A man and woman stand beside one another as the woman holds a certificate proving she’s passed the Coffee Project Training',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Jennifer-Col-2.jpg',
            alt: 'A coffee bean plant with red coffee beans and a blooming white flower',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Jennifer-Col-3.jpg',
            alt: 'A before image of barren coffee plants looking unhealthy',
          },
          img4: {
            imgURL: 'https://cdn.rbi.digital/t4g/Jennifer-Col-4.jpg',
            alt: 'An after image of a coffee plantation that is lucious and green',
          },
        },
        {
          title: 'Eladio Cerquera Duran',
          location: 'Garzon-Huila',
          text: 'In 2021, Eladio received training from Tims Coffee for Communities program that completely changed his coffee farm for the better. An entire examination of his farm and business was carried out that determined that if he changed his methodology slightly he could increase his productivity, improve the quality of his coffee, and increase his income. Over the next year, Eladio received the support he needed and increased his output significantly.',
          quote:
            '“Usually something like this would take several years, but thanks to their help I’ve already been seeing results.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Eladio-Col-1.jpg',
            alt: 'A male farmer in a yellow jacket stands beside a blue wall and is handing out pamphlets',
          },
        },
      ],
    },
    guatemala: {
      farms: [
        {
          title: 'Maria Garcia',
          location: 'Huehuetenango',
          text: 'Maria, along with her family, have learnt a great deal about the soil they grow their coffee in from the training and support provided by the Tims Coffee for Communities program. She’s been able to increase her profitability tremendously and sees the results as nothing short of remarkable.',
          quote:
            '“The training has given us the knowledge to better understand which soil is best for our land and our plants.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/MariaGarcia-Guat-1.jpg',
            alt: 'A shot of a female farmer as she speaks amongst her coffee plants',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/MariaGarcia-Guat-2.jpg',
            alt: 'A wide shot of two farmers speaking in the forest amongst coffee plants',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/MariaGarcia.gif',
            alt: 'A gif of two coffee farmers inspecting a coffee plant',
          },
        },
        {
          title: 'Yeltsin Lucas',
          location: 'Huehuetenango',
          text: 'Yeltsin is a young entrepreneur and farmer who was raised by his grandparents to love everything about coffee farming. He is extremely grateful for the training and support provided by the Tims Coffee for Communities program. Through his training, the quality of his coffee plants have increased and he’s seen a sizable reduction in the plants he’s lost to disease.',
          quote:
            '“I’ve seen really positive results from the great support we received from the coffee project.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Yeltsin-Gaut-1.jpg',
            alt: 'A close up of a young male coffee farmer looking into the camera',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Yeltsin-Gaut-2.jpg',
            alt: 'An instructor teaches a young farmer how to use specialized equipment out in the wild',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Yeltsin.gif',
            alt: 'A gif of an overhead shot of two farmers walking along their green and lucious coffee plantation',
          },
        },
        {
          title: 'Santos Juarez',
          location: 'Huehuetenango',
          text: 'Before the Tims Coffee for Communities program, Santos didn’t feel like he had the proper equipment, or training, to safely farm coffee the way he wanted to. Now thanks to being supplied the use of protective equipment and proper safety training, Santos feels much more confident in his every day work.',
          quote:
            '“I personally am infinitely grateful to the project for the help they have given us.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/SantosJuarez-Gaut-1.jpg',
            alt: 'A farmer with a blue canister on his back sprays down his coffee plants',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/SantosJuarez-Gaut-2.jpg',
            alt: 'A portrait shot of a farmer wearing a straw hat looking off into the distance as he stands near his coffee plants',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/SantosJuarez.gif',
            alt: 'A gif of an overhead shot of a farmer walking and examining his coffee plants in the forest',
          },
        },
        {
          title: 'Carolina Hernández',
          location: 'Santa Rosa',
          text: 'At first, Carolina was skeptical of the kind of help the Tims Coffee for Communities program could bring her. She has been a coffee farmer for many years and felt she already knew everything that she needed to know. It wasn’t until she took part in the trainings that she realized that implementing the techniques she learnt could increase her production exponentially. She’s grateful for the support the project brings and is excited for what the future holds.',
          quote:
            '“In a few years I now see myself with more coffee plantations so I can give my daughter a better future.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/CarolinaHernandez-Gaut-1.jpg',
            alt: 'A woman farmer in a blue hat smiles for the camera as she holds the leaves of one of her coffee plants',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/CarolinaHernandez-Gaut-2.jpg',
            alt: 'A close up of red coffee beans on a coffee tree as the sun hits them',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/CarolinaHernandez.gif',
            alt: 'A woman farmer in all blue guides an instructor through her coffee plants',
          },
        },
        {
          title: 'Sandra Nájera',
          location: 'Jalapa',
          text: 'Sandra is proud to be a woman farmer who owns her own land in Guatemala. She is also an advocate for other women farmers and encourages many, including her own daughters, to do the trainings and workshops provided by the Tims Coffee for Communities program. Her goal is to have all women become financially independent and she believes the project gives women the tools to do that.',
          quote:
            '“Women can do everything men can, we’ve learnt the skills that can help us do great things.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/SandraNajera-Gaut-1.jpg',
            alt: 'A female farmer in a red hat smiles for the camera as she holds the leaves of one of her coffee plants',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/SandraNajera-Gaut-2.jpg',
            alt: 'An overhead view of a female farmer in white, tending to her coffee plants in her coffee plantation',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/SandraNájera.gif',
            alt: 'A gif from the perspective of looking up at the blue sky, directly under someone’s hands cupped together as they drop coffee beans onto the camera lens',
          },
        },
        {
          title: 'Ubaldo Castellanos',
          location: 'Santa Rosa',
          text: 'Ubaldo has struggled with getting the proper supplies for his coffee farm and overcoming plants that have become more prone to disease. With the teachings passed down to him from the Tims Coffee for Communities program, Ubaldo has been able to learn vital skills that have helped him. These include identifying nutrient deficiencies in his soil and new ways of fertilizing. His newfound skills have helped him keep his farm afloat and continue to be profitable during economic and environmental crises.',
          quote:
            '“It’s very satisfying to see the results, and help my family.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Ubaldo-Gaut-1.jpg',
            alt: 'A male farmer in a blue patterned shirt looks into the camera',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Ubaldo-Gaut-2.jpg',
            alt: 'A male farmer in a blue patterned shirt shows his coffee plants to an instructor',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Ubaldo.gif',
            alt: 'A gif that is filming the back of a male farmer in a blue patterned shirt as he  pushes some plants in the way to the side',
          },
        },
        {
          title: 'Idali Tapiero Silva',
          location: 'Guatemala',
          text: ' From a young age, Idali’s father took her to the coffee farms to watch him pick coffee and it is there that she fell in love with the process. Now as an adult who has become a coffee farmer herself, she is extremely grateful for the support she receives from Tims Coffee for Communities program. She likes that she can be trained by other women because it makes her feel empowered and she believes this will help more women become respected as coffee farmers. From agronomics to learning improved methods of farming, Idali loves all that she has learnt thanks to these projects.',
          quote: '"They have given us everything, and for that I thank you.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Idali-Mex-1.jpg',
            alt: 'A woman farmer in a greenhouse shows the camera her portable scale',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Idali-Mex-2.jpg',
            alt: 'A woman farmer with a bandana around her mouth poses for the camera as she puts coffee beans in her yellow bucket',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/IdaliTapieroSilva.gif',
            alt: 'A gif of a woman farmer in all blue, picking coffee beans and putting them in her yellow bucket',
          },
        },
        {
          title: 'Beisi Carrillo',
          location: 'El Triunfo, Guatemala',
          text: 'Beisi is a motivated 28-year-old woman farmer born and raised in the coffee world. She has participated in many business trainings through the coffee project, and through their help secured funding for her own business after presenting her proposal to a panel. Beisi’s involvement in various programs, including starting her own youth saving group, have allowed her to broaden her vision on the different ways to innovate and generate income as a female coffee entrepreneur in the modern world. She’s continuing her knowledge by pursuing education through the coffee project to become a Climate Pioneer.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Beisi_1.jpg',
            alt: 'Portrait of a young female farmer as she looks at the camera, behind her is a beautiful skyline',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Beisi_2.jpg',
            alt: 'Picture of 1 female and 2 male farmers using a device to lower farming equipment',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Beisi_Gif.gif',
            alt: 'A gif of farmers picking red coffee beans from their trees',
          },
        },
        {
          title: 'Maricelda Gómez Juarez',
          location: 'El Triunfo, Guatemala',
          text: 'Maricelda is a coffee farmer, a mother of two, and the sole provider for her family. With little knowledge of farm management at first, she faced the challenge of taking over the family’s coffee farm. With the help of the training  and workshops provided by the coffee project Maricelda has empowered herself to significantly improve the quality of her coffee and sell it through farmer organizations.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Maricelda_1.jpg',
            alt: 'Image of a woman coffee farmer tending to her crops',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Maricelda_2.jpg',
            alt: 'A family of all women poses for a picture outside of their home',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Maricelda_GIF.gif',
            alt: 'Gif of a woman coffee farmer as she smiles and rubs her hand through a pile of coffee beans',
          },
        },
        {
          title: 'Floridamla Martinez',
          location: 'Huehuetenango, Guatemala',
          text: 'Floridalma, an outgoing 24-year-old, who has stayed in Guatemala to manage her family’s coffee farm. Her involvement in the coffee project changed her perspective, seeing coffee farming as a viable livelihood. The training and workshops made her feel motivated and secure and she now oversees the entire coffee process and administrative work. She leads a youth group and promotes agronomy internships, and is a bio-factory entrepreneur.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Floridalma_1.jpg',
            alt: 'A group of young coffee farmers pose for the camera standing amongst coffee plants',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Floridalma_2.jpg',
            alt: 'A close up shot a female coffee farmer with long hair, wearing a hat and smiling at the camera',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Floridalma_GIF.gif',
            alt: ' gif of a female farmer walking onto a platform of coffee beans and sweeping them with the picturesque views of Guatemala in the background',
          },
        },
        {
          title: 'Rudy Martinez',
          location: 'San Antonio Huista, Guatemala',
          text: 'Coffee farming has been a part of Rudy’s family roots for generations, and as a result he had been trained in more \'traditional\' coffee farming practices. Since becoming an active member of the coffee project in 2019, Rudy and his family have learnt and become more open to new farming practices. Guided by the training they’ve learnt, they’ve implemented climate-smart and organic practices, improved their soil’s health and now know proper crop distancing. Rudy’s farm has set an example for his community, convincing others to make similar changes. His production is better, and he’s made investments to increase productivity even further. He encourages his children to now participate in youth groups that promote these practices so they can be the next generation of coffee farmers.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Rudy_1.jpg',
            alt: 'A family of coffee farmers wearing blue look on as the father shows them one of their coffee plants',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Rudy_2.jpg',
            alt: 'A family of coffee farmers wearing blue pose in a line for a photo',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Rudy_GIF.gif',
            alt: 'A father coffee farmer in a blue shirt and white hat shows his daughter what to look for when picking a coffee plant',
          },
        },
        {
          title: 'Oscar Perez Matiaz',
          location: 'Huehuetenango - Guatemala',
          text: 'Oscar joined the coffee project in 2019, gaining knowledge and experience in farm management and climate adaptation. Now, he has become a young leader in his community, sharing sustainable farming practices with others, including his family. His father, who taught him how to farm coffee, now asks him for advice. He now manages his family’s farm.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Oscar_1.jpg',
            alt: 'Portrait shot of a young coffee farmer in traditional Guatemalan farming attire',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Oscar_2.jpg',
            alt: 'A young farmer in traditional Guatemalan farming attire tends to one of his coffee plants',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Oscar_GIF.gif',
            alt: 'A gif of a young coffee farmer in traditional Guatemalan farming attire pouring beans into and operating coffee farming machinery',
          },
        },
      ],
    },
    indonesia: {
      farms: [
        {
          title: 'Marjiati',
          location: 'Sumatera',
          text: 'Marjiati and her husband have been a part of the coffee world for over 15 years, and have 1,200 coffee trees in their farm. Because of the training she has received in part thanks to Tims Coffee for Communities program, her and her husband’s farm is shown as an example for good coffee practices around the world. She is now a key role model for other coffee groups and every day utilizes specific leadership skills that she discovered through the program.',
          quote:
            '“We’ve learnt so much, and we’re now excited to teach others.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Marijiata-Indo-1.jpg',
            alt: 'A group of coffee farmers in Indonesia pose together for a photo in their coffee field',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Marijiata-Indo-2.jpg',
            alt: 'A female coffee farmer in pink tends to one of her coffee plants',
          },
        },
        {
          title: 'Proto Sembiring',
          location: 'North Sumatera',
          text: 'In 2021, Proto and his family took part in several ergonomic trainings and workshops provided by the Tims Coffee for Communities program. These workshops helped them to learn new intercropping systems that kept his business a float. Now, he has planted an additional 1,000 new coffee plants supported by the program.',
          quote:
            '“I hope programs like this will continue to be developed, they provide great benefit to farmers like me.”',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/ProtoSemb-Indo-1.jpg',
            alt: 'A wide shot of a male coffee farmer smiling as he stands amongst his coffee plants',
          },
        },
      ],
    },
  },
  fr: {
    brazil: {
      farms: [
        {
          title: 'Donato de Carloe Filho',
          location: 'Municipalité de Pedregulho, Brésil',
          text: 'Donato et sa famille exploitent une petite plantation de café qui, auparavant, n’avait pas le même soutien ni la structure des plus grands producteurs. Depuis qu’ils ont pris part aux formations et aux ateliers du programme de partenariat de café Tim, Donato et sa famille sont beaucoup mieux outillés et structurés. Ils ont appris à mieux organiser leurs documents et leurs dossiers et connaissent maintenant des moyens plus modernes pour prendre soin de leurs plants de café.',
          quote:
            '« Nous avons appris à quel point il est important de cultiver le café de la bonne façon. Les conditions de notre plantation se sont grandement améliorées. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Donato-Brazil-1.jpg',
            alt: 'Trois producteurs de café sont assis autour d’une table, à l’intérieur, près d’une grande fenêtre, et discutent entre eux.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Donato-Brazil-2.jpg',
            alt: 'Deux producteurs de café sont assis à une table à l’intérieur écoutent les instructions d’un instructeur portant un équipement de protection.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Donato-Brazil-3.jpg',
            alt: 'Plan large d’une plantation de café très verte, de jour, avec des nuages dans le ciel.',
          },
        },
        {
          title: 'João Carlos Vissoso',
          location: 'Sitio Fern, Brésil',
          text: 'Après avoir hérité d’une ferme laitière de son père en 1992, Juan a décidé de transformer sa ferme en plantation de café. Avec l’aide du programme de partenariat de café Tim, Juan et ses fidèles employés ont appris d’importantes techniques de sécurité pour la culture du café et ont grandement amélioré leurs activités commerciales.',
          quote:
            '« Je suis vraiment motivé à continuer le projet. Chaque jour, je vois des améliorations sur ma plantation. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/JoaoCarlo-Brazil-1.jpg',
            alt: 'Trois producteurs de café sont assis, face à un instructeur, sous un bâtiment à façade ouverte.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/JoaoCarlo-Brazil-2.jpg',
            alt: 'Plan plus rapproché de trois producteurs de café assis, face à un instructeur, sous un bâtiment à façade ouverte.',
          },
        },
        {
          title: 'Manoel Oliveria Lima',
          location: 'Plantation Ana Tereza, Brésil',
          text: 'Depuis 1969, Manoel produit du café à Ribeirão Corrente. Ce n’est que récemment qu’il a pris part aux formations et aux ateliers du programme de partenariat de café Tim. Le programme lui a permis d’offrir une formation certifiée à tous ses employés et d’acquérir les compétences nécessaires à la gestion des parasites et des maladies.',
          quote:
            '« Ils sont toujours prêts à nous aider, je suis très heureux de ce partenariat. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/ManoelOliveria-Brazil-1.jpg',
            alt: 'Deux producteurs de café se tiennent devant des tables blanches et portent des équipements de protection.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/ManoelOliveria-Brazil-2.jpg',
            alt: 'Un instructeur se tient debout à côté d’un grand tableau de papier blanc sur un chevalet pendant qu’il enseigne.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/ManoelOliveria-Brazil-3.jpg',
            alt: 'Plan d’agriculteurs assis dans une salle de classe tandis qu’un instructeur enseigne à l’avant de la salle.',
          },
          img4: {
            imgURL: 'https://cdn.rbi.digital/t4g/ManoelOliveria-Brazil-4.jpg',
            alt: 'Plan large d’une plantation de jour avec un chemin de terre qui la traverse.',
          },
        },
      ],
    },
    colombia: {
      farms: [
        {
          title: 'Alba Arango Ramos',
          location: 'Garzon, Huila, Colombie',
          text: 'Le rêve d’Alba a toujours été de devenir une productrice de café, comme son père. En 2018, elle et son mari ont finalement exaucé son souhait en démarrant leur propre plantation de café dans le village de Los Medios. Avec l’aide du voisinage, ils ont pu en apprendre davantage sur la production et les principales pratiques agricoles, mais ils avaient encore besoin de formation pour passer au niveau supérieur. En 2021, Alba a participé au programme de partenariat de café Tim et a reçu un nouveau séchoir solaire, ce qui lui a permis d’améliorer la qualité de son café et d’augmenter ses revenus.',
          quote:
            '« Ça nous a aidés sur la plantation, mais également à la maison. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/AlbaArango-Col-1.jpg',
            alt: 'Une productrice de café se tient à côté de sacs de grains de café empilés.',
          },
        },
        {
          title: 'Juan Carlos Gualaco Gomez',
          location: 'Acevedo, Huila, Colombie',
          text: 'Juan Carlos est un jeune producteur agricole qui a consacré sa vie à la culture du café. Dès qu’il en a l’occasion, il participe aux formations et aux événements du programme de partenariat de café Tim. Il a d’ailleurs acquis plusieurs compétences techniques, comme les bonnes pratiques de lavage pour réduire sa consommation d’eau et de nouvelles façons plus efficaces de sécher ses grains. Il a pris part à de nombreuses discussions et sessions qui l’ont familiarisé avec nos engagements dans les trois grands domaines du développement durable : les gens et les communautés, la planète et la qualité des aliments et des boissons.',
          quote:
            '« Je suis très reconnaissant puisque le projet m’a permis d’améliorer mes activités quotidiennes sur ma plantation. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/JaunCarlos-Col-1.jpg',
            alt: 'Un agriculteur portant des bottes de pluie pose en tenant une balance portable.',
          },
        },
        {
          title: 'Ana Gloria Rodriguez',
          location: 'Pitalito, Huila, Colombie',
          text: 'Même si elle en connaissait très peu sur le café, Ana s’est tout de même lancée en démarrant sa plantation de café, qu’elle a nommé Villa Valentina. En 2014, elle a reçu un diagnostic de cancer du sein. Malgré l’adversité, elle n’a jamais délaissé son amour pour la culture du café et elle a continué la gestion de sa plantation tout au long de ses traitements. Elle est très reconnaissante pour les formations du programme de partenariat de café Tim. Le programme lui a permis d’acquérir des compétences essentielles en matière de sécurité pour gérer sa plantation et d’avoir du soutien durant une période où elle en avait bien besoin.',
          quote:
            '« Je suis vraiment reconnaissante pour tout ce que vous avez fait. J’espère continuer à en apprendre davantage dans les années à venir. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/AnaGloria-Col-1.jpg',
            alt: 'Une mère et son fils posent au milieu de leurs plants de café.',
          },
        },
        {
          title: 'Jennifer Alexandra Osorio',
          location: 'Vallée du Cauca, Colombie',
          text: 'Jennifer est productrice de café et propriétaire agricole depuis 3 ans. Grâce au programme de partenariat de café Tim, elle a pu identifier les carences nutritionnelles du sol où elle fait pousser ses caféiers et a pu développer un nouveau plan de nutrition qui pourrait faire augmenter sa productivité jusqu’à 25 %.',
          quote:
            '« J’ai appris à identifier les carences de mon sol, ce qui me permet d’avoir une production plus constante. J’ai beaucoup appris durant chaque formation. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Jennifer-Col-1.jpg',
            alt: 'Un homme et une femme se tiennent l’un à côté de l’autre, la femme tenant un certificat prouvant qu\'elle a réussi la formation du partenariat de café.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Jennifer-Col-2.jpg',
            alt: 'Un plant de caféier avec des grains de café rouges et une fleur blanche éclose.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Jennifer-Col-3.jpg',
            alt: 'Une image antérieure de plants de café stériles et en mauvaise santé.',
          },
          img4: {
            imgURL: 'https://cdn.rbi.digital/t4g/Jennifer-Col-4.jpg',
            alt: 'Une image après coup d’une plantation de café luxuriante et verdoyante.',
          },
        },
        {
          title: 'Eladio Cerquera Duran',
          location: 'Garzon, Huila, Colombie ',
          text: 'En 2021, Eladio a suivi une formation du programme de partenariat de café Tim qui a complètement transformé sa plantation de café. Une analyse complète de ses installations et de son entreprise a été effectuée pour constater qu’en modifiant légèrement sa méthodologie, il pourrait améliorer la qualité de son café et accroître sa productivité et ses revenus. L’année suivante, Eladio a reçu tout le soutien nécessaire pour augmenter considérablement sa production.',
          quote:
            '« Habituellement, ce genre de projet s’étend sur plusieurs années, mais grâce à leur aide, je vois déjà des résultats. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Eladio-Col-1.jpg',
            alt: 'Un agriculteur vêtu d’un manteau ciré jaune se tient à côté d’un mur bleu et distribue des brochures.',
          },
        },
      ],
    },
    guatemala: {
      farms: [
        {
          title: 'Maria Garcia',
          location: 'Huehuetenango, Guatemala',
          text: 'Grâce aux formations du programme de partenariat de café Tim, Maria et sa famille ont énormément appris à propos du sol où ils cultivent leur café. Elle a donc été en mesure d’augmenter considérablement sa rentabilité et est très impressionnée par les résultats obtenus.',
          quote:
            '« La formation nous a fourni les connaissances nécessaires pour déterminer quel est le meilleur type de sol pour notre territoire et nos plantations. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/MariaGarcia-Guat-1.jpg',
            alt: 'Une agricultrice parle au milieu de ses plants de café.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/MariaGarcia-Guat-2.jpg',
            alt: 'Plan large de deux agriculteurs discutant dans la forêt au milieu des plants de café.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/MariaGarcia.gif',
            alt: 'Gif de deux cultivateurs de café inspectant un plant de café.',
          },
        },
        {
          title: 'Yeltsin Lucas',
          location: 'Huehuetenango, Guatemala',
          text: 'Yelstin est un jeune producteur et entrepreneur, et ce sont ses grands-parents qui lui ont transmis leur amour pour la culture du café. Il est extrêmement reconnaissant pour la formation et le soutien reçus dans le cadre du programme de partenariat de café Tim. À la suite de sa formation, la qualité de ses plantations de café s’est améliorée, et il a été en mesure de constater une réduction des plants perdus à cause de maladies.',
          quote:
            '« Grâce à l’excellent soutien du projet, j’ai pu voir des résultats très positifs. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Yeltsin-Gaut-1.jpg',
            alt: 'Gros plan d’un jeune cultivateur de café regardant directement dans l’objectif.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Yeltsin-Gaut-2.jpg',
            alt: 'Un instructeur apprend à un jeune agriculteur à utiliser du matériel spécialisé dans la nature.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Yeltsin.gif',
            alt: 'Un gif d’une prise de vue aérienne de deux agriculteurs marchant le long de leur plantation de café verte et luxuriante.',
          },
        },
        {
          title: 'Santos Juarez',
          location: 'Huehuetenango, Guatemala',
          text: 'Avant de participer au programme de partenariat de café Tim, Santos n’avait pas l’impression d’avoir l’équipement adéquat ni la formation nécessaire pour cultiver le café de façon sécuritaire. Grâce à des formations en matière de sécurité ainsi que sur l’utilisation de l’équipement de protection, Santos se sent maintenant beaucoup plus en confiance au travail.',
          quote:
            '« Je suis infiniment reconnaissant de l’aide que j’ai reçue dans le cadre de ce projet. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/SantosJuarez-Gaut-1.jpg',
            alt: 'Un agriculteur transportant un bidon bleu sur le dos vaporise ses plants de café.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/SantosJuarez-Gaut-2.jpg',
            alt: 'Plan portrait d’un agriculteur portant un chapeau de paille et regardant au loin, debout près de ses plants de café.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/SantosJuarez.gif',
            alt: 'Gif d’une prise de vue aérienne d’un agriculteur marchant et examinant ses plants de café dans la forêt.',
          },
        },
        {
          title: 'Carolina Hernández',
          location: 'Santa Rosa, Guatemala',
          text: 'Au début, Carolina était sceptique quant au genre d’aide que le programme de partenariat de café Tim pourrait lui offrir. Étant productrice de café depuis plusieurs années, elle croyait déjà tout savoir. Mais c’est en participant aux formations qu’elle a réalisé qu’en appliquant les techniques apprises, elle pourrait augmenter sa production de façon exponentielle. Elle est vraiment reconnaissante de tout le soutien reçu, et elle est impatiente de voir ce que l’avenir lui réserve.',
          quote:
            '« D’ici quelques années, je m’imagine déjà avec plusieurs autres plantations que je pourrai léguer à ma fille. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/CarolinaHernandez-Gaut-1.jpg',
            alt: 'Une agricultrice coiffée d’un chapeau bleu sourit à l’appareil photo en tenant les feuilles d’un de ses plants de café.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/CarolinaHernandez-Gaut-2.jpg',
            alt: 'Gros plan de grains de café rouges sur un caféier, sous le soleil.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/CarolinaHernandez.gif',
            alt: 'Une agricultrice vêtue de bleu guide un instructeur à travers ses plants de café.',
          },
        },
        {
          title: 'Sandra Nájera',
          location: 'Jalapa, Guatemala',
          text: 'Sandra est fière d’être une productrice de café qui possède sa propre terre au Guatemala. Elle encourage également d’autres productrices, incluant ses filles, à participer aux formations et aux ateliers du programme de partenariat de café Tim. Son objectif est que toutes les femmes deviennent financièrement indépendantes, et elle est convaincue que ce projet leur donne les outils pour y parvenir.',
          quote:
            '« Les femmes peuvent faire tout ce que les hommes font, nous avons acquis les compétences pour nous aider à faire de grandes choses. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/SandraNajera-Gaut-1.jpg',
            alt: 'Une agricultrice coiffée d’un chapeau rouge sourit dans l’objectif en tenant les feuilles d’un de ses plants de café.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/SandraNajera-Gaut-2.jpg',
            alt: 'Vue aérienne d’une agricultrice vêtue de blanc, s’occupant de ses plants de café dans sa plantation de café.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/SandraNájera.gif',
            alt: 'Gif de la vue vers le ciel bleu, directement sous les mains de quelqu’un qui laisse tomber des grains de café sur l’objectif.',
          },
        },
        {
          title: 'Ubaldo Castellanos',
          location: 'Santa Rosa, Guatemala',
          text: 'Par le passé, Ubaldo a eu de la difficulté à obtenir l’équipement approprié pour cultiver son café, et ses plants sont devenus plus propices aux maladies. Grâce aux enseignements reçus dans le cadre du programme de partenariat de café Tim, Ubaldo a pu acquérir des compétences clés qui l’ont grandement aidé, notamment pour identifier les carences nutritionnelles de son sol et découvrir de nouvelles méthodes de fertilisation. Ces compétences lui ont permis de rester en affaires et d’être rentable malgré les crises économiques et environnementales.',
          quote:
            '« C’est très satisfaisant de voir les résultats et de pouvoir aider ma famille. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Ubaldo-Gaut-1.jpg',
            alt: 'Un agriculteur vêtu d’une chemise bleue à motifs regarde dans l’objectif.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Ubaldo-Gaut-2.jpg',
            alt: 'Un agriculteur vêtu d’une chemise bleue à motifs montre ses plants de café à un instructeur.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Ubaldo.gif',
            alt: 'Gif qui montre le dos d’un agriculteur vêtu d’une chemise bleue à motifs alors qu’il pousse des plantes sur le côté.',
          },
        },
        {
          title: 'Idali Tapiero Silva',
          location: 'Guatemala',
          text: 'Dès son plus jeune âge, Idali accompagnait son père dans les plantations pour cueillir les grains. C’est à ce moment qu’elle est tombée amoureuse de la culture du café. Maintenant elle-même productrice de café, elle est extrêmement reconnaissante du soutien reçu dans le cadre du programme de partenariat de café Tim. Elle aime avoir la possibilité d’être formée par des femmes parce qu’elle se sent soutenue et croit que cela peut aider les productrices à être encore plus respectées. De l’agronomie aux techniques agricoles améliorées, Idali est très reconnaissante de tout ce qu’elle a appris avec le programme.',
          quote: '« Ils nous ont tout donné, et je les remercie. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Idali-Mex-1.jpg',
            alt: 'ne agricultrice dans une serre lève sa balance portable à l’objectif.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Idali-Mex-2.jpg',
            alt: 'Une agricultrice, portant un bandana autour de la bouche, pose pour l’appareil photo alors qu’elle met des grains de café dans son seau jaune.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/IdaliTapieroSilva.gif',
            alt: 'Gif d’une agricultrice habillée tout en bleu, cueillant des grains de café et les mettant dans son seau jaune.',
          },
        },
        {
          title: 'Beisi Carrillo',
          location: 'El Triunfo, Guatemala',
          text: 'Beisi est une productrice de 28 ans des plus déterminée qui a été élevée dans le monde du café. Elle a participé à de nombreuses formations commerciales dans le cadre du programme, ce qui lui a permis d’obtenir du financement pour lancer sa propre entreprise après l’avoir présenté devant un comité. Son implication dans différents programmes, incluant la création de son propre groupe d’épargne pour les jeunes, lui a permis de découvrir différentes façons d’innover et de générer des revenus en tant que femme entrepreneure dans le monde d’aujourd’hui. Elle continue toujours d’en apprendre plus en poursuivant son éducation dans le cadre du programme afin de devenir une Pionnière pour le climat.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Beisi_1.jpg',
            alt: 'Plan portrait d’une jeune agricultrice qui regarde dans l’objectif. Derrière elle, on aperçoit un bel horizon.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Beisi_2.jpg',
            alt: 'Une agricultrice et deux agriculteurs utilisent un dispositif pour abaisser du matériel agricole.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Beisi_Gif.gif',
            alt: 'Gif d’agriculteurs cueillant des grains de café rouges sur leurs arbres.',
          },
        },
        {
          title: 'Maricelda Gómez Juarez',
          location: 'El Triunfo, Guatemala',
          text: 'Maricelda, productrice de café et mère de deux enfants, est seule à subvenir aux besoins de sa famille. Avec peu de connaissances en gestion agricole au départ, elle a su relever le défi de reprendre la plantation de café familiale. Avec l’aide des formations et des ateliers du programme, Maricelda a trouvé tout le soutien nécessaire afin d’améliorer considérablement la qualité de son café et de le vendre à travers divers organismes de producteurs.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Maricelda_1.jpg',
            alt: 'Une cultivatrice de café s’occupant de ses plants.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Maricelda_2.jpg',
            alt: 'Une famille composée uniquement de femmes pose à l’extérieur de leur maison.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Maricelda_GIF.gif',
            alt: 'Une famille composée uniquement de femmes pose à l’extérieur de leur maison.',
          },
        },
        {
          title: 'Floridamla Martinez',
          location: 'Huehuetenango, Guatemala',
          text: 'Floridalma, jeune femme énergique de 24 ans, est restée au Guatemala pour gérer la plantation de café familiale. Son implication dans le programme a changé sa perspective, et elle considère maintenant la culture du café comme un moyen de subsistance viable. Les formations et les ateliers lui ont permis de se sentir en sécurité et motivée, tout en supervisant le volet opérationnel et administratif de la plantation. Elle dirige un groupe de jeunes et promeut des stages en agronomie, en plus d’être entrepreneure en bio-usine.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Floridalma_1.jpg',
            alt: 'Un groupe de jeunes producteurs de café pose au milieu de plants de café.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Floridalma_2.jpg',
            alt: 'Gros plan sur une productrice de café aux cheveux longs, portant un chapeau et souriant dans l’objectif.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Floridalma_GIF.gif',
            alt: 'Gif d’une agricultrice marchant sur une plateforme de grains de café et les balayant, avec une vue pittoresque du Guatemala en arrière-plan.',
          },
        },
        {
          title: 'Rudy Martinez',
          location: 'San Antonio Huista, Guatemala',
          text: 'La culture du café fait partie de l’héritage familial de Rudy depuis plusieurs générations. Il a donc été formé avec des pratiques agricoles plus « traditionnelles ». Depuis qu’ils ont rejoint le programme en 2019, Rudy et sa famille ont appris de nouvelles pratiques agricoles et sont plus ouverts à l’agriculture moderne. Grâce à la formation, ils ont pu appliquer des pratiques biologiques et intelligentes contre le changement climatique, améliorer la santé de leur sol et connaître la distance idéale pour espacer les plants. La plantation de Rudy est devenue un exemple pour sa communauté et a convaincu d’autres producteurs d’apporter des modifications similaires. Sa production s’est améliorée, et il a également fait des investissements pour augmenter sa productivité. Il encourage ses enfants à participer à des groupes de jeunes qui font la promotion de ces types de pratiques pour qu’ils puissent devenir à leur tour la prochaine génération de producteurs de café.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Rudy_1.jpg',
            alt: 'Une famille de cultivateurs de café vêtus de bleu regarde le père leur montrer l’un de leurs plants de café.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Rudy_2.jpg',
            alt: 'Une famille de producteurs de café vêtus de bleu pose en ligne.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Rudy_GIF.gif',
            alt: 'Gif d’un cultivateur de café, vêtu d’une chemise bleue et d\'un chapeau blanc, qui montre à sa fille comment cueillir un plant de café.',
          },
        },
        {
          title: 'Oscar Perez Matiaz',
          location: 'Huehuetenango, Guatemala',
          text: 'Oscar a rejoint le programme en 2019, et a acquis des connaissances et de l’expérience en gestion agricole et en adaptation au climat. Aujourd’hui, il est un jeune leader dans sa communauté et partage des pratiques agricoles durables avec les autres producteurs, y compris sa famille. Son père, qui l’a initié à la culture du café, lui demande maintenant conseil. Il s’occupe maintenant de la ferme familiale.',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Oscar_1.jpg',
            alt: 'Portrait d’un jeune producteur de café en tenue traditionnelle guatémaltèque.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Oscar_2.jpg',
            alt: 'Un jeune agriculteur en tenue traditionnelle guatémaltèque s’occupe de l’un de ses plants de café.',
          },
          img3: {
            imgURL: 'https://cdn.rbi.digital/t4g/Oscar_GIF.gif',
            alt: '​​Gif d’un jeune cultivateur de café en tenue traditionnelle guatémaltèque versant des grains dans des machines de culture du café et les faisant fonctionner.',
          },
        },
      ],
    },
    indonesia: {
      farms: [
        {
          title: 'Marjiati',
          location: 'Sumatera, Indonésie',
          text: 'Marjiati et son mari sont impliqués dans le monde du café depuis plus de 15 ans et possèdent plus 1 200 caféiers dans leur plantation. Grâce à la formation du programme de partenariat de café Tim, leur plantation est devenue un exemple à travers le monde en matière de bonnes pratiques caféicoles. Elle est maintenant un modèle pour différents groupes de producteurs et elle continue d’utiliser au quotidien ses compétences de leadership acquises avec le programme.',
          quote:
            '« Nous avons tellement appris et nous avons hâte d’enseigner tout cela aux autres. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/Marijiata-Indo-1.jpg',
            alt: 'Un groupe de cultivateurs de café en Indonésie pose ensemble pour une photo dans leur champ de café.',
          },
          img2: {
            imgURL: 'https://cdn.rbi.digital/t4g/Marijiata-Indo-2.jpg',
            alt: 'Une productrice de café habillée en rose s’occupe de l’un de ses plants de café.',
          },
        },
        {
          title: 'Proto Sembiring',
          location: 'North Sumatera, Indonésie',
          text: 'En 2021, Proto et sa famille ont participé à plusieurs formations et ateliers du programme de partenariat de café Tim. Ces ateliers leur ont permis d’apprendre de nouveaux systèmes de culture intercalaire pour garder leur entreprise en affaires. Grâce au programme, il a aussi planté 1 000 nouveaux plants de café.',
          quote:
            '« Je souhaite que ce genre de programme continue de se développer. Ils sont vraiment bénéfiques pour les producteurs comme moi. »',
          img1: {
            imgURL: 'https://cdn.rbi.digital/t4g/ProtoSemb-Indo-1.jpg',
            alt: 'Plan large d’un cultivateur de café souriant au milieu de ses plants de café.',
          },
        },
      ],
    },
  },
};

const farmPicker = (section) => (key) => {
  const { lang } = useContext(Tims4GoodContext);
  const textObj = farms[lang][section][key];
  return textObj;
};

export { farmPicker };
