import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const TimSection = ({
  children,
  topAndBottomPaddingRequired,
  backgroundColorDisplayed,
  backgroundImage,
  id,
  style,
}) => {
  const theme = useTheme();

  const paddingtopBottom = topAndBottomPaddingRequired
    ? theme.spacings.zetta
    : 0;

  const bgColor = backgroundColorDisplayed !== 'none'
    ? { backgroundColor: theme.colors[backgroundColorDisplayed] }
    : null;

  const bgImage = backgroundImage !== 'none'
    ? {
      backgroundImage:
            'url("https://cdn.rbi.digital/t4g/timeline_background.jpg")',
    }
    : null;

  const TimSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: center;
    padding: ${paddingtopBottom} 0;
    ${bgColor};
    ${bgImage};
    overflow: hidden;

    ${theme.mq.untilByte} {
      ${backgroundImage !== 'none'
    ? { paddingTop: `${theme.spacings.zetta}` }
    : null}
    }
  `;

  return <TimSection style={style} id={id}>{children}</TimSection>;
};

TimSection.propTypes = {
  topAndBottomPaddingRequired: PropTypes.bool,
  backgroundColorDisplayed: PropTypes.string,
  backgroundImage: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
};

TimSection.defaultProps = {
  topAndBottomPaddingRequired: false,
  backgroundColorDisplayed: 'none',
  backgroundImage: 'none',
  style: {},
};

export default TimSection;
