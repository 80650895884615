import React from 'react';
import { Global, css } from '@emotion/react';
import { GeneralStyles as theme } from './generalStyles';

const GlobalStyler = () => (
  <Global
    styles={css`
        html { scroll-behavior: smooth; }
        #tims-for-good {
          font-family: 'sofia_pro';
          font-weight: 700;
          line-height: 1.1;
          width: 100%;
          overflow:hidden;

          h1,
          h2,
          h3,
          h4,
          p,
          button {
            font-family: 'sofia_pro';
          }
        }
      `}
  />
);

GlobalStyler.propTypes = {};

export default GlobalStyler;
