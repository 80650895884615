import styled from '@emotion/styled';
import { GeneralStyles as theme } from '../styles/generalStyles';

export const MapNavContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.brown2};
  @media (min-width: ${theme.breakpoints.mega}px) {
    overflow-y: scroll;
    height: ${({ height }) => height}px;
  }
`;

export const MapNavContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 36px;
  justify-content: center;
  ${theme.mq.untilMega} {
    padding: 98px 36px;
  }
  ${theme.mq.untilKilo} {
    padding: 76px ${theme.spacings.kilo} 76px ${theme.spacings.kilo};
  }
`;

export const Title = styled.h2`
  color: ${theme.colors.white};
  font-size: 37px;
  line-height: 37.5px;
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: 24px;
  margin-top: 0px;
  ${theme.mq.untilMega} {
    font-size: 37px;
  }
  ${theme.mq.untilKilo} {
    font-size: ${theme.fontSize.kilo};
  }
`;

export const ActionText = styled.text`
  color: ${theme.colors.white};
  font-size: ${theme.fontSize.kilo};
  font-weight: ${theme.fontWeight.bold};
  line-height: 28px;
  ${theme.mq.untilKilo} {
    font-size: ${theme.fontSize.atom};
    line-height: 20px;
  }
`;

export const CountryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 36px;
  width: 100%;
`;

export const FarmTitle = styled.h3`
  color: ${theme.colors.white};
  font-size: ${theme.fontSize.exa};
  font-weight: 700;
  line-height: 40px;
  margin: unset;
`;

export const FarmLocation = styled.h4`
  color: ${theme.colors.white};
  font-size: ${theme.fontSize.atom};
  font-weight: 400;
  line-height: 18px;
  margin: unset;
`;

export const FarmTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FarmListElement = styled.button`
  border-style: unset;
  text-align: unset;
  padding: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.brown2};
  padding-bottom: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.giga};
  border-bottom: 1px solid ${theme.colors.white};
  cursor: pointer;
  width: 100%;
`;

export const MapNavFarmContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacings.giga};
  padding-bottom: 38px;
  ${theme.mq.untilMega} {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 75px;
    width: 100%;
  }
  ${theme.mq.untilKilo} {
    padding-bottom: ${theme.spacings.exa};
  }
`;

export const FarmDetailTitle = styled.h1`
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -2px;
  font-weight: 700;
  color: ${theme.colors.white};
  margin: unset;
  ${theme.mq.untilMega} {
    line-height: 63.5px;
  }
`;

export const FarmDetailLocation = styled.h2`
  font-size: ${theme.fontSize.kilo};
  font-weight: 700;
  line-height: 43.5px;
  letter-spacing: -2px;
  color: ${theme.colors.white};
  margin: unset;
`;

export const FarmDetailText = styled.p`
  font-size: ${theme.fontSize.atom};
  font-weight: 700;
  line-height: 18px;
  color: ${theme.colors.white};
`;

export const FarmDetailImgContainer = styled.div`
  width: 100%;
  align-self: center;
`;

export const FarmDetailImgRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (min-width: 813px) and (max-width: 1439px) {
    flex-direction: column;
  }

  ${theme.mq.untilKilo} {
    flex-direction: column;
  }
  &:nth-of-type(2) {
    margin-top: 16px;
    @media (min-width: 813px) and (max-width: 1439px) {
      margin-top: 0px;
    }
    ${theme.mq.untilKilo} {
      margin-top: 0px;
    }
  }
`;

export const FarmDetailImgSmall = styled.div`
  width: 100%;
  height: 216px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  &:nth-of-type(1) {
    margin-right: ${theme.spacings.giga};
  }
  ${theme.mq.untilMega} {
    height: 217px;
    &:nth-of-type(1) {
      margin-right: 10px;
    }
  }

  @media (min-width: 813px) and (max-width: 1439px) {
    height: 275px;
    margin-bottom: ${theme.spacings.giga};
  }

  ${theme.mq.untilKilo} {
    height: 153px;
    margin-bottom: ${theme.spacings.giga};
  }
`;

export const FarmDetailImgLarge = styled.div`
  width: 100%;
  height: 305px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  margin-top: ${theme.spacings.giga};
  ${theme.mq.untilMega} {
    height: 378px;
    margin-top: 36px;
  }

  @media (min-width: 813px) and (max-width: 1439px) {
    height: 275px;
    margin-top: 0px;
  }

  ${theme.mq.untilKilo} {
    height: 169px;
    margin-top: 0px;
  }
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
