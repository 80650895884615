import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const StyledTimelineLI = ({ className, count, children }) => {
  const theme = useTheme();
  const btnPos = count % 2 === 0 ? 'flex-start' : 'flex-end';

  const StyledTimeLineRow = styled.li`
    ${'' /* border: thin solid grey; */}
    display: flex;
    flex-direction: column;
    align-items: ${btnPos};
    height: 97px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .buttonWrapper {
      width: 45%;
      height: 100%;
      justify-content: center;
      flex-direction: column;

      ${theme.mq.untilByte} {
        width: 100%;
      }
    }
    .ta-da {
      height: 100%;
    }

    button {
      width: 100%;
      height: 100%;
      justify-content: center;
    }


    ${theme.mq.untilByte} {
      align-items: flex-start;
      height: unset;

      margin-bottom: ${theme.spacings.exa};
    }
  `;
  return <StyledTimeLineRow className={className}>{children}</StyledTimeLineRow>;
};

StyledTimelineLI.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
};

export default StyledTimelineLI;
