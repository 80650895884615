import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { t } from '../../translations';
import { GeneralStyles as theme } from '../styles/generalStyles';

const StyledBackButton = styled.svg``;

const ButtonWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  padding: 0px;
  padding-bottom: 24px;
  padding-top: ${theme.spacings.tera};
  ${theme.mq.untilTera} {
    padding-top: 36px;
  }
`;

const BackButton = ({ style, onClick, country, isFarmSelected }) => (
  <ButtonWrapper
    onClick={onClick}
    aria-label={t('mapNav')(isFarmSelected ? `${country}Button` : 'backButton')}
    style={style}
  >
    <StyledBackButton
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='39.8174' height='39.8174' rx='19.9087' fill='white' />
      <path
        d='M24.0557 26.7356L22.5587 28.2039L14.1013 19.9086L22.5587 11.6133L24.0557 13.0815L17.0952 19.9086L24.0557 26.7356Z'
        fill='#3F2021'
      />
    </StyledBackButton>
  </ButtonWrapper>
);

BackButton.propTypes = {
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  country: PropTypes.string,
  isFarmSelected: PropTypes.bool
};

BackButton.defaultProps = {
  style: {},
  onClick: () => {},
  country: '',
  isFarmSelected: false
};

export default BackButton;
