import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Tims4GoodContext from '../../context/timsForGoodContext';

const StyledLink = ({ children, url }) => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const theme = useTheme();

  const StyledLink = styled.a`
    font-size: ${theme.fontSize.bit};
    color: ${theme.colors.white};
    border-radius: 24.5px;
    border: solid 2px ${theme.colors.white};
    background-color: rgba(255, 255, 255, 0.1);
    width: 50%;
    height: 49px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    ${theme.mq.untilMega} {
      flex-direction: column;
      font-size: ${theme.fontSize.byte};
    }
  `;

  const onClick = () => {
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Link Clicked',
      mParticleEvtType: 1,
      evtMessage: { url },
    });
  };

  return <StyledLink href={url} target="_blank" onClick={onClick}>{children}</StyledLink>;
};

StyledLink.propTypes = {
  variant: PropTypes.string,
  highlightColor: PropTypes.string,
};

export default StyledLink;
