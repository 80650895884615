import React, { Fragment, useContext } from 'react';
import { useTheme } from '@emotion/react';
import CoffeBeanLineArt from '../../components/branding/CoffeBeanLineArt';
import CoffeeBeanInScoop from '../../components/branding/CoffeeBeanInScoop';
import EthicallySourced from '../../components/branding/EthicallySourced';
import Column50 from '../../components/layout/Column50';
import MainColumn from '../../components/layout/MainColumn';
import TimSection from '../../components/layout/TimsSection';
import { t } from '../../translations';
import Tims4GoodContext from '../../context/timsForGoodContext';
import RakeLineArt from '../../components/branding/RakeLineArt';
import YoutubeEmbed from '../../components/video/YoutubeEmbed';
import {
  CfcHeader,
  CfcTitle,
  CfcText,
  CfcTextBlack,
  EnveritasSubHeader,
  EnveritasText,
  EnveritasImg,
  FarmerContainer,
  FarmerCenteredText,
  FarmerImageContainer,
  FarmerImg,
  FarmerText,
  LearnMoreText,
  MonitoringIcon,
  MonitoringPoint,
  MonitoringPointText,
  ResponsiveSectionWrapper,
  ResponsiveSectionMargin,
  ResponsiveColumnWrapper,
  MonitoringPointContainer
} from './EthicallySourcedCommitment.styles';
import Modal from '../../components/ui/modal/Modal';
import {
  StyledModalContentHeader,
  StyledModalTextContent
} from '../intro/intro.styles';

export const EthicallySourcedCommitment = () => {
  const { lang, sendMessage } = useContext(Tims4GoodContext);
  const theme = useTheme();

  const onClickEnveritas = () => {
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Link Clicked',
      mParticleEvtType: 1,
      evtMessage: { url: 'https://www.enveritas.org' }
    });
  };

  return (
    <>
      <ResponsiveSectionMargin>
        <TimSection backgroundColorDisplayed='none'>
          <CoffeBeanLineArt />
          <CoffeeBeanInScoop />
          <MainColumn justifyContent='center'>
            <CfcHeader>{t('community')('preheader')}</CfcHeader>
          </MainColumn>
          <ResponsiveColumnWrapper marginTop={[60, 73, 30]}>
            <MainColumn justifyContent='center'>
              <YoutubeEmbed
                videoId={t('community')('standaloneVideoId')}
                iframeTitle={t('community')('standaloneVideoAlt')}
              />
            </MainColumn>
          </ResponsiveColumnWrapper>
          <Modal
            title={t('community')('standaloneVideoAlt')}
            key='videoTranscript'
            eventKey='video_Transcript'
            contentStyles={{
              color: `${theme.colors.white}`,
              display: 'flex',
              flexDirection: 'column'
            }}
            buttonStyles={{
              position: 'relative',
              border: `${theme.colors.black} ${theme.borderWidth.kilo} solid `,
              cursor: 'pointer',
              overflow: 'hidden',
              outline: 'none',
              color: `${theme.colors.black}`,
              padding: `0 ${theme.spacings.tera}`,
              borderRadius: `${theme.borderRadius.kilo}`,
              fontSize: theme.fontSize.atom,
              lineHeight: theme.fontSize.peta,
              letterSpacing: theme.letterSpacing.kilo,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'unset',
              marginTop: `${theme.spacings.mega}`,
              backgroundColor: theme.colors.beigeOverlay15,

              '&:focus': theme.focus.brown,

              svg: {
                position: 'absolute',
                right: theme.spacings.exa
              }
            }}
          >
            {t('community')('cfcVideo').buttonTitle}
            <></>
            <div id='modal_content_container'>
              <StyledModalContentHeader id='modal_header'>
                {t('community')('standaloneVideoAlt')}
              </StyledModalContentHeader>
              <StyledModalTextContent id='modal_content'>
                {t('community')('cfcVideo').transcript.map((text) => (
                  <p key={text}>{text}</p>
                ))}
              </StyledModalTextContent>
            </div>
          </Modal>
          <ResponsiveColumnWrapper marginTop={[59, 77, 36]}>
            <MainColumn>
              <Column50>
                <EthicallySourced lang={lang} />
              </Column50>
              <Column50>
                <CfcText>{t('community')('proudlySupporting')}</CfcText>
              </Column50>
            </MainColumn>
          </ResponsiveColumnWrapper>
        </TimSection>
      </ResponsiveSectionMargin>
      <ResponsiveSectionWrapper
        backgroundColor={theme.colors.beige3}
        paddingTop={[60, 66, 48]}
        paddingBottom={[93, 73, 30]}
      >
        <TimSection backgroundColorDisplayed='beige3'>
          <MainColumn>
            <Column50>
              <CfcTitle style={{ 'margin-bottom': '5px' }}>
                {t('community')('cfcTitle')}
              </CfcTitle>
            </Column50>
            <Column50>
              <CfcTextBlack>{t('community')('cfcText')}</CfcTextBlack>
            </Column50>
          </MainColumn>
        </TimSection>
      </ResponsiveSectionWrapper>
      <ResponsiveSectionWrapper
        backgroundColor={theme.colors.beige4}
        paddingTop={[42, 53, 43]}
        paddingBottom={[40, 75, 69]}
        applyFlexStyles
      >
        <RakeLineArt />
        <TimSection backgroundColorDisplayed='beige4'>
          <MainColumn zIndex='3'>
            <Column50>
              <CfcTitle>
                <a
                  href='https://www.enveritas.org'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={onClickEnveritas}
                >
                  {t('community')('enveritasHeader')}
                </a>
              </CfcTitle>
              <EnveritasSubHeader>
                {t('community')('enveritasSubHeader')}
              </EnveritasSubHeader>
              <EnveritasText>{t('community')('enveritas')}</EnveritasText>
            </Column50>
            <Column50>
              <EnveritasImg
                src='https://cdn.rbi.digital/t4g/enveritas-partnership.png'
                alt={t('community')('enveritasAlt')}
              />
            </Column50>
          </MainColumn>
        </TimSection>
      </ResponsiveSectionWrapper>
      <ResponsiveSectionWrapper
        backgroundColor={theme.colors.beige3}
        paddingTop={[95, 118, 52]}
        paddingBottom={[81, 120, 47]}
        applyFlexStyles
      >
        <TimSection backgroundColorDisplayed='beige3'>
          <MainColumn>
            <Column50>
              <CfcTitle>{t('community')('farmerCenteredHeader')}</CfcTitle>
            </Column50>
            <Column50>
              <FarmerCenteredText>
                {t('community')('farmerCenteredSubHeader')}
              </FarmerCenteredText>
            </Column50>
          </MainColumn>
          <ResponsiveColumnWrapper marginTop={[72, 72, 64]}>
            <MainColumn>
              {t('community')('farmers').map((farmer, index) => (
                <FarmerContainer key={farmer.caption}>
                  <FarmerImageContainer isFirst={index === 0}>
                    <FarmerImg src={farmer.imgSrc} alt={farmer.alt} />
                  </FarmerImageContainer>
                  <FarmerText>{farmer.caption}</FarmerText>
                </FarmerContainer>
              ))}
            </MainColumn>
          </ResponsiveColumnWrapper>
          <MainColumn justifyContent='center'>
            <LearnMoreText>{t('community')('learnMoreHeader')}</LearnMoreText>
          </MainColumn>
          <ResponsiveColumnWrapper marginTop={[48, 40, 40]}>
            <MainColumn>
              {t('community')('learnMoreVideos').map((video) => (
                <YoutubeEmbed
                  key={video.embedId}
                  videoId={video.embedId}
                  iframeTitle={video.title}
                />
              ))}
            </MainColumn>
          </ResponsiveColumnWrapper>
          <ResponsiveColumnWrapper marginTop={[72, 72, 64]}>
            <MainColumn>
              <Column50>
                <CfcTitle>{t('community')('monitoringHeader')}</CfcTitle>
              </Column50>
              <Column50>
                <MonitoringPointContainer>
                  {t('community')('monitoringPoints').map((point) => (
                    <MonitoringPoint key={point.imgSrc}>
                      <MonitoringIcon src={point.imgSrc} alt={point.imgAlt} />
                      <MonitoringPointText>{point.caption}</MonitoringPointText>
                    </MonitoringPoint>
                  ))}
                </MonitoringPointContainer>
              </Column50>
            </MainColumn>
          </ResponsiveColumnWrapper>
        </TimSection>
      </ResponsiveSectionWrapper>
    </>
  );
};
