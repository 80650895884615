import React, { useMemo, Fragment, useContext, useEffect } from 'react';
import { Timeline, Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import { useTheme } from '@emotion/react';
import {
  Panel,
  Wrapper,
  Descriptor,
  HeaderP,
  TimsForGoodWordWrapper,
  IntroH2,
  VideoPadding,
  BodyWrapper,
  StyledModalContentHeader,
  StyledModalTextContent
} from './intro.styles';
import { t } from '../../translations';
import TimsForGoodWordMark from '../../components/branding/TimsForGoodWordMark';
import VideoComponent from '../../components/video/VideoComponent';
import browserSniffer from '../../utils/browserSniffer';
import { useWindowSize } from '../../utils/windowSize';
import { useMetrics } from '../../context/metrics';
import Modal from '../../components/ui/modal/Modal';
import Tims4GoodContext from '../../context/timsForGoodContext';

export const Intro = () => {
  const { lang, sendMessage } = useContext(Tims4GoodContext);
  const { width } = useWindowSize();
  const isDesktopBrowser = useMemo(
    () =>
      // TODO: replace this with window size detection
      !browserSniffer(),
    []
  );

  const { getCurrentTopOffSet } = useMetrics();
  const offSet = getCurrentTopOffSet();
  const theme = useTheme();

  useEffect(() => {
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Page Visted',
      mParticleEvtType: 1
    });
    console.log({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Page Visted',
      mParticleEvtType: 1
    });
  }, []);

  const enableScrollMagic = isDesktopBrowser && width > theme.breakpoints.tera;

  return (
    <Wrapper offSet={offSet}>
      <div className='fixed'>
        <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
          <Scene
            enabled={enableScrollMagic}
            duration='100%'
            pin={enableScrollMagic}
          >
            <Timeline paused wrapper={<Panel />}>
              <div className='gap'>
                <Tween
                  from={{ opacity: 1, yPercent: 0 }}
                  to={{ opacity: 0, yPercent: 20 }}
                >
                  <TimsForGoodWordWrapper>
                    <TimsForGoodWordMark />
                  </TimsForGoodWordWrapper>
                  <BodyWrapper>
                    <Descriptor>
                      <IntroH2>
                        {t('header')('smallChanges')}
                        ,
                        <br />
                        <span>{t('header')('bigImpact')}</span>
                      </IntroH2>
                      <HeaderP>{t('header')('paragraph')}</HeaderP>
                    </Descriptor>
                    <VideoPadding>
                      <VideoComponent
                        title='t4g-header-video'
                        offSet={offSet}
                        videoSrc={
                          lang === 'fr'
                            ? 'https://cdn.rbi.digital/t4g/rbi_header_video_lo-res_fr.mp4'
                            : 'https://cdn.rbi.digital/t4g/rbi_header_video_lo-res_en.mp4'
                        }
                        videoPoster={
                          lang === 'fr'
                            ? 'https://cdn.rbi.digital/t4g/rbi_header_video_lo-res_fr.jpg'
                            : 'https://cdn.rbi.digital/t4g/rbi_header_video_lo-res_en.jpg'
                        }
                        hasControls
                        shouldAutoPlay={false}
                        shouldLoop
                        isMuted={false}
                        isBackground={false}
                        backgroundImage={
                          lang === 'fr'
                            ? 'https://cdn.rbi.digital/t4g/rbi_header_video_lo-res_fr.jpg'
                            : 'https://cdn.rbi.digital/t4g/rbi_header_video_lo-res_en.jpg'
                        }
                      />
                      <Modal
                        title='t4g-header-video'
                        key='videoTranscript'
                        eventKey='video_Transcript'
                        contentStyles={{
                          color: `${theme.colors.white}`,
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        buttonStyles={{
                          position: 'relative',
                          border: `${theme.colors.black} ${theme.borderWidth.kilo} solid `,
                          cursor: 'pointer',
                          overflow: 'hidden',
                          outline: 'none',
                          color: `${theme.colors.black}`,
                          padding: `0 ${theme.spacings.tera}`,
                          borderRadius: `${theme.borderRadius.kilo}`,
                          fontSize: theme.fontSize.atom,
                          lineHeight: theme.fontSize.peta,
                          letterSpacing: theme.letterSpacing.kilo,
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          overflow: 'visible',
                          width: 'unset',
                          marginTop: `${theme.spacings.mega}`,
                          backgroundColor: theme.colors.beigeOverlay15,

                          '&:focus': theme.focus.brown,

                          svg: {
                            position: 'absolute',
                            right: theme.spacings.exa
                          }
                        }}
                      >
                        {t('tv')('buttonTitle')}
                        <></>
                        <div id='modal_content_container'>
                          <StyledModalContentHeader id='modal_header'>
                            {t('tv')('title')}
                          </StyledModalContentHeader>
                          <StyledModalTextContent id='modal_content'>
                            {t('header')('transcript').map((text, index) => (
                              <p key={index}>{text}</p>
                            ))}
                          </StyledModalTextContent>
                        </div>
                      </Modal>
                    </VideoPadding>
                  </BodyWrapper>
                </Tween>
              </div>
            </Timeline>
          </Scene>
        </Controller>
      </div>
    </Wrapper>
  );
};
