import React from 'react';
import { useTheme } from '@emotion/react';
import { t } from '../../translations';
import Column45 from '../../components/layout/Column45';
import StyledH2 from '../../components/structure/StyledH2';
import Column50 from '../../components/layout/Column50';
import StyledH3 from '../../components/structure/StyledH3';
import StyledP from '../../components/structure/StyledP';
import MainColumn from '../../components/layout/MainColumn';
import { TimTabsContainer, TitleContainer } from './CommitmentToFood.styles';
import Leaf from '../../components/branding/Leaf';
import { mobileSubheaderStyle } from '../../components/styles/generalStyles';
import { ScrollReveal } from '../../components/scroll-reveal/ScrollReveal';

export const CommitmentToFood = () => {
  const theme = useTheme();

  return (
    <TimTabsContainer id="commitmentToFoodScene">
      <TitleContainer color={theme.colors.red1}>
        <MainColumn>
          <Column45>
            <Leaf variant="white" />
            <StyledH2 textColor="white" highlightcolor="brown">
              <span>{t('common')('ourCommitmentTo')}</span>
              {' '}
              {t('foodBev')('foodBevQuality')}
            </StyledH2>
          </Column45>
          <Column50>
            <ScrollReveal>
              <StyledH3 className="reveal" textColor="white">
                {t('foodBev')('header')}
              </StyledH3>
              <StyledP className="reveal" textColor="white" style={mobileSubheaderStyle} fontWeight="regular">
                {t('foodBev')('subheader')}
              </StyledP>
            </ScrollReveal>
          </Column50>
        </MainColumn>
      </TitleContainer>
    </TimTabsContainer>
  );
};
