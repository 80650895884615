import styled from '@emotion/styled';
import { GeneralStyles } from '../../components/styles/generalStyles';

const theme = { ...GeneralStyles };

export const HeaderRedBar = styled.div`
  color: ${theme.colors.white};
  background-color: ${theme.colors.red1};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${theme.spacings.zetta};
  width: 100%;
  text-align: center;
  align-items: center;
  position: relative;
  font-weight: bold;

  span {
    font-size: ${theme.fontSize.bit};
    margin-top: ${theme.spacings.peta};
  }
  a {
    color: ${theme.colors.white};
  }

  ${theme.mq.untilMega} {
    padding: ${theme.spacings.mega};
  }
`;

export const VideoP = styled.p`
  font-size: ${theme.fontSize.kilo};
  margin: 0 15%;

  ${theme.mq.untilTera} {
    margin: 0;
  }
`;
