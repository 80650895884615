import styled from '@emotion/styled';

export const TabContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.beige2};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: hidden;
  padding: ${(props) => props.theme.spacings.kilo} 0px ${(props) => props.theme.spacings.peta} 0;

  ${({ theme }) => theme.mq.untilKilo} {
    padding-top: 0;
  }

`;

export const TabButtonContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.beige2};
  padding-top: ${({ theme }) => theme.spacings.mega};
  display: flex;
  justify-content: center;
`;

export const StyleUL = styled.ul`
margin: 0;
display: flex;
flex: 1;
flex-direction: row;
gap: ${({ theme }) => theme.spacings.kilo};
list-style: none;
padding: ${({ theme }) => theme.spacings.kilo} 0;

  ${({ theme }) => theme.mq.untilMega} {
    flex-direction: column;
  }
`;

export const StyledList = styled.li`
  justify-content: center;
  flex-grow: 1;

  button {
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.brown};
    padding: ${({ theme }) => theme.spacings.giga} ${({ theme }) => theme.spacings.mega};
    border-radius: ${({ theme }) => theme.borderRadius.giga};
    font-size: ${({ theme }) => theme.fontSize.kilo};
    line-height: ${({ theme }) => theme.lineHeight.zetta};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    &:focus {
      ${({ theme }) => theme.focus.brown};
    }

    ${({ theme }) => theme.mq.untilTera} {
      font-size: ${({ theme }) => theme.fontSize.exa};
    }
  }
`;

export const NormalBtn = styled.button`
  background-color: transparent;
  transition: background-color 400ms ease-out;

  &.active, :hover {
    background-color: ${({ theme }) => theme.colors.lightBeige};
  }
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  position: absolute;
  box-sizing: border-box;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.mega};


  ${({ theme }) => theme.mq.untilMega} {
    padding: 0px ${({ theme }) => theme.spacings.peta};
    width: 100%;
    flex-direction: column;
    gap: 0;
  }

`;

export const TabText = styled.p`
  color: ${({ theme }) => theme.colors.brown};
  padding-right: ${({ theme }) => theme.spacings.peta};
  font-size: ${({ theme }) => theme.fontSize.kilo};
  line-height: ${({ theme }) => theme.lineHeight.byte};
  letter-spacing: ${({ theme }) => theme.letterSpacing.giga};
  align-self: flex-start;
  white-space: pre-wrap;
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  a {
    color: ${({ theme }) => theme.colors.red1};
  }

  ${({ theme }) => theme.mq.untilTera} {
    padding-right: ${({ theme }) => theme.spacings.kilo};
  }

  ${({ theme }) => theme.mq.untilMega} {
    padding-right: 0;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSize.byte};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  `;

export const TabContentImg = styled.img`
  align-self: flex-end;
  height: 35vh;
  max-height: 300px;
  margin-top: ${({ theme }) => theme.spacings.yotta};

  ${({ theme }) => theme.mq.untilTera} {
    margin-right: -50px;
    height: 200px;
  }

  ${({ theme }) => theme.mq.untilMega} {
    width: 100%;
    height: auto;
    margin-top: ${({ theme }) => theme.spacings.exa};
    margin-right: 0;
  }
`;

export const Frame = styled.div`
  position:relative; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  ${({ theme }) => theme.mq.untilMega} {
    flex-direction: column;
    gap: 0;
  }
`;
