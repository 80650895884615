import styled from '@emotion/styled';

export const PlanetLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: hidden;
`;

export const PlanetLineButtonContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.brown};
  display: flex;
  justify-content: right;
  width: 100%;
  ${({ theme }) => theme.mq.untilTera} {
    display: none;
  }
`;

export const TabStyleUL = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.kilo};
  list-style: none;
  padding: ${({ theme }) => theme.spacings.mega}
    ${({ theme }) => theme.spacings.zetta};

  ${({ theme }) => theme.mq.untilMega} {
    flex-direction: column;
  }
`;

export const TabStyledList = styled.li`
  justify-content: center;
  flex-grow: 1;

  button {
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.spacings.bit}
      ${({ theme }) => theme.spacings.mega};
    border-radius: ${({ theme }) => theme.borderRadius.giga};
    font-size: ${({ theme }) => theme.fontSize.exa};
    line-height: ${({ theme }) => theme.lineHeight.mega};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    &:focus {
      ${({ theme }) => theme.focus.white};
    }

    ${({ theme }) => theme.mq.untilTera} {
      font-size: ${({ theme }) => theme.fontSize.exa};
    }
  }
`;

export const Tab = styled.button`
  background: transparent;
  transition: background-color 400ms ease-out;

  &.active,
  :hover {
    color: ${({ theme }) => theme.colors.brown};
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const PlanetLineContent = styled.div(
  ({ display }) => `
  display: ${display};
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.mega};
  ${({ theme }) => theme.mq.untilMega} {
    padding: 0px ${({ theme }) => theme.spacings.peta};
    width: 100%;
    flex-direction: column;
    gap: 0;
  }
`
);

export const StyledTimelineGraphicContainer = styled.div`
  height: 100%;
  position: absolute;
  ${({ theme }) => theme.mq.untilByte} {
    align-self: baseline;
    left: 0px}
  }
`;

export const StyledTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.spacings.exa};
`;

export const StyledTimelineHeader = styled.span`
  font-size: ${({ theme }) => theme.fontSize.exa};
  line-height: ${({ theme }) => theme.lineHeight.byte};
  display: block;
`;

export const StyledTimelineContent = styled.span`
  font-size: ${({ theme }) => theme.fontSize.atom};
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: ${({ theme }) => theme.lineHeight.byte};
  letter-spacing: ${({ theme }) => theme.letterSpacing.kilo};
`;

export const StyledModalContentHeader = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.kilo};
  line-height: ${({ theme }) => theme.lineHeight.byte};
  letter-spacing: ${({ theme }) => theme.letterSpacing.byte};
  margin: ${({ theme }) => theme.spacings.peta}
    ${({ theme }) => theme.spacings.tera} ${({ theme }) => theme.spacings.peta};
  ${({ theme }) => theme.mq.untilByte} {
    margin: ${({ theme }) => theme.spacings.mega}
      ${({ theme }) => theme.spacings.mega}
      ${({ theme }) => theme.spacings.mega};
  }
`;

export const StyledModalTextContent = styled.div`
  font-size: ${({ theme }) => theme.fontSize.exa};
  line-height: ${({ theme }) => theme.lineHeight.byte};
  margin: 0 ${({ theme }) => theme.spacings.tera}
    ${({ theme }) => theme.spacings.peta};

  ${({ theme }) => theme.mq.untilByte} {
    font-size: ${({ theme }) => theme.fontSize.preAtom};
    margin: 0 ${({ theme }) => theme.spacings.mega}
      ${({ theme }) => theme.spacings.mega};
  }
  p {
    font-size: ${({ theme }) => theme.fontSize.exa};
    line-height: ${({ theme }) => theme.lineHeight.byte};
  }
`;

export const StyledModalImage = styled.img`
  width: 100%;
  height: auto;
  min-height: 250px;
`;

export const StyledNavButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: ${({ theme }) => theme.spacings.mega};
  ${({ theme }) => theme.mq.afterTera} {
    display: none;
  }
`;

export const NavigationButton = styled.button`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.brown};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  text-align: center;
  border-style: unset;
  height: 72px;
  width: 72px;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.brown45Transparent};
  }
  &.left_nav {
    padding: ${({ theme }) => theme.spacings.byte}
      ${({ theme }) => theme.spacings.bit} ${({ theme }) => theme.spacings.bit}
      0;
  }
  &.right_nav {
    padding: ${({ theme }) => theme.spacings.byte} 0
      ${({ theme }) => theme.spacings.bit} ${({ theme }) => theme.spacings.bit};
  }

  ${({ theme }) => theme.mq.untilKilo} {
    height: 44px;
    width: 44px;
    svg {
      height: 24px;
    }
  }
`;

export const NavigationTitle = styled.button`
  background-color: ${({ theme }) => theme.colors.brown};
  max-width: 262px;
  flex: 1;
  height: 72px;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacings.byte}
    ${({ theme }) => theme.spacings.mega};
  border-radius: ${({ theme }) => theme.borderRadius.kilo};
  font-size: ${({ theme }) => theme.fontSize.peta};
  line-height: ${({ theme }) => theme.lineHeight.byte};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0 ${({ theme }) => theme.spacings.yotta};
  z-index: 1;
  ${({ theme }) => theme.mq.untilKilo} {
    margin: 0 ${({ theme }) => theme.spacings.peta};
    line-height: ${({ theme }) => theme.lineHeight.zetta};
    height: 44px;
  }
`;
