/** @jsx jsx */
import React, { Fragment, useState, useEffect } from 'react';
import { ThemeProvider, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { GeneralStyles } from './components/styles/generalStyles';
import Tims4GoodContext from './context/timsForGoodContext';

import './styles.module.css';
import GlobalStyler from './components/styles/GlobalStyler';
import {
  CommitmentIntro,
  Intro,
  LaunchNews,
  CommitmentToFood,
  FoodHighlights,
  InteractiveCoffeeMap,
  PlanetTimeline,
  SustainableProjectsReel,
  CommitmentToPeople,
  CommunityTiles,
  EthicallySourcedCommitment
} from './scenes';
import { MetricsProvider } from './context/metrics';

const theme = { ...GeneralStyles };

const TimsForGood = ({ offSets, ...props }) => {
  const appContext = { ...props };
  const controller = new AbortController();
  const [langToUse, setLangToUse] = useState('en');
  const urlParams = new URLSearchParams(window.location.search);
  const RN_LANGUAGE = urlParams.get('timsLang')
    ? urlParams.get('timsLang')
    : 'en';

  const offSetsProp = offSets || [
    { minWidth: 0, offSetTop: 56 },
    { minWidth: 813, offSetTop: 78 }
  ];

  useEffect(() => {
    window.addEventListener(
      'message',
      (evt) => {
        let lang;
        if (typeof evt.data === 'string') {
          const data = JSON.parse(evt.data);
          lang = data.language ? data.language : lang;
        } else {
          lang = evt.data.language ? evt.data.lang : lang;
        }

        if (lang) {
          setLangToUse(lang);
        }
      },
      { signal: controller.signal }
    );

    setLangToUse(RN_LANGUAGE);
    return () => {
      // different way to remove "message" event from experience...
      controller.abort();
    };
  }, []);

  // eslint-disable-next-line max-len
  // CTG contract for mParticle logs from native app / web-hosted / component based marketing experience
  const sendMessage = (data) => {
    if (window.ReactNativeWebView) {
      // send if is RN env
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else if (window.parent) {
      // send if is an iframe env
      window.parent.postMessage(JSON.stringify(data), '*');
    }

    // example of what mParticle is expecting
    // mParticle(
    //   '[TH] T4G VideoTranscript Clicked',
    //   MParticleEventTypes.Other,
    //   {
    //     transcript: true
    //   }
    // );
  };

  return (
    <>
      {langToUse !== undefined && (
        <ThemeProvider theme={theme}>
          <MetricsProvider breakPointOffSets={offSetsProp}>
            <Tims4GoodContext.Provider
              value={{
                ...appContext,
                lang: langToUse,
                sendMessage
              }}
            >
              <GlobalStyler />
              <div id='tims-for-good'>
                <Intro />
                <LaunchNews />
                <CommitmentIntro />
                <EthicallySourcedCommitment />
                <InteractiveCoffeeMap />
                <CommitmentToPeople />
                <CommunityTiles />
                <PlanetTimeline />
                <SustainableProjectsReel />
                <CommitmentToFood />
                <FoodHighlights />
              </div>
            </Tims4GoodContext.Provider>
          </MetricsProvider>
        </ThemeProvider>
      )}
    </>
  );
};

TimsForGood.propTypes = {
  offSets: PropTypes.arrayOf(
    PropTypes.shape({
      minWidth: PropTypes.number.isRequired,
      offSetTop: PropTypes.number.isRequired
    })
  )
};

TimsForGood.defaultProps = {
  offSets: [
    { minWidth: 0, offSetTop: 56 },
    { minWidth: 813, offSetTop: 78 }
  ]
};

export default TimsForGood;
