import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Tims4GoodContext from '../../context/timsForGoodContext';
import { farmPicker } from '../../farms';
import MapNavFarm from './MapNavFarm';
import MapChevron from '../branding/MapChevron';
import BackButton from '../branding/BackButton';
import {
  Title,
  FarmListElement,
  FarmLocation,
  FarmTextContainer,
  FarmTitle,
  CountryContainer,
  StyledList
} from './MapNavStyled';

const MapNavCountry = ({ country, updateCountry }) => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const [selectedFarm, setSelectedFarm] = useState({});
  const firstUpdate = useRef(true);

  const farms = farmPicker(country)('farms');

  const handleListClick = (farm) => {
    setSelectedFarm(farm);
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Coffee Story Clicked',
      mParticleEvtType: 8,
      evtMessage: { country, farmer: farm.title }
    });
  };

  const handleBackClick = () => {
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Map Nav Back Button Clicked',
      mParticleEvtType: 1
    });
    setSelectedFarm((prevSelectedFarm) => {
      if (Object.keys(prevSelectedFarm).length > 0) {
        return {};
      }
      updateCountry('');
      return prevSelectedFarm;
    });
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setSelectedFarm({});
  }, [country]);

  const renderFarmList = (list) =>
    list.map((farm) => (
      <li>
        <FarmListElement key={farm.title} onClick={() => handleListClick(farm)}>
          <FarmTextContainer>
            <FarmTitle>{farm.title}</FarmTitle>
            <FarmLocation>{farm.location}</FarmLocation>
          </FarmTextContainer>
          <MapChevron />
        </FarmListElement>
      </li>
    ));

  return (
    <CountryContainer>
      <BackButton
        onClick={handleBackClick}
        country={country}
        isFarmSelected={!!selectedFarm.title}
      />
      {Object.keys(selectedFarm).length === 0 ? (
        <div>
          <Title>{country.charAt(0).toUpperCase() + country.slice(1)}</Title>
          <StyledList>{renderFarmList(farms)}</StyledList>
        </div>
      ) : (
        <MapNavFarm farm={selectedFarm} />
      )}
    </CountryContainer>
  );
};

MapNavCountry.propTypes = {
  country: PropTypes.string.isRequired,
  updateCountry: PropTypes.func.isRequired
};

export default MapNavCountry;
