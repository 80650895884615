import styled from '@emotion/styled';
import { GeneralStyles } from '../../components/styles/generalStyles';

const theme = { ...GeneralStyles };

export const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  background-image: url('https://cdn.rbi.digital/t4g/timeline_background.jpg');

  .fixed {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;

    ${theme.mq.untilTera} {
      padding-top: 60px;
    }
  }

  .gap {
    max-width: 1720px;
    display: flex;
    align-self: center;
    flex-direction: column;
    ${theme.mq.untilTera} {
      align-items: center;
    }

    ${theme.mq.tera} {
      align-items: start;
      padding: 0 ${theme.spacings.zetta};
    }
  }

  ${theme.mq.kiloToByte} {
    height: 950px;
  }

  ${theme.mq.byteToMega} {
    height: 1050px;
  }

  ${theme.mq.megaToGiga} {
    height: 1100px;
  }

  ${theme.mq.gigaToTera} {
    height: 1250px;
  }

  ${theme.mq.untilTera} {
    div > div {
      height: auto;
    }
  }

  ${theme.mq.untilKilo} {
    height: 720px;

    .fixed {
      position: relative;
      height: auto;

      div > div {
        height: auto;
      }
    }
  }
`;

export const Panel = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-content: center;
`;

export const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${theme.mq.tera} {
    justify-content: space-between;
    flex-direction: row;
  }

  ${theme.mq.untilTera} {
    flex-direction: column;
  }
`;

export const Descriptor = styled.div`
  color: ${theme.colors.white};
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${theme.fontSize.exa};

  ${theme.mq.untilTera} {
    text-align: center;
    width: auto;
    margin-bottom: ${theme.spacings.kilo};
    padding: 0 ${theme.spacings.zetta};
  }
  ${theme.mq.untilKilo} {
    margin-bottom: ${theme.spacings.kilo};
    padding: 0 ${theme.spacings.peta};
  }

  ${theme.mq.tera} {
    text-align: left;
    justify-content: start;
    margin-right: 130px;
    min-width: 540px;
  }
`;

export const TimsForGoodWordWrapper = styled.div`
  width: 70%;
  max-width: 500px;
  ${theme.mq.untilKilo} {
    margin-top: 0;
    padding-left: unset;
    text-align: center;
  }

  ${theme.mq.mega} {
    text-align: center;
  }

  ${theme.mq.tera} {
    text-align: left;
  }
`;

export const HeaderP = styled.p`
  font-size: ${theme.fontSize.kilo};
  line-height: ${theme.lineHeight.byte};
  letter-spacing: ${theme.letterSpacing.exa};
  margin-bottom: ${theme.spacings.giga};
  color: ${theme.colors.black};
  font-weight: ${theme.fontWeight.regular};

  ${theme.mq.untilMega} {
    font-size: ${theme.fontSize.mega};
    line-height: ${theme.lineHeight.byte};
    text-align: center;
  }

  ${theme.mq.untilKilo} {
    font-size: ${theme.fontSize.atom};
    line-height: ${theme.lineHeight.byte};
    text-align: center;
  }
`;

export const IntroH2 = styled.h2`
  font-size: ${theme.fontSize.yotta};
  line-height: ${theme.lineHeight.zetta};
  letter-spacing: ${theme.letterSpacing.bit};
  color: ${theme.colors.red1};
  text-align: left;
  margin: 0;

  span {
    color: ${theme.colors.black};
    margin-bottom: ${theme.spacings.mega};
  }

  ${theme.mq.untilKilo} {
    font-size: ${theme.fontSize.peta};
    margin-bottom: ${theme.spacings.giga};
  }

  ${theme.mq.untilTera} {
    text-align: center;
  }

  ${theme.mq.tera} {
    font-size: 60px;
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.byte};
  }
`;

export const VideoPadding = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${theme.mq.untilTera} {
    padding: 0 ${theme.spacings.zetta};
  }

  ${theme.mq.untilKilo} {
    padding: 0 ${theme.spacings.peta};
  }

  ${theme.mq.tera} {
    margin-top: -138px;
  }

  div {
    width: 100%;
    background-color: unset;
    display: flex;
    justify-content: center;

    ${theme.mq.gigaToTera} {
      margin-bottom: 20px;
    }
  }

  video {
    width: 100%;
    max-width: unset;
  }
`;

export const StyledModalContentHeader = styled.h2`
  font-size: ${theme.fontSize.kilo};
  line-height: ${theme.lineHeight.byte};
  letter-spacing: ${theme.letterSpacing.byte};
  margin: ${theme.spacings.peta} ${theme.spacings.tera} ${theme.spacings.peta};
  padding-right: ${theme.spacings.yotta};

  ${theme.mq.untilByte} {
    font-size: ${theme.fontSize.exa};
    margin: ${theme.spacings.mega} ${theme.spacings.mega} ${theme.spacings.giga};
    padding-right: ${theme.spacings.tera};
  }
`;

export const StyledModalTextContent = styled.div`
  font-size: ${theme.fontSize.exa};
  line-height: ${theme.lineHeight.byte};
  margin: 0 ${theme.spacings.tera} ${theme.spacings.peta};

  ${theme.mq.untilByte} {
    font-size: ${theme.fontSize.preAtom};
    margin: 0 ${theme.spacings.mega} ${theme.spacings.mega};
  }
`;
