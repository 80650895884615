import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import PropTypes from 'prop-types';
import { Wrapper } from './ScrollReveal.styles';

/**
 *
 * Adds a delayed reveal when the element enters the viewport
 *
 * Any child with the className "reveal" will be targeted
 *
 *
 * @example
 * <ScrollReveal>
 *   <p className="reveal">Ta da!</p>
 * </ScrollReveal>
 */
export const ScrollReveal = ({
  children,
  style,
  offSetTrigger,
  triggerHook,
  className,
  enabled,
}) => (
  <Wrapper className={className} style={style}>
    <Controller>
      <Scene enabled={enabled} classToggle="ta-da" reverse={false} offset={offSetTrigger} triggerHook={triggerHook}>
        <div className={enabled ? '' : 'noReveal'}>
          {children}
        </div>
      </Scene>
    </Controller>
  </Wrapper>
);

ScrollReveal.propTypes = {
  triggerHook: PropTypes.oneOf(['onEnter', 'onCenter', 'onLeave']),
  offSetTrigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  enabled: PropTypes.bool,
};

ScrollReveal.defaultProps = {
  triggerHook: 'onCenter',
  offSetTrigger: 0,
  enabled: true,
};
