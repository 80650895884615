import styled from '@emotion/styled';
import { GeneralStyles } from '../../components/styles/generalStyles';

const theme = { ...GeneralStyles };

export const StyledModalContentHeader = styled.h2`
  font-size: ${theme.fontSize.kilo};
  line-height: ${theme.lineHeight.byte};
  letter-spacing: ${theme.letterSpacing.byte};
  margin: ${theme.spacings.peta} ${theme.spacings.tera} ${theme.spacings.peta};
  padding-right: ${theme.spacings.yotta};

  ${theme.mq.untilByte} {
    font-size: ${theme.fontSize.exa};
    margin: ${theme.spacings.mega} ${theme.spacings.mega} ${theme.spacings.giga};
    padding-right: ${theme.spacings.tera};
  }
`;

export const StyledModalTextContent = styled.div`
  font-size: ${theme.fontSize.exa};
  line-height: ${theme.lineHeight.byte};
  margin: 0 ${theme.spacings.tera} ${theme.spacings.peta};

  ${theme.mq.untilByte} {
    font-size: ${theme.fontSize.preAtom};
    margin: 0 ${theme.spacings.mega} ${theme.spacings.mega};
  }
`;
