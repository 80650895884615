import { useState, useEffect } from 'react';

export function useScreen() {
  const [isMobile, setIsMobile] = useState(false);
  const MOBILE_SCREEN = 671;
  const onResize = () => {
    setIsMobile(window.screen.width < MOBILE_SCREEN);
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return function cleanup() {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isMobile;
}

export function useDesktopScreen() {
  const [isDesktop, setIsDesktop] = useState(false);
  const DESKTOP_SCREEN = 812;
  const onResize = () => {
    setIsDesktop(window.screen.width > DESKTOP_SCREEN);
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return function cleanup() {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isDesktop;
}
