import React, { useContext } from 'react';
import { HeaderRedBar, VideoP } from './LaunchNews.styles';
import { t } from '../../translations';
import { ScrollReveal } from '../../components/scroll-reveal/ScrollReveal';
import Tims4GoodContext from '../../context/timsForGoodContext';

export const LaunchNews = () => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const url = t('header')('tfgUrl');
  const onClick = () => {
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Link Clicked',
      mParticleEvtType: 1,
      evtMessage: { url },
    });
  };
  return (
    <ScrollReveal>
      <HeaderRedBar>
        <VideoP className="reveal">{t('header')('wereLaunching')}</VideoP>
        <span className="reveal">
          {t('header')('visit')}
          {' '}
          <a
            href={t('header')('tfgUrl')}
            target="_blank"
            rel="noreferrer"
            onClick={onClick}
          >
            {t('header')('tfgUrlName')}
          </a>
          {' '}
          {t('header')('latestStories')}
        </span>
      </HeaderRedBar>
    </ScrollReveal>
  );
};
