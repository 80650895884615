import React from 'react';

const MapChevron = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2656 11.0449L20.0222 17.8163L13.2656 24.5877L15.3457 26.6677L24.1972 17.8163L15.3457 8.96484L13.2656 11.0449Z"
      fill="white"
    />
  </svg>
);

export default MapChevron;
