import React, { Fragment, useContext, useEffect, useState } from 'react';
import Tims4GoodContext from '../../../context/timsForGoodContext';
import ModalTrigger from './ModalTrigger';
import ModalContent from './ModalContent';

const Modal = ({
  title,
  descriptor,
  contentStyles,
  buttonStyles,
  children,
  chevron,
  eventKey
}) => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const [isShown, setIsShown] = useState(false);

  let touchStartPosition = 0;
  let modalAreaRect = null;
  let modelBodyRect = null;
  let shouldScroll = false;

  let modalArea;
  let modalBody;
  let modalBodyOffset = 0;

  const keys = {
    37: 1,
    38: 1,
    39: 1,
    40: 1
  };
  const wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  const preventDefault = (evt) => {
    const contentBody = evt.target.parentNode;
    const modalBodyRect = contentBody.getBoundingClientRect();

    if (shouldScroll) {
      const isTouch = !!evt?.changedTouches;

      let shouldScrollUp = false;
      if (evt.wheelDelta) {
        shouldScrollUp = evt.wheelDelta > 0;
      }
      if (evt.deltaY) {
        shouldScrollUp = evt.deltaY > 0;
      }

      if (evt?.changedTouches) {
        shouldScrollUp = touchStartPosition < evt.changedTouches[0].clientY;
      }

      if (isTouch) {
        if (
          parseFloat(modalAreaRect.bottom.toFixed(2)) >=
            parseFloat(modalBodyRect.bottom.toFixed(2)) &&
          !shouldScrollUp
        ) {
          evt.preventDefault();
        } else if (
          parseFloat(modalAreaRect.top.toFixed(2)) <=
            parseFloat(modalBodyRect.top.toFixed(2)) &&
          shouldScrollUp
        ) {
          evt.preventDefault();
        }
      } else {
        if (
          modalAreaRect.bottom.toFixed(2) === modalBodyRect.bottom.toFixed(2) &&
          shouldScrollUp
        ) {
          evt.preventDefault();
        }

        if (
          modalAreaRect.top.toFixed(2) === modalBodyRect.top.toFixed(2) &&
          !shouldScrollUp
        ) {
          evt.preventDefault();
        }
      }

      if (
        evt.target.nodeName !== 'svg' &&
        evt.target.className.includes('modal-cover')
      ) {
        evt.preventDefault();
      }
    } else {
      evt.preventDefault();
    }
  };

  const preventDefaultForScrollKeys = (e) => {
    if (shouldScroll) {
      const heightDiff = modelBodyRect.height - modalAreaRect.height;
      const step = heightDiff / 10;

      switch (e.key) {
        case 'Down':
        case 'ArrowDown':
          if (heightDiff >= Math.abs(modalBodyOffset)) {
            modalBodyOffset -= step;
            modalBody.style.marginTop = `${modalBodyOffset}px`;
          }
          break;
        case 'Up':
        case 'ArrowUp':
          if (Math.abs(modalBodyOffset) >= 1) {
            modalBodyOffset += step;
            modalBody.style.marginTop = `${modalBodyOffset}px`;
          }
          break;
        default:
          break;
      }
    }
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  };

  const openModal = () => {
    setIsShown(true);
  };

  const getTouchStartCoordinates = (evt) => {
    touchStartPosition = evt.changedTouches[0].clientY;
  };

  useEffect(() => {
    if (isShown) {
      modalArea = document.querySelector('.modal-area');
      modalAreaRect = modalArea.getBoundingClientRect();

      modalBody = document.querySelector('.modal-body');
      modelBodyRect = modalBody.getBoundingClientRect();

      if (modelBodyRect.height !== modalAreaRect.height) {
        shouldScroll = true;
      }

      window.addEventListener('DOMMouseScroll', preventDefault, {
        passive: false
      });
      window.addEventListener(wheelEvent, preventDefault, { passive: false });
      window.addEventListener('keydown', preventDefaultForScrollKeys, {
        passive: false
      });
      window.addEventListener('touchmove', preventDefault, { passive: false });
      window.addEventListener('touchstart', getTouchStartCoordinates, {
        passive: false
      });
      document.body.style.overflow = 'hidden';
    }
    return () => {
      window.removeEventListener('DOMMouseScroll', preventDefault);
      window.removeEventListener(wheelEvent, preventDefault);
      window.removeEventListener('keydown', preventDefaultForScrollKeys);
      window.removeEventListener('touchmove', preventDefault);
      window.removeEventListener('touchstart', getTouchStartCoordinates);
      document.body.style.overflow = 'unset';
    };
  }, [isShown]);

  const showModal = (evt) => {
    if (
      (evt.type === 'keydown' && evt.keycode === 13) ||
      evt.type === 'click'
    ) {
      openModal();
      if (eventKey !== 'video_Transcript') {
        sendMessage({
          type: 'MPARTICLE_LOG',
          evtSource: '[TH] T4G Timeline Clicked',
          mParticleEvtType: 8,
          evtMessage: { timeline: eventKey }
        });
      } else {
        sendMessage({
          type: 'MPARTICLE_LOG',
          evtSource: '[TH] T4G VideoTranscript Clicked',
          mParticleEvtType: 8,
          evtMessage: { transcript: true, title }
        });
      }
    }
  };

  const closeModal = (evt) => {
    setIsShown(false);
  };

  const handleKeyPress = ({ keyCode }) => {
    if (keyCode === 27) {
      setIsShown(false);
    }
  };

  const handleClickOutside = (evt) => {
    if (evt.target.className.includes('modal-cover')) {
      setIsShown(false);
    }
  };

  return (
    <>
      <ModalTrigger
        title={title}
        descriptor={descriptor}
        onSelected={showModal}
        isModalShown={isShown}
        buttonStyles={buttonStyles}
        chevron={chevron}
      >
        {children[0]}
        {children[1]}
      </ModalTrigger>
      {isShown && (
        <ModalContent
          contentStyles={contentStyles}
          onSelected={closeModal}
          onKeydown={handleKeyPress}
          onClickOutside={handleClickOutside}
        >
          {children[2]}
        </ModalContent>
      )}
    </>
  );
};

export default Modal;
