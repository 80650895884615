import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const StyledTimelineUL = ({ children }) => {
  const theme = useTheme();

  const StyledTimelineUL = styled.ul`
    margin: 0;
    list-style: none;
    padding: 0;
    height: 100%;
    width: 100%;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    li:first-of-type {
      margin-top: ${theme.spacings.peta};
    }
    li:last-of-type {
      margin-bottom: ${theme.spacings.tera};
    }

    ${theme.mq.untilByte} {
      margin-left: ${theme.spacings.yotta};
      width: unset;
      position: relative;
    }
  `;
  return <StyledTimelineUL>{children}</StyledTimelineUL>;
};

StyledTimelineUL.propTypes = {};

export default StyledTimelineUL;
