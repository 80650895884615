import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { colorToDisplay } from '../../utils';

const StyledH4 = ({
  textColor,
  textAlign,
  children,
  margin,
  variant,
  style,
}) => {
  const theme = useTheme();
  const tColor = colorToDisplay(textColor);
  const StyledH4 = styled.h4`
    color: ${tColor};
    font-size: ${variant === 'large'
    ? theme.fontSize.mega
    : theme.fontSize.kilo};
    line-height: ${theme.lineHeight.byte};
    letter-spacing: ${theme.letterSpacing.byte};
    margin: ${margin || `${theme.spacings.kilo} 0`};
    z-index: 2;
    ${textAlign && `text-align: ${textAlign};`}

    ${theme.mq.untilMega} {
      text-align: center;
      margin: ${theme.spacings.kilo} 0;
    }
    ${{ ...style }}
  `;

  return <StyledH4>{children}</StyledH4>;
};

StyledH4.propTypes = {
  margin: PropTypes.string,
  textAlign: PropTypes.string,
  textColor: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object,
};

StyledH4.defaultProps = {
  textAlign: 'center',
  textColor: 'white',
  style: {},
};

export default StyledH4;
