import styled from '@emotion/styled';
import { GeneralStyles } from '../../components/styles/generalStyles';

const theme = { ...GeneralStyles };

export const ImageTilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${theme.mq.untilMega} {
    flex-direction: column;
  }
`;
