import styled from '@emotion/styled';

export const Wrapper = styled.div`
  z-index:1;
  position: relative;
  color: #ffffff;

  .reveal {
    opacity: 0;
    transform: translateX(-15px);
    transition: opacity 800ms ease-out, transform 400ms ease-out;
  }

  & .ta-da {
    .reveal {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .noReveal {
    .reveal {
      opacity: 1;
      transform: translateX(0px);
    }
  }

`;
