import { useContext } from 'react';
import { MetricsContext } from './metrics.context';

export const useMetrics = () => {
  const { getCurrentTopOffSet, addResizeCallback } = useContext(MetricsContext);

  return {
    getCurrentTopOffSet,
    onWindowResize: addResizeCallback,
  };
};
