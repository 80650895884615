export const hexToMatrix = (color) => {
  if (color.includes('#')) {
    color = color.substring(1, color.length); // eslint-disable-line 
  }

  const numberList = color.toLowerCase() === 'ffffff'
    ? [1, 0, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0]
    : [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];
  const RGB = [];
  for (let i = 0; i < color.length; i += 2) {
    const firstDigit = parseInt(color[i], 16);

    const firstDigitPartial = firstDigit * 16;

    let RGBValue = parseInt(color[i + 1], 16) + firstDigitPartial;

    RGBValue /= 255;

    RGBValue = RGBValue.toFixed(2);

    RGB.push(RGBValue);
  }
  const red = parseFloat(RGB[0]).toFixed(2);
  const green = parseFloat(RGB[1]).toFixed(2);
  const blue = parseFloat(RGB[2]).toFixed(2);

  numberList[0] = red;
  numberList[6] = green;
  numberList[12] = blue;
  numberList.join(' ');
  return numberList;
};
