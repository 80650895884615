import styled from '@emotion/styled';
import { GeneralStyles } from '../../components/styles/generalStyles';

const theme = { ...GeneralStyles };

export const TimTabsContainer = styled.section`
  display: flex;
  flex-direction: column;
  position:relative;
`;

export const TitleContainer = styled.div`
  background-color: ${(props) => props.color};
  padding: ${theme.spacings.zetta} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
