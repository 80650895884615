import React from 'react';
import ImageTile from '../../components/layout/ImageTile';
import ScrollTo from '../../components/ScrollTo';
import { t } from '../../translations';
import { ImageTilesContainer } from './CommitmentIntro.styles';

export const CommitmentIntro = () => (
  <ImageTilesContainer>
    <ScrollTo elementId="commitmentToPeopleScene">
      <ImageTile imgSource="https://cdn.rbi.digital/t4g/Header_People_and_Communities.jpg" alt={t('commitments')('peopleAlt')}>
        <span variant="small">{t('commitments')('people')}</span>
      </ImageTile>
    </ScrollTo>
    <ScrollTo elementId="planeTimelineScene">
      <ImageTile imgSource="https://cdn.rbi.digital/t4g/Header_Planet.jpg" alt={t('commitments')('planetAlt')}>
        <span variant="small">{t('commitments')('planet')}</span>
      </ImageTile>
    </ScrollTo>
    <ScrollTo elementId="commitmentToFoodScene">
      <ImageTile imgSource="https://cdn.rbi.digital/t4g/Header_Food_and_Beverage.jpg" alt={t('commitments')('foodBevAlt')}>
        <span variant="small">{t('commitments')('foodBev')}</span>
      </ImageTile>
    </ScrollTo>
  </ImageTilesContainer>
);
