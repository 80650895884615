import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const StyledH2 = ({
  variant,
  children,
  highlightcolor,
  textColor,
  textAlign,
  className,
}) => {
  const theme = useTheme();

  const setBodyColor = (textColor) => {
    let color;

    switch (textColor) {
      case 'brown':
        color = theme.colors.brown;
        break;
      case 'white':
        color = theme.colors.white;
        break;
      default:
        color = theme.colors.red1;
        break;
    }

    return color;
  };

  const standardTextColor = setBodyColor(textColor);

  const highlight = highlightcolor === 'red' ? theme.colors.red1 : theme.colors.brown;

  const fontSize = variant === 'small' ? theme.fontSize.peta : theme.fontSize.tera;
  const letterSpacing = variant === 'small' ? theme.letterSpacing.bit : theme.letterSpacing.atom;

  const StyledH2 = styled.h2`
    font-size: ${fontSize};
    line-height: ${theme.lineHeight.zetta};
    letter-spacing: ${letterSpacing};
    color: ${standardTextColor};
    text-align: ${textAlign};
    margin: 0;

    span {
      color: ${highlight};
      margin-bottom: ${theme.spacings.mega};
    }

    ${theme.mq.untilMega} {
      font-size: ${theme.fontSize.zetta};
      text-align: center;
      margin-bottom: ${theme.spacings.mega};
    }
    ${theme.mq.untilKilo} {
      font-size: ${theme.fontSize.zetta};
    }
  `;

  return <StyledH2 className={className}>{children}</StyledH2>;
};

StyledH2.propTypes = {
  variant: PropTypes.string,
  highlightcolor: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
};

StyledH2.defaultProps = {
  variant: 'large',
  children: 'basic text <span>that has some text in red</span>',
  textColor: 'red',
};

export default StyledH2;
