import styled from '@emotion/styled';
import { GeneralStyles } from '../styles/generalStyles';

const theme = { ...GeneralStyles };

export const VideoPanel = styled.section`
  background: radial-gradient(transparent, 5%, ${theme.colors.brown});
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: center;
  padding: ${theme.spacings.yotta} 0;
`;
