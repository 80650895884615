import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ImageTile from './ImageTile';
import InfoToggleCard from '../InfoToggleCard';

import { GeneralStyles as theme } from '../styles/generalStyles';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  ${theme.mq.afterTera} {
    > div {
      flex-basis: 33%;
    }
  }
  ${theme.mq.untilTera} {
    > div {
      flex-basis: 50%;
    }
  }
  ${theme.mq.untilMega} {
    > div {
      flex-basis: 100%;
    }
  }
`;

const Cards = ({ content }) => {
  const cardMap = new Map([
    ...content.map((_, index) => ([`${index}`, { ref: useRef() }])),
  ]);

  const handleSelect = (index) => () => {
    const mapKey = `${index}`;
    cardMap.forEach((_, key) => {
      cardMap.get(key).ref.current?.toggle(key === mapKey);
    });
  };

  return (
    <CardsContainer>
      {content.map((card, index) => (
        <ImageTile
          key={index}
          justifyContent="center"
          imgSource={card.imgSrc}
          alt={card.alt}
        >
          <InfoToggleCard
            ref={cardMap.get(`${index}`).ref}
            content={card}
            onSelected={handleSelect(index)}
          />
        </ImageTile>
      ))}
    </CardsContainer>
  );
};

Cards.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      desc: PropTypes.string.isRequired,
      imgSrc: PropTypes.string,
      url: PropTypes.sting,
    }),
  ),
};

Cards.defaultProps = {};

export default Cards;
