import React from 'react';

import TimsForGood from 'tims-for-good';
import './index.css';

const App = () => (
  <TimsForGood
    offSets={[
      { minWidth: 0, offSetTop: 56 },
      { minWidth: 813, offSetTop: 78 },
    ]}
    lang="en"
    isNative={false}
  />
);

export default App;
