import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const Chevron = ({ fillColor, size, point }) => {
  const theme = useTheme();
  let chevDimensions;
  const x1 = 1.5800000429153442; const x2 = 17.586000442504883; const y1 = 1.5549999475479126; const y2 = 17.025999069213867; const
    y3 = 32.34700012207031;
  switch (size) {
    case 'tiny':
      chevDimensions = {
        width: 12,
        height: 21,
      };
      break;
    case 'small':
      chevDimensions = {
        width: 18,
        height: 33,
      };
      break;
    default:
      chevDimensions = {
        width: 0,
        height: 0,
      };
      break;
  }

  const StyledChevron = styled.svg`
    .path2 {
      fill: ${fillColor};
    }
  `;

  const points = point === 'left' ? `${x2} ${y1} ${x1} ${y2} ${x2} ${y3}` : `${x1} ${y1} ${x2} ${y2} ${x1} ${y3}`;

  return (
    <StyledChevron
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={chevDimensions.width}
      height={chevDimensions.height}
      viewBox="0 0 19.183 34"
    >
      <defs>
        <bxGuide x="50.298" y="78.627" angle="0" />
        <bxGuide x="13.872" y="50.002" angle="90" />
        <bxGuide x="19.183" y="-7.937" angle="0" />
        <bxGuide x="-3.221" y="34" angle="90" />
        <bxGuide x="1.972" y="17.009" angle="90" />
      </defs>
      <polyline
        style={{
          stroke: fillColor,
          fill: 'none',
          strokeWidth: '3px',
          strokeLinejoin: 'round',
          strokeLinecap: 'round',
        }}
        points={points}
      />
    </StyledChevron>
  );
};

Chevron.propTypes = {
  fillColor: PropTypes.string,
  size: PropTypes.string,
  point: PropTypes.oneOf(['left', 'right']),
};

Chevron.defaultProps = {
  fillColor: '#ffffff',
  size: 'large',
  point: 'right',
};

export default Chevron;
