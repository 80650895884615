/** @jsx jsx */
import React, { useRef } from 'react';
import { jsx } from '@emotion/react';
import { ScrollReveal } from '../../scroll-reveal/ScrollReveal';

const ModalTrigger = ({
  title,
  descriptor,
  onSelected,
  isModalShown,
  buttonStyles,
  children,
}) => {
  const buttonRef = useRef();
  return (
    <ScrollReveal className="buttonWrapper" triggerHook="onEnter" offSetTrigger={75}>
      <button
        className="reveal"
        ref={buttonRef}
        onClick={(evt) => {
          onSelected(evt);
        }}
        onKeyDown={(evt) => {
          evt.persist();
          onSelected(evt);
        }}
        css={buttonStyles}
      >
        {children}
      </button>
    </ScrollReveal>
  );
};

export default ModalTrigger;
