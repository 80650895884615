import React, {
  forwardRef, useContext, useImperativeHandle, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Tween, PlayState } from 'react-gsap';
import { t } from '../translations';
import Tims4GoodContext from '../context/timsForGoodContext';
import StyledButton from './structure/StyledButton';
import StyledP from './structure/StyledP';
import StyledLink from './structure/StyledLink';
import CardArrow from './branding/CardArrow';

const InfoToggleCard = forwardRef(({ content, onSelected }, ref) => {
  const { sendMessage } = useContext(Tims4GoodContext);
  const theme = useTheme();
  const [isActive, setIsActive] = useState(false);

  useImperativeHandle(ref, () => ({
    toggle: (on) => {
      setIsActive(on);
    },
  }));

  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width:100%;
    box-sizing: border-box;

    p {
      margin-top: 0;
      overflow: hidden;
    }

    .fill {
      width: 100%;
      height: 100%;
      position:absolute;
      left: 0px;
      top:0px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      align-items: center;
    }

    .stepTwo {
      background-color: ${theme.colors.green};
    }


    .baseCard {
      width: 100%;
      padding: 0px ${theme.spacings.giga};
      height: 100%;

      .arrow {
        transition: transform 900ms ease-out;
      }
      &:hover {
        .arrow {
          transform: translateX(10px);
        }
      }
    }

    ${theme.mq.untilMega} {
      padding: 0 5vw;
    }
    ${theme.mq.untilByte} {
      padding: 0;
    }
  `;

  const onCardClick = () => {
    sendMessage({
      type: 'MPARTICLE_LOG',
      evtSource: '[TH] T4G Tile Clicked',
      mParticleEvtType: 8,
      evtMessage: { tile: content.eventKey },
    });

    onSelected();
  };

  return (
    <Wrapper active={isActive} className={isActive ? 'open' : 'closed'}>
      <Tween
        duration={1}
        playState={isActive ? PlayState.reverse : PlayState.play}
        from={{ opacity: 0, x: -10 }}
        to={{ opacity: 1, x: 0, zIndex: 1 }}
      >
        <div className="fill stepOne">
          <StyledButton
            className="baseCard"
            textColor="white"
            onSelected={onCardClick}
            textAlign="center"
            variant="small"
            hasChevron={false}
          >
            {content.title}
            <CardArrow className="arrow" />
          </StyledButton>
        </div>
      </Tween>
      <Tween
        duration={1}
        playState={isActive ? PlayState.play : PlayState.reverse}
        from={{ opacity: 0 }}
        to={{ opacity: 1, zIndex: 1 }}
        ease="ease-out"
      >
        <div className="fill stepTwo">
          <StyledP
            padding
            fontWeight="regular"
            textAlign="center"
            variant="tiny"
          >
            {content.desc}
          </StyledP>
          <StyledLink url={content.url}>{t('common')('learnMore')}</StyledLink>
        </div>
      </Tween>
    </Wrapper>
  );
});

InfoToggleCard.propTypes = {
  content: PropTypes.shape(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      imgSrc: PropTypes.string,
      url: PropTypes.sting,
    }).isRequired,
  ),
  onSelected: PropTypes.func,
};

export default InfoToggleCard;
