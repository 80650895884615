import { useTheme } from '@emotion/react';
import React, { useContext } from 'react';
import StyledH4 from '../../components/structure/StyledH4';
import { VideoPanel } from '../../components/video-panel/VideoPanel';
import VideoComponent from '../../components/video/VideoComponent';
import { t } from '../../translations';
import Tims4GoodContext from '../../context/timsForGoodContext';

export const SustainableProjectsReel = () => {
  const theme = useTheme();
  const { lang } = useContext(Tims4GoodContext);

  return (
    <VideoPanel>
      <StyledH4 style={{ padding: theme.fontSize.giga }}>
        {t('projects')('videoDesc')}
      </StyledH4>
      <VideoComponent
        title="sustainable-projects-video"
        videoSrc={`https://cdn.rbi.digital/t4g/${
          lang === 'fr'
            ? 'video_projects_timsforgood_fr.mp4'
            : 'video_projects_timsforgood_en.mp4'
        }`}
        videoPoster={`https://cdn.rbi.digital/t4g/${
          lang === 'fr'
            ? 'video_projects_timsforgood_fr.png'
            : 'video_projects_timsforgood_en.png'
        }`}
        hasControls
        shouldAutoPlay={false}
        shouldLoop={false}
        isMuted={false}
        isBackground={false}
        tracks={[
          <track
            key="en"
            label="English"
            kind="subtitles"
            srcLang="en"
            src="//cdn.rbi.digital/t4g/vid-projects_en.vtt"
          />,
          <track
            key="fr"
            label="French"
            kind="subtitles"
            srcLang="fr"
            src="//cdn.rbi.digital/t4g/vid-projects_fr.vtt"
          />,
        ]}
      />
    </VideoPanel>
  );
};
