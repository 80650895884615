/** @jsx jsx */
import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import styled from '@emotion/styled';
import { jsx } from '@emotion/react';
import { GeneralStyles as theme } from '../../styles/generalStyles';

const StyledModalCover = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 550;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledModalArea = styled.div`
  position: fixed;
  width: 35%;
  max-height: 100vh;
  background-color: ${theme.colors.brown};
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: ${theme.borderRadius.exa};
  ${theme.mq.untilTera} {
    width: 50%;
  }

  ${theme.mq.untilMega} {
    width: 70%;
    max-height: 80vh;
  }

  ${theme.mq.untilByte} {
    width: 90%;
  }
`;
const StyledCloseBtn = styled.button`
  position: absolute;
  top: ${theme.spacings.peta};
  right: ${theme.spacings.peta};
  line-height: 1;
  background: ${theme.colors.white};
  border: 0;
  cursor: pointer;
  border-radius: ${theme.borderRadius.circle};
  width: ${theme.spacings.tera};
  height: ${theme.spacings.tera};

  &:focus {
    ${theme.focus.red};
  }

  ${theme.mq.untilByte} {
    top: ${theme.spacings.kilo};
    right: ${theme.spacings.kilo};
    width: ${theme.spacings.peta};
    height: ${theme.spacings.peta};
  }
`;

const StyledCloseSVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${theme.colors.white};
  stroke: ${theme.colors.brown};
  stroke-linecap: round;
  stroke-width: 2;
`;

const StyledCloseSpan = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;

const ModalContentArea = styled.div`
  a {
    display: block;
    text-align: center;
    line-height: 44px;
    margin: ${theme.spacings.kilo} auto 0px;
    width: 180px;
  }
`;

const ModalContent = ({
  onSelected,
  onClickOutside,
  onKeydown,
  contentStyles,
  children,
}) => {
  const closeButtonRef = useRef();
  useEffect(() => {
    closeButtonRef.current.focus();
  }, []);
  return ReactDOM.createPortal(
    <FocusTrap>
      <StyledModalCover
        role="dialog"
        aria-label="modalName"
        aria-modal="true"
        className="modal-cover"
        onKeyDown={(evt) => {
          evt.persist();
          onKeydown(evt);
        }}
        onClick={(evt) => {
          onClickOutside(evt);
        }}
      >
        <StyledModalArea
          className="modal-area"
          role="dialog"
          aria-labelledby="modal_header modal_content"
        >
          <StyledCloseBtn
            ref={closeButtonRef}
            className="_modal-close"
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              onSelected(evt);
            }}
          >
            <StyledCloseSVG className="_modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </StyledCloseSVG>
            <StyledCloseSpan id="close-modal" className="_hide-visual">
              Close
            </StyledCloseSpan>
          </StyledCloseBtn>
          <ModalContentArea className="modal-body" css={contentStyles}>
            {children}
          </ModalContentArea>
        </StyledModalArea>
      </StyledModalCover>
    </FocusTrap>,
    document.body,
  );
};

export default ModalContent;
