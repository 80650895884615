import React from 'react';
import Leaf from '../../components/branding/Leaf';
import Column45 from '../../components/layout/Column45';
import Column50 from '../../components/layout/Column50';
import MainColumn from '../../components/layout/MainColumn';
import TimSection from '../../components/layout/TimsSection';
import { ScrollReveal } from '../../components/scroll-reveal/ScrollReveal';
import StyledH2 from '../../components/structure/StyledH2';
import StyledH3 from '../../components/structure/StyledH3';
import StyledP from '../../components/structure/StyledP';
import { mobileSubheaderStyle } from '../../components/styles/generalStyles';
import { t } from '../../translations';

export const CommitmentToPeople = () => (
  <TimSection id="commitmentToPeopleScene" topAndBottomPaddingRequired>
    <MainColumn>
      <Column45>
        <Leaf variant="red" />
        <StyledH2 textColor="brown" highlightcolor="red">
          {t('common')('ourCommitmentTo')}
          {' '}
          <span>{t('people')('pplComm')}</span>
        </StyledH2>
      </Column45>
      <Column50>
        <ScrollReveal>
          <StyledH3 className="reveal" textColor="brown">{t('people')('header')}</StyledH3>
          <StyledP className="reveal" textColor="brown" style={mobileSubheaderStyle} fontWeight="regular">
            {t('people')('text')}
          </StyledP>
        </ScrollReveal>
      </Column50>
    </MainColumn>
  </TimSection>
);
