import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const Column50 = ({
  children, fullWidthUntil, layout, isTimeline, className,
}) => {
  const theme = useTheme();
  const alignment = layout === 'center' ? 'center' : 'initial';
  const flexDirection = isTimeline ? 'row' : 'column';

  const Column50 = styled.div`
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: ${alignment};

    ${theme.mq[`until${fullWidthUntil}`]} {
      width: 100%;
    }
    ${theme.mq.untilByte} {
      flex-direction: ${flexDirection};
      justify-content: center;
    }
  `;
  return <Column50 className={className || ''}>{children}</Column50>;
};

Column50.propTypes = {
  fullWidthAt: PropTypes.string,
  layout: PropTypes.string,
  isTimeline: PropTypes.bool,
  className: PropTypes.string,
};

Column50.defaultProps = {
  fullWidthUntil: 'Mega',
  layout: 'left',
  isTimeline: false,
  className: '',
};

export default Column50;
